import React, { FC } from 'react';
import MuiDrawer, { DrawerProps as MuiDrawerProps } from '@mui/material/Drawer';

import styled, { css } from '../../utils/styled';
import { Paper, PaperProps } from '../surfaces/paper';

export type DrawerProps = Omit<MuiDrawerProps, 'anchor'>;

export const DrawerSizingCSS = css`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 320px;
  }
`;

const StyledPaper = styled(Paper)`
  ${DrawerSizingCSS}
  border-radius: 0;
`;

export const Drawer: FC<DrawerProps> = (props) => (
  <MuiDrawer
    {...props}
    anchor="left"
    variant="temporary"
    PaperProps={{ component: StyledPaper } as PaperProps}
  />
);

Drawer.displayName = 'Drawer';
