import React, { ChangeEvent, MouseEvent, useMemo, useState } from 'react';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { nanoid } from 'nanoid';
import classNames from 'classnames';

import { Paper } from '../../surfaces/paper';
import styled from '../../../utils/styled';
import { IconButtonCSS } from '../../buttons/iconButton';
import { Typography } from '../typography';

import {
  getComparator,
  Id,
  TTableData,
  TTableHeadCell,
  Order,
  RowsPerPage,
  TableProps,
  stableSort,
  TRowsPerPage,
} from './utils';
import { TableHead } from './tableHead';
import { TableToolbar } from './tableToolbar';
import { TableCheckbox } from './tableCheckbox';

const StyledTableRow = styled(TableRow)`
  &.alternating:nth-of-type(odd) {
    background: ${({ theme }) => theme.palette.action.disabledBackground};

    &:hover {
      background: ${({ theme }) => theme.palette.action.focus};
    }
  }
` as typeof TableRow;

const StyledTablePagination = styled(TablePagination)`
  .icon-button {
    ${IconButtonCSS}
  }
` as typeof TablePagination;

export function Table<T extends TTableData = TTableData>(
  {
  actions,
  alternating,
  dense,
  disablePagination,
  hideHeader,
  headCells,
  hideToolbar,
  initOrder = 'desc',
  initOrderBy,
  initPage = 0,
  labelDisplayedRows,
  labelRowsPerPage,
  labelSelectedItems,
  outlined,
  rows,
  rowsPerPage: initRowsPerPage = 10,
  selectedIds,
  selectedItemsActions,
  setSelectedIds,
  TableProps,
  tableTitle,
  titleBackButton,
  titleNextButton,
  ...rest
}: TableProps<T>) {
  const [order, setOrder] = useState<Order>(initOrder);

  const [orderBy, setOrderBy] = useState<keyof T | undefined>(initOrderBy);

  const [page, setPage] = useState(initPage);
  const [rowsPerPage, setRowsPerPage] = useState<TRowsPerPage>(initRowsPerPage);

  const titleId = useMemo(() => nanoid(), []);

  const selectable = !!(selectedIds && setSelectedIds);

  if (!!selectedIds && !setSelectedIds)
    console.error(
      'Selected table items were provided, but no selection handler. Please provide both or none.'
    );

  if (!selectedIds && !!setSelectedIds)
    console.error(
      'Selection handler was provided, but no list of selected table items. Please provide both or none.'
    );

  const handleRequestSort = (_: MouseEvent<unknown>, property: keyof T) => {
    if (orderBy !== property) {
      setOrder('desc');
      setOrderBy(property);
    } else if (order === 'desc') setOrder('asc');
    else setOrderBy(undefined);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (setSelectedIds) {
      if (event.target.checked) {
        const newSelecteds = rows?.map((n) => n.id);
        setSelectedIds(newSelecteds);
      } else setSelectedIds([]);
    }
  };

  const handleSelect = (_: MouseEvent, id: Id) => {
    if (selectedIds && setSelectedIds) {
      const selectedIndex = selectedIds.indexOf(id);
      let newSelectedIds: Id[] = [];

      if (selectedIndex === -1)
        newSelectedIds = newSelectedIds.concat(selectedIds, id);
      else if (selectedIndex === 0)
        newSelectedIds = newSelectedIds.concat(selectedIds.slice(1));
      else if (selectedIndex === selectedIds.length - 1)
        newSelectedIds = newSelectedIds.concat(selectedIds.slice(0, -1));
      else if (selectedIndex > 0) {
        newSelectedIds = newSelectedIds.concat(
          selectedIds.slice(0, selectedIndex),
          selectedIds.slice(selectedIndex + 1)
        );
      }

      setSelectedIds(newSelectedIds);
    }
  };

  const handleChangePage = (_: unknown, newPage: number) => setPage(newPage);

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10) as TRowsPerPage);
    setPage(0);
  };

  const isSelected = (id: Id) => selectable && selectedIds?.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const sortedRows = orderBy
    ? (rows.length > 0 && stableSort(rows, getComparator(order, orderBy))) ||
      ([] as T[])
    : rows;

  const paginatedRows = disablePagination
    ? sortedRows
    : (sortedRows &&
        sortedRows.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )) ||
      ([] as T[]);

  return (
    <Paper sx={{ width: '100%' }} {...rest}>
      {(tableTitle || selectable || actions || selectedItemsActions) &&
        !hideToolbar && (
          <TableToolbar
            actions={actions}
            labelSelectedItems={labelSelectedItems}
            numSelected={selectedIds?.length || 0}
            selectedItemsActions={selectedItemsActions}
            tableTitle={tableTitle}
            titleId={titleId}
          />
        )}

      {hideToolbar && tableTitle && (
        <Typography variant="srOnly" id={titleId}>
          {tableTitle}
        </Typography>
      )}

      <TableContainer>
        <MuiTable aria-labelledby={titleId} size={dense ? 'small' : 'medium'}>
          <TableHead<T>
            headCells={headCells}
            hideHeader={hideHeader}
            numSelected={selectedIds?.length || 0}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
            order={order}
            orderBy={orderBy}
            rowCount={rows.length}
            selectable={selectable}
          />

          <TableBody>
            {paginatedRows.map((row) => {
              const {
                TableCellPropMap,
                TableRowProps,
                TableRowProps: { className } = {},
              } = row;
              const isItemSelected = isSelected(row.id);

              const main = headCells.find((isMainProperty) => isMainProperty);

              let labelId = main
                ? TableCellPropMap?.[main.id as string]?.id ?? ''
                : '';
              labelId = labelId || `table-checkbox-label-${nanoid()}`;

              return (
                <StyledTableRow
                  {...TableRowProps}
                  className={classNames(className, { alternating })}
                  hover
                  key={row.id}
                  {...(selectable
                    ? {
                        'aria-checked': isItemSelected,
                        onClick: (e: MouseEvent<HTMLTableRowElement>) =>
                          handleSelect(e, row.id),
                        role: 'checkbox',
                        selected: isItemSelected,
                        tabIndex: -1,
                        sx: { cursor: 'pointer' },
                      }
                    : {})}
                >
                  {selectable && (
                    <TableCell padding="checkbox">
                      <TableCheckbox
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                  )}

                  {headCells.map(({ align, id, numeric }) => (
                    <TableCell
                      {...TableCellPropMap?.[id as string]}
                      align={align || numeric ? 'right' : 'left'}
                      key={String(id)}
                    >
                      {row[id]}
                    </TableCell>
                  ))}
                </StyledTableRow>
              );
            })}

            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 30 : 50) * emptyRows,
                }}
              >
                <TableCell colSpan={headCells.length + (selectable ? 1 : 0)} />
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </TableContainer>

      {!disablePagination && (
        <StyledTablePagination
          backIconButtonProps={{
            className: 'mr-2 icon-button',
            size: 'small',
            title: titleBackButton,
          }}
          component="div"
          count={rows.length}
          labelDisplayedRows={labelDisplayedRows}
          labelRowsPerPage={labelRowsPerPage}
          nextIconButtonProps={{
            className: 'mr-2 icon-button',
            size: 'small',
            title: titleNextButton,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[...RowsPerPage]}
        />
      )}
    </Paper>
  );
}

export {
  Order,
  RowsPerPage,
  TableProps,
  TRowsPerPage,
  TTableData,
  TTableHeadCell,
};
