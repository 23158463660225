import React, { FC, useEffect, useState } from 'react';
import { SnackbarProvider } from 'notistack';
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider as MuiThemeProvider } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import FontFaceObserver from 'fontfaceobserver';
import nbLocale from 'date-fns/locale/nb';
import nnLocale from 'date-fns/locale/nn';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ThemeProvider as StyledThemeProvider } from '../../utils/styled';
import DefaultTheme from '../../theme/defaultTheme';
import { EnovaTheme } from '../../theme/types';

import EnovaContext, { EnovaContextValues } from './context';
import GlobalStyle from './globalStyles';

import '../../stylesheets/styles.css';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends EnovaTheme {}
}

type EnovaContextProviderProps = {
  /**
   * Maximum number of concurrent snackbars
   */
  maxSnacks?: number;
  /**
   * App theme
   */
  theme?: EnovaTheme;
  locale?: keyof typeof localeMap;
  children?: React.ReactNode;
};

const localeMap = {
  'nb-NO': nbLocale,
  'nn-NO': nnLocale,
  en: undefined,
};

const EnovaContextProvider: FC<EnovaContextProviderProps> = ({
  children,
  locale = 'nb-NO',
  maxSnacks = 3,
  theme: initTheme = DefaultTheme,
}) => {
  const [theme, setTheme] = useState(initTheme);
  const [, rerender] = useState(0);
  const [mainMenuOpen, setMainMenuOpen] = useState(false);

  // Used to identify small screens to display dense snackbars
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  // Rerenders provider when font is loaded to ensure correct tab indicator width
  useEffect(() => {
    new FontFaceObserver('National')
      .load()
      .then(() => rerender((prev) => prev + 1))
      .catch((err) =>
        console.error('Error loading fonts with FontFaceObserver', err)
      );
  }, []);

  const values: EnovaContextValues = {
    mainMenuOpen,
    setTheme,
    theme,
    setMainMenuOpen,
  };

  return (
    <EnovaContext.Provider value={values}>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={localeMap[locale]}
        >
          <MuiThemeProvider theme={theme}>
            <CssBaseline />

            <StyledThemeProvider theme={theme}>
              <SnackbarProvider dense={xsScreen} maxSnack={maxSnacks}>
                <GlobalStyle />
                {children}
              </SnackbarProvider>
            </StyledThemeProvider>
          </MuiThemeProvider>
        </LocalizationProvider>
      </StyledEngineProvider>
    </EnovaContext.Provider>
  );
};

export default EnovaContextProvider;
