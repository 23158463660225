import React, { FC, HTMLAttributes, ReactNode } from 'react';
import Box from '@mui/material/Box';
import classNames from 'classnames';

import styled from '../../utils/styled';
import { Typography } from '../dataDisplay/typography';
import { getMarginClasses, MarginProps } from '../input/utils';

export type FormMessageProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'title' | 'children'
> &
  MarginProps & {
    /**
     * Message content
     */
    children?: ReactNode;
    /**
     * If true, the input will take up the full width of its container
     */
    fullWidth?: boolean;
    /**
     * Message title
     */
    title: string;
    variant?: 'error' | 'warning';
  };

const StyledBox = styled(Box)`
  background: ${({ theme }) => theme.palette.error.background};
  border: ${({ theme }) => `2px solid ${theme.palette.error.main}`};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;

  &.warning {
    background: ${({ theme }) => theme.palette.warning.background};
    border: ${({ theme }) => `2px solid ${theme.palette.warning.dark}`};
  }

  &.fullwidth {
    width: 100%;
  }

  &.default-width {
    width: ${({ theme }) => theme.size.inputWidth}px;
  }

  .form-message--content {
    font-size: ${({ theme }) => theme.typography.body2.fontSize};
    font-weight: ${({ theme }) => theme.typography.body2.fontWeight};
  }
` as typeof Box;

export const FormMessage: FC<FormMessageProps> = ({
  children,
  className,
  fullWidth,
  marginBottom,
  marginTop,
  marginY,
  title,
  variant = 'error',
  ...rest
}) => (
  <StyledBox
    className={classNames(
      variant,
      getMarginClasses({ marginBottom, marginTop, marginY }),
      {
        fullwidth: fullWidth,
        'default-width': !fullWidth,
      },
      className
    )}
    paddingX={4}
    paddingY={3}
    {...rest}
  >
    <Typography>
      <strong>{title}</strong>
    </Typography>

    {typeof children === 'string' ? (
      <Typography variant="body2">{children}</Typography>
    ) : (
      <div className="form-message--content">{children}</div>
    )}
  </StyledBox>
);

FormMessage.displayName = 'FormMessage';
