import React, { FC, forwardRef } from 'react';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';

import { IconButton } from '../../buttons/iconButton';
import styled from '../../../utils/styled';

import { TextFieldBase } from './textFieldBase';
import { SearchFieldProps } from './types';

const SearchButtonWrapper = styled.div`
  display: flex;
  margin-right: ${({ theme }) => theme.spacing(-1)};
`;

const StyledTextFieldBase = styled(TextFieldBase)`
  input::placeholder {
    opacity: 1;
    font-style: italic;
  }
` as typeof TextFieldBase;

export const SearchField: FC<SearchFieldProps> = forwardRef(
  ({ disabled, onSearch, ...rest }, ref) => (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSearch();
      }}
    >
      <StyledTextFieldBase
        {...rest}
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchButtonWrapper>
                <Divider orientation="vertical" flexItem className="mr-2" />

                <IconButton
                  aria-label="Search"
                  color="inherit"
                  disabled={disabled}
                  icon="search"
                  type="submit"
                  size="large"
                />
              </SearchButtonWrapper>
            </InputAdornment>
          ),
        }}
        ref={ref}
        role="search"
      />
    </form>
  )
);

SearchField.displayName = 'SearchField';
