import classNames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';

import { AccordionItem } from './accordionItem';

export type AccordionProps = HTMLAttributes<HTMLDivElement>;

export type AccordionSubTypes = {
  Item: typeof AccordionItem;
};

export const Accordion: FC<AccordionProps> & AccordionSubTypes = ({
  children,
  className,
  ...rest
}) => (
  <div className={classNames('EfcAccordion', className)} {...rest}>
    {children}
  </div>
);

Accordion.Item = AccordionItem;
Accordion.displayName = 'Accordion';
