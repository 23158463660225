import React, {
  ChangeEvent,
  createElement,
  FC,
  Fragment,
  HTMLAttributes,
  ReactElement,
  ReactFragment,
  SyntheticEvent,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { TabScrollButtonProps } from '@mui/material/TabScrollButton';
import Hidden from '@mui/material/Hidden';
import Divider from '@mui/material/Divider';

import useEnovaContext from '../../../hooks/enovaContext';
import { Menu } from '../menu';
import styled from '../../../utils/styled';
import { MainContentBox } from '../../../utils/mainContentBox';

import { TabPanel } from './tabPanel';
import { a11yPropsTab, Tab, TabProps, TabValue } from './utils';
import { TabsBase } from './tabsBase';
import { TabTitle } from './tabTitle';

export type TabsProps = Omit<
  HTMLAttributes<HTMLButtonElement>,
  'children' | 'onChange'
> & {
  /**
   * Tab panel content
   */
  children: ReactElement<TabProps>[] | ReactFragment;
  /**
   * Removes horizontal padding. Intended for usage _within_ a screen where the `Tabs` component is not the main content container.
   */
  contained?: boolean;
  /**
   * Initially selected tab (unless `selectedTab` prop is specified)
   */
  defaultTab?: TabValue;
  /**
   * If `true`, hides horizontal rule below tab bar
   */
  dense?: boolean;
  hideDivider?: boolean;
  /**
   * Tab change handler for controlled tabs or to perform additional actions on tab change
   */
  onChange?: (event: ChangeEvent<unknown>, newValue: TabValue) => void;
  /**
   * Selected tab
   */
  selectedTab?: TabValue;
};

type TabsSubTypes = {
  Tab: typeof Tab;
};

const TabMenuButton = styled(Menu.Button)`
  font-weight: bold;

  *:not(.badge) {
    font-size: ${({ theme }) => theme.typography.body2.fontSize};
  }
` as typeof Menu.Button;

const TabMenuListItem = styled(Menu.ListItem)`
  .primary,
  .primary *:not(.badge) {
    font-size: ${({ theme }) => theme.typography.body2.fontSize};
  }
` as typeof Menu.ListItem;

export const Tabs: FC<TabsProps> & TabsSubTypes = ({
  'aria-label': ariaLabel,
  children,
  contained,
  defaultTab,
  dense,
  hideDivider,
  onChange,
  selectedTab,
  ...rest
}) => {
  const content: ReactElement<TabProps>[] = (
    Array.isArray(children)
      ? children
      : (children as ReactElement).type === Fragment
      ? (children as ReactElement).props?.children || []
      : []
  ).filter(
    (item: ReactElement<TabProps>) =>
      item?.props?.tabTitle && item?.props?.children
  );

  if (
    selectedTab != null &&
    !content.some(
      (item, index) =>
        item?.props?.identifier === selectedTab || index === selectedTab
    )
  )
    console.error(
      'Selected tab does not correspond to identifier or index of any provided tab.'
    );

  if (
    selectedTab == null &&
    defaultTab != null &&
    !content.some(
      (item, index) =>
        item?.props?.identifier === defaultTab || index === defaultTab
    )
  )
    console.error(
      'Default tab does not correspond to identifier or index of any provided tab.'
    );

  const [value, setValue] = useState(
    selectedTab != null
      ? selectedTab
      : defaultTab != null
      ? defaultTab
      : content[0]?.props?.identifier || 0
  );

  useEffect(
    () =>
      setValue(
        selectedTab != null ? selectedTab : content[0]?.props.identifier || 0
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTab]
  );

  const { theme, setTheme } = useEnovaContext();
  useLayoutEffect(() => setTheme(theme));

  const containedContentBoxProps = contained ? { pl: 0, pr: 0 } : {};

  return (
    <Fragment>
      <Hidden smUp>
        <MainContentBox
          {...containedContentBoxProps}
          display="flex"
          flexDirection="column"
          pb={2}
        >
          <Menu>
            <TabMenuButton dropdownIcon variant="secondary">
              <div className="f-fill text-start">
                {
                  content.find(({ props: { identifier } }, index) =>
                    [identifier, index].includes(value)
                  )?.props?.tabTitle
                }
              </div>
            </TabMenuButton>

            <Menu.Content>
              {content.map(({ props: { tabTitle, identifier } }, index) => (
                <TabMenuListItem
                  button
                  divider={index !== content.length - 1}
                  key={identifier != null ? identifier : index}
                  onClick={(e) => {
                    setValue(identifier || index);
                    onChange && onChange(e, identifier || index);
                  }}
                  selected={[identifier, index].includes(value)}
                  {...a11yPropsTab(identifier || index)}
                >
                  {tabTitle}
                </TabMenuListItem>
              ))}
            </Menu.Content>
          </Menu>
        </MainContentBox>
      </Hidden>

      <Hidden smDown>
        <MainContentBox {...containedContentBoxProps} pb={dense ? 0 : 2} pt={2}>
          <TabsBase
            {...rest}
            aria-label={ariaLabel}
            hideDivider
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(e: SyntheticEvent, newValue: any) => {
              setValue(newValue);
              onChange && onChange(e, newValue);
            }}
            scrollButtons="auto"
            TabScrollButtonProps={
              {
                'aria-label': 'Scroll',
                classes: {
                  disabled: 'scroll-button--hidden',
                  root: 'scroll-button',
                },
                role: 'button',
              } as Partial<TabScrollButtonProps>
            }
            value={value}
            variant="scrollable"
          >
            {content.map(({ props: { tabTitle, identifier } = {} }, index) => (
              <TabTitle
                key={identifier != null ? identifier : index}
                label={tabTitle}
                value={identifier || index}
                {...a11yPropsTab(identifier || index)}
              />
            ))}
          </TabsBase>
        </MainContentBox>
        {!hideDivider && <Divider />}
      </Hidden>

      <MainContentBox {...containedContentBoxProps} pt={dense ? 0 : 2}>
        {content.map((item, index) => {
          const {
            props: { identifier, tabTitle, ...props },
          } = item;

          return (
            <TabPanel
              identifier={identifier != null ? identifier : index}
              key={identifier != null ? identifier : index}
              value={value}
            >
              {createElement(item.type, props)}
            </TabPanel>
          );
        })}
      </MainContentBox>
    </Fragment>
  );
};

Tabs.Tab = Tab;
Tabs.displayName = 'Tabs';
