import React, { FC, HTMLAttributes } from 'react';
import Typography from '@mui/material/Typography';
import { Link } from '@mui/material';

import styled from '../../utils/styled';

type FooterSubTypes = {
  Link: typeof FooterLink;
  CategoryHeader: typeof FooterCategoryHeader;
};

export const StyledFooter = styled.footer`
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.background.paper};
`;

export const FooterLink = styled(Link)`
  color: ${({ theme }) => theme.palette.greys.grey1};
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

export const FooterCategoryHeader = styled(Typography)`
  color: ${({ theme }) => theme.palette.greys.grey1};
  font-size: 14px;
  font-weight: 600;
`;

export type FooterProps = HTMLAttributes<HTMLDivElement>;

export const Footer: FC<FooterProps> & FooterSubTypes = (props) => (
  <StyledFooter {...props} />
);

Footer.Link = FooterLink;
Footer.CategoryHeader = FooterCategoryHeader;

Footer.displayName = 'Footer';
