import React, { FC, forwardRef } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import classNames from 'classnames';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { capitalize } from '@mui/material/utils';

import { LineArtMap, LineArtOptions, LineArtOption } from './lineArtOptions';

export type LineArtProps = Omit<
  SvgIconProps,
  'classes' | 'children' | 'shapeRendering' | 'size' | 'titleAccess' | 'viewBox'
> & {
  name: LineArtOption;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      pointerEvents: 'none',
      fontSize: '5rem',
      height: 'auto',
      fill: 'none',
      stroke: 'currentColor',
    },
  })
);

export const LineArt: FC<LineArtProps> = forwardRef(
  ({ 'aria-label': ariaLabel, className, name, ...rest }, ref) => {
    const classes = useStyles();
    const title = capitalize(name).replace('_', ' ');

    const Component = LineArtMap[name].icon;

    return (
      <SvgIcon
        {...rest}
        {...{ component: Component }}
        aria-label={ariaLabel || title}
        className={classNames('EfcLineArt', classes.root, className)}
        ref={ref}
        viewBox={LineArtMap[name].viewBox}
      />
    );
  }
);

LineArt.displayName = 'LineArt';

export { LineArtOptions, LineArtOption };
