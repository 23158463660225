import MuiTextField from '@mui/material/TextField';

import { helperTextCSS, labelCSS } from '../utils';
import styled from '../../../utils/styled';
import { focusInvertedDoubleBoxShadow } from '../../../hooks/enovaContext/globalStyles';

export const StyledTextField = styled(MuiTextField)`
  max-width: 100%;

  &.default-width {
    width: ${({ theme }) => theme.size.inputWidth}px;
  }

  &.no-label fieldset {
    top: 0;
  }

  fieldset legend * {
    display: none;
  }

  .input-root {
    background: ${({ theme }) => theme.palette.background.paper};
    min-height: ${({ theme }) => theme.size.inputHeight}px;

    &.disabled {
      background: ${({ theme }) => theme.palette.action.disabledBackground};
      cursor: not-allowed;
    }

    &.focus {
      background: ${({ theme }) => theme.palette.background.paper};
    }

    &.focus fieldset {
      border-color: ${({ theme }) =>
        theme.palette.mode === 'dark'
          ? theme.palette.common.white
          : theme.palette.common.black};
    }

    .input {
      padding: ${({ theme }) => theme.spacing(2.4, 2)};
      background: transparent;

      &:not(textarea) {
        height: ${({ theme }) =>
          `calc(${theme.size.inputHeight}px - ${theme.spacing(2.4 * 2)})`};
      }
    }
  }

  &.narrow .input-root {
    width: ${({ theme }) => theme.size.inputWidth / 3}px;
  }

  &.high-viz .focus fieldset {
    ${focusInvertedDoubleBoxShadow}
    border-color: transparent;
  }

  &.number-field input {
    text-align: right;
  }

  .label,
  .label .asterisk {
    ${labelCSS}
  }

  .helper {
    ${helperTextCSS}
  }
` as typeof MuiTextField;
