import React, { FC, forwardRef, useMemo, useState } from 'react';
import { RadioProps as MuiRadioProps } from '@mui/material/Radio';
import { nanoid } from 'nanoid';
import Box from '@mui/material/Box';
import classNames from 'classnames';
import { useRadioGroup } from '@mui/material/RadioGroup';

import { getMarginClasses } from '../utils';

import {
  ButtonFormControlLabel,
  ButtonFormControlLabelProps,
} from './buttonFormControlLabel';
import { RadioProps } from './types';
import { StyledHelperText, StyledRadio } from './styles';

export type RadioButtonProps = RadioProps &
  Pick<ButtonFormControlLabelProps, 'icon' | 'invertedIcon'>;

/**
 * Demos:
 *
 * - [Radioes](https://mui.com/components/radios/)
 *
 * API:
 *
 * - [Radio API](https://mui.com/api/radio/)
 * - inherits [IconButton API](https://mui.com/api/icon-button/)
 */
export const RadioButton: FC<RadioButtonProps> = forwardRef(
  (
    {
      checked,
      className,
      error,
      helperText,
      icon,
      id: customId,
      invertedIcon,
      label,
      marginBottom,
      marginTop,
      marginY,
      onBlur,
      onFocus,
      width,
      ...rest
    },
    ref
  ) => {
    const [focus, setFocus] = useState(false);
    const id = useMemo(() => customId || nanoid(), [customId]);

    const radioGroup = useRadioGroup();

    let isChecked = !!(
      checked ||
      (rest.value != null && radioGroup?.value === rest.value)
    );

    const radio = (
      <StyledRadio
        {...(rest as MuiRadioProps)}
        checked={isChecked}
        checkedIcon={<span />}
        className="p-absolute"
        color="default"
        icon={<span />}
        id={id}
        inputRef={ref}
        onBlur={(e) => {
          setFocus(false);
          onBlur && onBlur(e);
        }}
        onFocusVisible={() => !focus && setFocus(true)}
      />
    );

    return (
      <Box
        className={classNames(
          getMarginClasses({ marginBottom, marginTop, marginY }),
          className
        )}
        display="flex"
        flexDirection="column"
      >
        <ButtonFormControlLabel
          checked={isChecked}
          className={className}
          control={radio}
          error={error}
          focus={focus}
          icon={icon}
          invertedIcon={invertedIcon}
          label={label}
          width={width}
        />

        {helperText && <StyledHelperText>{helperText}</StyledHelperText>}
      </Box>
    );
  }
);

RadioButton.displayName = 'RadioButton';
