import React, { FC } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

import { CONTENT_WIDTH, CONTENT_PADDING } from './contentUtils';

export type MainContentBoxProps = BoxProps & {
  padding?: 'all' | 'xOnly' | 'xtOnly' | 'xbOnly';
};

export const MainContentBox: FC<MainContentBoxProps> = ({
  children,
  padding = 'all',
  ...rest
}) => (
  <Box
    margin="0 auto"
    maxWidth={CONTENT_WIDTH}
    pl={CONTENT_PADDING}
    pr={CONTENT_PADDING}
    pt={['all', 'xtOnly'].includes(padding) ? CONTENT_PADDING : undefined}
    pb={['all', 'xbOnly'].includes(padding) ? CONTENT_PADDING : undefined}
    width={1}
    {...rest}
  >
    {children}
  </Box>
);
