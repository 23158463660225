import React, { FC, forwardRef, useState } from 'react';
import { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import classNames from 'classnames';

import { Icon } from '../../dataDisplay/icon';
import { getMarginClasses } from '../utils';

import { FormControlLabel } from './formControlLabel';
import { CheckboxProps } from './types';
import { StyledCheckbox, StyledHelperText } from './styles';

/**
 * Demos:
 *
 * - [Checkboxes](https://mui.com/components/checkboxes/)
 *
 * API:
 *
 * - [Checkbox API](https://mui.com/api/checkbox/)
 * - inherits [IconButton API](https://mui.com/api/icon-button/)
 */
export const Checkbox: FC<CheckboxProps> = forwardRef(
  (
    {
      className,
      error,
      helperText,
      checked,
      label,
      marginBottom,
      marginTop,
      marginY,
      onBlur,
      indeterminate,
      onFocus,
      width,
      ...rest
    },
    ref
  ) => {
    const [focus, setFocus] = useState(false);

    const checkbox = (
      <StyledCheckbox
        {...(rest as MuiCheckboxProps)}
        checked={checked}
        checkedIcon={<Icon className="mr-3" name="check_checked" />}
        className="as-start mw-100 check-indicator"
        color="default"
        icon={<Icon className="mr-3" name="check_unchecked" />}
        indeterminate={indeterminate && checked}
        indeterminateIcon={<Icon className="mr-3" name="check_indeterminate" />}
        inputRef={ref}
        onBlur={(e) => {
          setFocus(false);
          onBlur && onBlur(e);
        }}
        onFocusVisible={() => !focus && setFocus(true)}
      />
    );

    return (
      <Box
        className={classNames(
          getMarginClasses({ marginBottom, marginTop, marginY }),
          className
        )}
        display="flex"
        flexDirection="column"
      >
        <FormControlLabel
          control={checkbox}
          error={error}
          focus={focus}
          label={label}
          width={width}
        />

        {helperText && <StyledHelperText>{helperText}</StyledHelperText>}
      </Box>
    );
  }
);

Checkbox.displayName = 'Checkbox';
