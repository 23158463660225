import React, { FC, HTMLAttributes, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import classNames from 'classnames';
import { Breakpoint } from '@mui/material/styles';

import styled from '../../utils/styled';
import { Typography } from '../dataDisplay/typography';
import { IconButton } from '../buttons/iconButton';

type ContentWidth = number | Partial<Record<Breakpoint, number>>;

export type NotificationProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Width of the content (in pixels) or and object specifying content width for specific breakpoints. If no value is given, the content takes all of the available width.
   */
  contentWidth?: ContentWidth;
  /**
   * An object with `open` state and `onClose` handler to allow for controlled notification. If no value is given, the state is handled internally.
   */
  control?: { onClose: () => void; open: boolean };
  /**
   * Notification title
   */
  title: string;
  variant?: 'error' | 'success';
};

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(3, 4)};

  &.error {
    background: ${({ theme }) => theme.palette.error.background};
  }

  &.success {
    background: ${({ theme }) => theme.palette.success.background};
  }

  .form-message--content {
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
    font-weight: ${({ theme }) => theme.typography.body1.fontWeight};
  }
`;

export const Notification: FC<NotificationProps> = ({
  children,
  className,
  contentWidth,
  control: { onClose, open: initOpen = true } = {},
  title,
  variant = 'success',
  ...rest
}) => {
  const [open, setOpen] = useState(initOpen);

  const handleClose = () => {
    onClose && onClose();
    setOpen(false);
  };

  useEffect(() => setOpen(initOpen), [initOpen]);

  return (
    <Collapse in={open}>
      <Wrapper
        {...rest}
        className={classNames(variant, className)}
        role="alert"
      >
        <Box
          alignItems="flex-start"
          display="flex"
          justifyContent="space-between"
          margin="auto"
          maxWidth={1}
          width={contentWidth}
        >
          <div>
            <Typography variant="h4" component="p">
              {title}
            </Typography>

            {typeof children === 'string' ? (
              <Typography>{children}</Typography>
            ) : (
              <div className="form-message--content">{children}</div>
            )}
          </div>

          <IconButton
            aria-label="Dismiss"
            className="ml-2"
            icon="close"
            onClick={handleClose}
            size="small"
          />
        </Box>
      </Wrapper>
    </Collapse>
  );
};

Notification.displayName = 'Notification';
