import React, { FC, forwardRef, ForwardedRef } from 'react';

import styled from '../../../utils/styled';
import { Icon } from '../../dataDisplay/icon';

import { TextFieldBase } from './textFieldBase';
import { SelectOptionGroup, SelectOption, SelectProps } from './types';

const StyledSelect = styled(TextFieldBase)`
  select {
    padding-right: ${({ theme }) => theme.spacing(8)} !important;
  }

  option {
    font-weight: 300;
  }

  svg {
    color: inherit;
    position: absolute;
    right: ${({ theme }) => theme.spacing(2)};

    path {
      fill: currentColor;
    }
  }
`;

export const Select: FC<SelectProps> = forwardRef((props, ref) => {
  const { options, ...rest } = props;

  return (
    <StyledSelect
      {...rest}
      select
      SelectProps={{
        native: true,
        IconComponent: () => <Icon name="chevron_down" />,
      }}
      ref={ref as ForwardedRef<HTMLInputElement>}
    >
      {options.map((item) => {
        if (item.hasOwnProperty('groupName')) {
          const { groupName, options } = item as SelectOptionGroup;

          return (
            <optgroup key={groupName} label={groupName}>
              {options.map(({ label, value, ...rest }) => (
                <option key={value} value={value} {...rest}>
                  {label}
                </option>
              ))}
            </optgroup>
          );
        }

        const { label, value, ...rest } = item as SelectOption;

        return (
          <option
            aria-selected={value === props.value}
            key={value}
            value={value}
            {...rest}
          >
            {label}
          </option>
        );
      })}
    </StyledSelect>
  );
});

Select.displayName = 'Select';
