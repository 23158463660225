import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { alpha } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';

import {
  focusDoubleBoxShadow,
  focusSingleBoxShadow,
} from '../../../hooks/enovaContext/globalStyles';
import styled, { css } from '../../../utils/styled';
import { helperTextCSS } from '../utils';
import CheckIconSVG from '../../../assets/icons/check.svg';

const marginFactor = 2;

const formGroupStyles = css`
  gap: ${({ theme }) => theme.spacing(marginFactor)};

  &.dense-top {
    margin-top: ${({ theme }) => theme.spacing(-1)};
  }

  &.dense-bottom {
    margin-bottom: ${({ theme }) => theme.spacing(-1)};
  }
`;

const controlElementStyles = css`
  padding: 0;
  margin-left: ${({ theme }) => theme.spacing(4)};

  &:hover {
    background: none;
  }
`;

export const StyledCheckGroup = styled(FormGroup)`
  ${formGroupStyles}
` as typeof FormGroup;

export const StyledRadioGroup = styled(RadioGroup)`
  ${formGroupStyles}
` as typeof RadioGroup;

export const StyledCheckbox = styled(Checkbox)`
  ${controlElementStyles}
` as typeof Checkbox;

export const StyledRadio = styled(Radio)`
  ${controlElementStyles}
` as typeof Radio;

export const StyledHelperText = styled(FormHelperText)`
  ${helperTextCSS}
` as typeof FormHelperText;

export const StyledDescWrapper = styled.div`
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  font-weight: ${({ theme }) => theme.typography.body2.fontWeight};
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  &.disabled,
  &.disabled * {
    color: ${({ theme }) => theme.palette.text.disabled};
  }
`;

export const fromControlLabelBaseCSS = css`
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: thin solid
    ${({ theme }) =>
      alpha(
        theme.palette.mode === 'dark'
          ? theme.palette.common.white
          : theme.palette.common.black,
        0.23
      )};
  display: flex;
  margin: 0;
  max-width: 100%;
  min-height: ${({ theme }) => theme.size.inputHeight}px;
  padding: ${({ theme }) => theme.spacing(2.8, 4)};
  width: ${({ theme }) => theme.size.inputWidth}px;

  &.fullwidth {
    width: 100%;
  }

  &.autowidth {
    width: auto;
  }

  &.error:not(.disabled):not(.focus) {
    border-color: ${({ theme }) => theme.palette.error.main};
  }

  &.focus {
    ${focusSingleBoxShadow}
    border-color: ${({ theme }) =>
      theme.palette.mode === 'dark'
        ? theme.palette.common.white
        : theme.palette.common.black};
  }

  &:not(.disabled):not(.error):hover {
    border-color: ${({ theme }) => theme.palette.text.primary};
  }

  .label,
  .label *:not(svg:not(.check-indicator)) {
    font-size: ${({ theme }) => theme.typography.body2.fontSize};
    font-weight: ${({ theme }) => theme.typography.body2.fontWeight};
  }

  .check-indicator {
    color: ${({ theme }) => theme.palette.text.primary};
    margin: -2px;
  }

  &.disabled .check-indicator {
    color: ${({ theme }) => theme.palette.action.disabled};
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  ${fromControlLabelBaseCSS}
` as typeof FormControlLabel;

export const StyledButtonFormControlLabel = styled(FormControlLabel)`
  ${fromControlLabelBaseCSS}
  align-items: center;
  min-height: ${({ theme }) => theme.size.inputHeight * 2}px;
  padding: ${({ theme }) => theme.spacing(2.8, 4)};
  text-align: center;
  justify-content: center;
  width: ${({ theme }) => theme.size.inputWidth / 2}px;

  &.disabled.checked {
    opacity: 65%;

    .label {
      color: inherit;
    }
  }

  &.shape {
    padding: ${({ theme }) => theme.spacing(1, 3, 2)};
    min-height: ${({ theme }) => theme.size.inputHeight * 2.5}px;
  }

  &.checked.focus {
    ${focusDoubleBoxShadow}
  }

  .checkbox::before {
    background-color: currentColor;
    clip-path: padding-box;
    content: '';
    mask-image: url(${CheckIconSVG});
    mask-position: 0 1px;
    mask-repeat: no-repeat;
    padding: 0;
  }

  .checkbox.checked::before {
    padding: 0 0.7em;
  }
` as typeof FormControlLabel;
