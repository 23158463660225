import React, { FC, forwardRef } from 'react';
import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';

import styled, { css } from '../../utils/styled';
import { focusVisibleSingleThickBoxShadow } from '../../hooks/enovaContext/globalStyles';
import { AppBarButton } from '../layout/appBar';
import { Badge, BadgeProps } from '../dataDisplay/badge';

import { IconButtonProps } from './iconButton';

export type ToggleButtonProps = Omit<
  IconButtonProps,
  'icon' | 'size' | 'children' | 'color' | 'edge'
> &
  Pick<BadgeProps, 'badgeContent'> & {
    open?: boolean;
    label?: string;
  };

const StyleCSS = css`
  ${focusVisibleSingleThickBoxShadow};
  align-items: center;
  display: flex;
  height: 48px;
  padding: 12px;
  position: relative;
  transform: rotate(0deg);
  width: 48px;

  .badge--root {
    height: 100%;
    width: 100%;
  }

  & > span {
    position: relative;
    height: 100%;
  }

  .bar {
    background-color: ${({ theme }) => theme.palette.background.paper};
    border-radius: 2px;
    display: block;
    height: 4px;
    left: 0;
    opacity: 1;
    position: absolute;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    width: 100%;

    &--1 {
      top: 2px;
    }

    &--2,
    &--3 {
      top: calc(50% - 2px);
      z-index: 1;
    }

    &--4 {
      top: calc(100% - 6px);
    }
  }

  &.open .bar {
    &--1 {
      top: calc(50% - 2px);
      width: 0;
      left: 50%;
    }

    &--2 {
      transform: rotate(45deg);
    }

    &--3 {
      transform: rotate(-45deg);
    }

    &--4 {
      top: calc(50% - 2px);
      width: 0;
      left: 50%;
    }
  }
`;

const StyledWrapper = styled.span`
  ${StyleCSS}
`;

const StyledIconButton = styled(IconButton)`
  ${StyleCSS}
` as typeof IconButton;

const StyledButton = styled(AppBarButton)`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  cursor: pointer;
  display: flex;
  padding: ${({ theme }) => theme.spacing(1, 3, 1, 1)};
` as typeof AppBarButton;

const Bars: FC<Pick<BadgeProps, 'badgeContent'>> = (props) => (
  <Badge aria-hidden {...props}>
    <span className="bar bar--1" />
    <span className="bar bar--2" />
    <span className="bar bar--3" />
    <span className="bar bar--4" />
  </Badge>
);

export const ToggleButton: FC<ToggleButtonProps> = forwardRef(
  ({ badgeContent, className, label, open, ...rest }, ref) =>
    label ? (
      <StyledButton
        {...rest}
        ref={ref}
        className={classNames(className, 'toggle-btn')}
      >
        <StyledWrapper className={classNames({ open })}>
          <Bars badgeContent={badgeContent} />
        </StyledWrapper>

        <span className="pl-4">{label}</span>
      </StyledButton>
    ) : (
      <StyledIconButton
        {...rest}
        className={classNames({ open }, className, 'toggle-btn')}
        ref={ref}
      >
        <Bars badgeContent={badgeContent} />
      </StyledIconButton>
    )
);

ToggleButton.displayName = 'ToggleButton';
