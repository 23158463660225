import {
  MouseEvent,
  ChangeEvent,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { TablePaginationProps } from '@mui/material/TablePagination';
import { TableCellProps } from '@mui/material/TableCell';
import { TableRowProps } from '@mui/material/TableRow';
import { TableProps as MuiTableProps } from '@mui/material/Table';

import { PaperProps } from '../../surfaces/paper';

const descendingComparator = <T extends TTableData>(
  a: T,
  b: T,
  orderBy: keyof T
) => (b[orderBy] < a[orderBy] ? -1 : b[orderBy] > a[orderBy] ? 1 : 0);

export type Order = 'asc' | 'desc';

export function getComparator<T extends TTableData, Key extends keyof T>(
  order: Order,
  orderBy: Key
): (a: T, b: T) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export const stableSort = <T extends TTableData>(
  array: T[],
  comparator: (a: T, b: T) => number
) => {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const RowsPerPage = [10, 25, 100] as const;
export type TRowsPerPage = typeof RowsPerPage[number];

export type Id = string | number;

export type TTableData = {
  id: Id;
  TableCellPropMap?: { [field: string]: TableCellProps };
  TableRowProps?: TableRowProps;
};

export type TTableHeadCell<T extends TTableData> = {
  align?: 'left' | 'right' | 'center';
  disableSort?: boolean;
  id: keyof T;
  isMainProperty?: boolean; // If table is selectable, the checkbox will use this property to label the checkbox using the `aria-labelledby` prop
  label: string;
  numeric?: boolean;
};

export type TableProps<T extends TTableData = TTableData> = Pick<
  TablePaginationProps,
  'labelRowsPerPage' | 'labelDisplayedRows'
> &
  PaperProps & {
    /**
     * Table actions. Primarily intended for one or more `IconButton`.
     */
    actions?: ReactNode;
    /**
     * If `true`, every other row will have a light grey background
     */
    alternating?: boolean;
    /**
     * If `true`, the table is displayed with dense padding
     */
    dense?: boolean;
    /**
     * If `true`, the table does not include a pagination component, and all provided props regarding pagination are disregarded
     */
    disablePagination?: boolean;
    /**
     * Head cell configuration
     */
    headCells: readonly TTableHeadCell<T>[];
    /**
     * If `true`, the table does not include a toolbar on top
     */
    hideHeader?: boolean;
    /**
     * If `true`, the table does not include a toolbar on top
     */
    hideToolbar?: boolean;
    /**
     * Initial order ('asc' or 'desc')
     */
    initOrder?: Order;
    /**
     * Initial order attribute
     */
    initOrderBy?: keyof T;
    /**
     * Initial page number displayed upon render
     */
    initPage?: number;
    /**
     * Accepts a function which returns a string value that says how many items are selected (intended for localization)
     */
    labelSelectedItems?: (numSelected: number) => string;
    /**
     * Table data
     */
    rows: T[];
    /**
     * Number of rows per page
     */
    rowsPerPage?: TRowsPerPage;
    /**
     * Ids of selected items
     */
    selectedIds?: Id[];
    /**
     * Actions for selected items. Primarily intended for one or more `IconButton`.
     */
    selectedItemsActions?: ReactNode;
    /**
     * Setter func for `selectedIds` prop
     */
    setSelectedIds?: Dispatch<SetStateAction<Id[]>>;
    /**
     * Props forwarded to the `table` component
     */
    TableProps?: MuiTableProps;
    /**
     * Table title displayed in the toolbar, and used to identify table `aria-labelledby` element
     */
    tableTitle?: string;
    /**
     * Title of back button in pagination component (used as simple tooltip - intended for localization)
     */
    titleBackButton?: string;
    /**
     * Title of next button in pagination component (used as simple tooltip - intended for localization)
     */
    titleNextButton?: string;
  };

export type TableHeadProps<T extends TTableData> = Pick<
  TableProps<T>,
  'headCells' | 'hideHeader'
> & {
  /**
   * Number of selected table items
   */
  numSelected: number;
  /**
   * Sort function
   */
  onRequestSort: (event: MouseEvent<unknown>, property: keyof T) => void;
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
  /**
   * Order (`asc` or `desc`)
   */
  order: Order;
  /**
   * Order attribute
   */
  orderBy?: keyof T;
  /**
   * Total number of tablerows
   */
  rowCount: number;
  /**
   * If `true`, items in the table can be selected
   */
  selectable?: boolean;
};

export type TableToolbarProps<T extends TTableData> = Pick<
  TableProps<T>,
  'actions' | 'selectedItemsActions' | 'tableTitle' | 'labelSelectedItems'
> & {
  /**
   * Number of selected table items
   */
  numSelected: number;
  titleId: string;
};
