import React, { ComponentType, FC, HTMLProps, ReactNode } from 'react';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import styled from '../../../utils/styled';
import { focusVisibleInsetBoxShadow } from '../../../hooks/enovaContext/globalStyles';
import { Logo } from '../../dataDisplay/logo';

import { AppBarButton, AppBarButtonProps } from './appBarButton';
import { AppBarMenu, AppBarMenuProps } from './appBarMenu';

export type AppBarProps = Omit<
  MuiAppBarProps,
  | 'children'
  | 'classes'
  | 'color'
  | 'component'
  | 'elevation'
  | 'outlined'
  | 'shadow'
  | 'square'
  | 'variant'
> & {
  /**
   * Props object used to render the app bar logo.
   */
  logoProps?: HTMLProps<HTMLAnchorElement> & {
    component?: ComponentType<HTMLProps<HTMLAnchorElement>>;
  };
  leftContent?: ReactNode;
  rightContent?: ReactNode;
};

const StyledAppBar = styled(MuiAppBar)`
  background: ${({ theme }) => theme.palette.primary.main} !important;
` as typeof MuiAppBar;

const LogoWrapper = styled.div`
  a {
    ${focusVisibleInsetBoxShadow};
    align-items: center;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    display: flex;
    left: 50%;
    padding: ${({ theme }) => theme.spacing()};
    position: absolute;
    text-decoration: none;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

type AppBarSubTypes = {
  Button: typeof AppBarButton;
  Menu: typeof AppBarMenu;
};

export const AppBar: FC<AppBarProps> & AppBarSubTypes = ({
  logoProps: {
    'aria-label': logoAriaLabel = 'logo',
    children: logo = <Logo />,
    component: LogoComponent = 'a',
    href = '/',
    ...logoProps
  } = {},
  leftContent,
  rightContent,
  ...rest
}) => (
  <StyledAppBar color="transparent" elevation={1} {...rest}>
    <Toolbar className="d-flex ai-center jc-between p-relative p-0">
      {leftContent}

      <LogoWrapper>
        <LogoComponent {...logoProps} aria-label={logoAriaLabel} href={href}>
          {logo}
        </LogoComponent>
      </LogoWrapper>

      {rightContent && <div className="d-flex ai-center">{rightContent}</div>}
    </Toolbar>
  </StyledAppBar>
);

AppBar.Button = AppBarButton;
AppBar.Menu = AppBarMenu;
AppBar.displayName = 'AppBar';

export { AppBarButton, AppBarButtonProps, AppBarMenu, AppBarMenuProps };
