import React, { MouseEventHandler } from 'react';
import { SnackbarMessage, useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import { nanoid } from 'nanoid';

import { IconButton } from '../../components/buttons/iconButton';

type SnackAction = {
  disabled?: boolean;
  label: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export type SnackVariant = undefined | 'error' | 'success' | 'warning';

type SnackbarOptions = {
  actions?: SnackAction | SnackAction[];
  dismissable?: boolean;
  persist?: boolean;
  variant?: SnackVariant;
};

/**
 * ### useSnack
 *
 * Implements `useSnackbar` from [notistack](https://github.com/iamhosseindhv/notistack).
 *
 * Allows for consistent display of [**Material-UI** snackbars](https://mui.com/components/snackbars/).
 *
 * Use with `EnovaContextProvider` to manage snackbars within your application, without having to handle open/close states.
 *
 * #### To use
 *
 * ```tsx
 * const { addSnack, closeSnack } = useSnack(); // Functions
 * const key = addSnack('This is a message'); // Add snackbar and store key in a variable
 * closeSnack(key); // Use key to close snackbar programmatically
 * ```
 *
 * ##### Function signature
 *
 * ```tsx
 * (message: SnackbarMessage, options?: SnackbarOptions) => string // Returns snackbar key that can be used for reference
 * ```
 *
 * #### Options
 *
 * *Note:* The shape of the options object differs from notistack, as it is customized for applications building on EFC.
 *
 * ```tsx
 * type SnackAction = {
 * 	disabled?: boolean;
 * 	label: string;
 * 	onClick?: MouseEventHandler<HTMLButtonElement>;
 * };

 * type SnackVariant = undefined | 'error' | 'success'
 *
 * type SnackbarOptions = {
 * 	actions?: SnackAction | SnackAction[];
 * 	dismissable?: boolean;
 * 	persist?: boolean;
 * 	variant?: SnackVariant;
 * };
 * ```
 */
const useSnack = () => {
  // const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar: closeSnack } = useSnackbar();

  const addSnack = (message: SnackbarMessage, options?: SnackbarOptions) => {
    const {
      actions,
      dismissable = true,
      persist = false,
      variant,
    } = options || {};

    const key = nanoid();

    enqueueSnackbar(message, {
      key,
      autoHideDuration: 6000,
      action:
        dismissable || actions ? (
          <div
          // className={classes.actions}
          >
            {actions &&
              (Array.isArray(actions) ? actions : [actions]).map(
                ({ disabled, label, onClick }) => (
                  <Button
                    color="inherit"
                    disabled={disabled}
                    className="fvbs"
                    key={label}
                    onClick={onClick}
                    size="small"
                  >
                    {label}
                  </Button>
                )
              )}

            {dismissable && (
              <IconButton
                aria-label="dismiss"
                className="fvbs"
                color="inherit"
                icon="close"
                onClick={() => closeSnack(key)}
                size="small"
              />
            )}
          </div>
        ) : undefined,
      persist,
      preventDuplicate: true,
      variant,
    });

    return key;
  };

  return { addSnack, closeSnack };
};

export default useSnack;
