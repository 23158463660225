import React, { FC, ForwardedRef, forwardRef } from 'react';

import { TextFieldBase } from './textFieldBase';
import { TextAreaProps } from './types';

export const TextArea: FC<TextAreaProps> = forwardRef((props, ref) => (
  <TextFieldBase
    {...props}
    multiline
    ref={ref as ForwardedRef<HTMLInputElement>}
    rows={7}
  />
));

TextArea.displayName = 'TextArea';
