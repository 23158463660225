import React, { FC, HTMLAttributes } from 'react';

import styled from '../../../utils/styled';

export type DialogActionsProps = HTMLAttributes<HTMLDivElement>;

const StyledDialogActions = styled.div`
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  & > * {
    margin: ${({ theme }) => theme.spacing(3)} !important;
  }
`;

/**
 * Wrapper component for `Dialog` actions
 */
export const DialogActions: FC<DialogActionsProps> = (props) => (
  <StyledDialogActions {...props} />
);
