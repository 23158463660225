import React, { FC, forwardRef } from 'react';
import classNames from 'classnames';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import Box from '@mui/material/Box';

import styled from '../../utils/styled';
import { Icon, IconOption } from '../dataDisplay/icon';
import {
  LargeIcon,
  LargeIconOption,
  LargeIconOptions,
} from '../dataDisplay/largeIcon';
import {
  fastTransition,
  focusVisibleBoxShadow,
} from '../../hooks/enovaContext/globalStyles';
import { Typography } from '../dataDisplay/typography';

export type StylizedButtonProps = Omit<
  MuiButtonProps,
  | 'action'
  | 'centerRipple'
  | 'children'
  | 'classes'
  | 'color'
  | 'component'
  | 'disableElevation'
  | 'disableFocusRipple'
  | 'disableRipple'
  | 'disableTouchRipple'
  | 'endIcon'
  | 'focusRipple'
  | 'focusVisibleClassName'
  | 'onFocusVisible'
  | 'size'
  | 'startIcon'
  | 'TouchRippleProps'
  | 'variant'
> & {
  /**
   * Additional desvription which will appear beneath the button title (intended for large variants)
   */
  description?: string;
  /**
   * Accepts allowed icon names and places specified icon to the left with the appropriate color
   */
  icon?: IconOption | LargeIconOption;
  /**
   * Button size
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Main content
   */
  title: string;
};

const StyledButton = styled(MuiButton)`
  ${focusVisibleBoxShadow};
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => `${theme.shape.borderRadius}px`} !important;
  border: ${({ theme }) => `thin solid ${theme.palette.divider}`} !important;
  color: ${({ theme }) => theme.palette.text.primary};
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing(2, 3, 2, 4)};
  position: relative;
  text-transform: none;

  &:not(.fullwidth) {
    width: fit-content;
  }

  &.btn-medium {
    padding: ${({ theme }) => theme.spacing(3, 4, 3, 5)};
  }

  &.btn-large {
    padding: ${({ theme }) => theme.spacing(4, 5, 4, 6)};
  }

  &::before {
    background: ${({ theme }) => theme.palette.extras.fjordGreen};
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    transform: rotate(180deg);
    width: ${({ theme }) => theme.spacing()};
  }

  &:disabled::before {
    opacity: ${({ theme }) => theme.palette.action.disabledOpacity};
  }

  &:hover {
    background: ${({ theme }) => theme.palette.action.hover};
  }

  &:focus-visible {
    background: ${({ theme }) => theme.palette.action.focus};
  }

  svg.navigation-icon {
    ${fastTransition('margin')}
    margin-right: ${({ theme }) => theme.spacing()};
  }

  &:hover,
  &:active,
  &:focus-visible:not(:disabled) {
    svg.navigation-icon {
      margin-right: 0;
      margin-left: ${({ theme }) => theme.spacing()};
    }
  }
`;

export const StylizedButton: FC<StylizedButtonProps> = forwardRef(
  (
    {
      className,
      description,
      fullWidth,
      icon,
      size = 'medium',
      title,
      ...rest
    },
    ref
  ) => (
    <StyledButton
      {...rest}
      className={classNames(className, `btn-${size}`, { fullwidth: fullWidth })}
      disableElevation
      endIcon={
        <Icon className="navigation-icon" name="double_arrow" color="inherit" />
      }
      fullWidth={fullWidth}
      ref={ref}
    >
      <Box display="flex" alignItems="center">
        {icon ? (
          LargeIconOptions.includes(icon as LargeIconOption) ? (
            <LargeIcon
              className="mr-3"
              color="inherit"
              name={icon as LargeIconOption}
            />
          ) : (
            <Icon className="mr-3" name={icon as IconOption} color="inherit" />
          )
        ) : null}

        <Box
          alignItems="flex-start"
          display="flex"
          flexDirection="column"
          mr={5}
        >
          <Typography
            component="span"
            variant={
              size === 'large' ? 'h3' : size === 'medium' ? 'h4' : undefined
            }
          >
            {title}
          </Typography>

          {description && (
            <Typography component="span">{description}</Typography>
          )}
        </Box>
      </Box>
    </StyledButton>
  )
);

StylizedButton.displayName = 'StylizedButton';
