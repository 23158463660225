import React, { FC, forwardRef } from 'react';
import classNames from 'classnames';

import { RadioGroupProps } from './types';
import { StyledRadioGroup } from './styles';
import { SelectionGroup } from './selectionGroup';
import { getSelectionGroupDisplayClasses } from './utils';

/**
 * Component for easy rendering of `Radio` groups with some common attributes.
 * Renders correctly according to current a11y requirements.
 *
 * Accepts one or more `Radio`.
 *
 * Uses the following components from **Material-UI**:
 *
 * - [FormControl](https://mui.com/api/form-control/)
 * - [FormLabel](https://mui.com/api/form-label/)
 * - [RadioGroup](https://mui.com/api/radio-group/)
 * - [FormHelperText](https://mui.com/api/form-helper-text/)
 */
export const RadioGroup: FC<RadioGroupProps> = forwardRef(
  (
    {
      children,
      defaultValue,
      id,
      name,
      onBlur,
      onChange,
      onFocus,
      row,
      value,
      ...rest
    },
    ref
  ) => (
    <SelectionGroup {...rest}>
      <StyledRadioGroup
        className={classNames(
          getSelectionGroupDisplayClasses(rest.hiddenLabel, row)
        )}
        defaultValue={defaultValue}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        ref={ref}
        value={value}
      >
        {children}
      </StyledRadioGroup>
    </SelectionGroup>
  )
);

RadioGroup.displayName = 'RadioGroup';
