import { createGlobalStyle, css } from '../../utils/styled';

export const fastTransition = (properties: string | string[] = 'all') => css`
  transition: ${({ theme }) =>
    (Array.isArray(properties) ? properties : [properties])
      .map(
        (p) =>
          `${p} ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeInOut}`
      )
      .join(' ')};
`;

export const focusSingleBoxShadow = css`
  outline: 0;
  box-shadow: ${({ theme }) =>
    `0 0 0 0.05rem ${
      theme.palette.mode === 'dark'
        ? theme.palette.common.white
        : theme.palette.common.black
    }`};
`;

export const focusInsetBoxShadow = css`
  outline: 0;
  box-shadow: ${({ theme }) =>
    `inset 0 0 0 0.15rem ${
      theme.palette.mode === 'dark'
        ? theme.palette.common.white
        : theme.palette.common.black
    }`};
`;

export const focusSingleThickBoxShadow = css`
  outline: 0;
  box-shadow: ${({ theme }) =>
    `0 0 0 0.15rem ${
      theme.palette.mode === 'dark'
        ? theme.palette.common.white
        : theme.palette.common.black
    }`};
`;

export const focusDoubleBoxShadow = css`
  outline: 0;
  box-shadow: ${({ theme }) =>
    `0 0 0 0.1rem ${
      theme.palette.mode === 'dark'
        ? theme.palette.common.black
        : theme.palette.common.white
    }, 0 0 0 0.25rem ${
      theme.palette.mode === 'dark'
        ? theme.palette.common.white
        : theme.palette.common.black
    }`};
`;

export const focusInvertedDoubleBoxShadow = css`
  outline: 0;
  box-shadow: ${({ theme }) =>
    `0 0 0 0.1rem ${
      theme.palette.mode === 'dark'
        ? theme.palette.common.white
        : theme.palette.common.black
    }, 0 0 0 0.25rem ${
      theme.palette.mode === 'dark'
        ? theme.palette.common.black
        : theme.palette.common.white
    }`};
`;

export const focusVisibleSingleBoxShadow = css`
  &:focus-visible {
    ${focusSingleBoxShadow}
  }
`;

export const focusVisibleInsetBoxShadow = css`
  &:focus-visible {
    ${focusInsetBoxShadow}
  }
`;

export const focusVisibleSingleThickBoxShadow = css`
  &:focus-visible {
    ${focusSingleThickBoxShadow}
  }
`;

export const focusVisibleBoxShadow = css`
  &:focus-visible {
    ${focusDoubleBoxShadow}
  }
`;

export const focusVisibleBoxShadowRounded = css`
  &:focus-visible {
    ${focusDoubleBoxShadow}
    border-radius: 1px;
  }
`;

const generateBgFgPair = (bgColor: string) => css`
  background: ${bgColor};
  color: ${({ theme }) => theme.palette.getContrastText(bgColor)};
`;

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    color: ${({ theme }) => theme.palette.text.primary};
    background: ${({ theme }) => theme.palette.background.default};
    line-height: ${({ theme }) => theme.typography.body1.lineHeight};

    ul {
      margin-block-start: 0.25em;
      margin-block-end: 0.25em;
      padding-inline-start: 1.5em;
    }

    * {
      text-rendering: optimizeLegibility;
    }

    *::selection {
       ${({ theme }) => generateBgFgPair(theme.palette.primary.dark)}
    }

    .bg-primary {
      ${({ theme }) => generateBgFgPair(theme.palette.primary.main)}
      
      a {
        color: inherit;
      }

      *::selection {

        background: ${({ theme }) => theme.palette.common.white};
        color: ${({ theme }) =>
          theme.palette.getContrastText(theme.palette.common.white)};
      }
    }

    .bg-secondary {
      ${({ theme }) => generateBgFgPair(theme.palette.secondary.main)}
      
      a {
        color: inherit;
      }
    }

    .w-default {
      width: ${({ theme }) => theme.size.inputWidth}px;
    }

    .w-max-content {
      width: max-content;
    }

    .bordered {
      border: thin solid ${({ theme }) => theme.palette.divider}
    }

    .rounded {
      border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    }

    .fvsbs {
      ${focusVisibleSingleBoxShadow}
    }

    .fvibs {
      ${focusVisibleInsetBoxShadow}
    }

    .fvstbs {
      ${focusVisibleSingleThickBoxShadow}
    } 

    .fvbs {
      ${focusVisibleBoxShadow}
    }

    .fvbsr {
      ${focusVisibleBoxShadowRounded}
    }
  }
`;

export default GlobalStyle;
