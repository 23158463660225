import React, { FC } from 'react';

import MenuProvider from './useMenu/provider';
import MenuContext from './useMenu/context';
import useMenu from './useMenu/index';
import { MenuButton } from './menuButton';
import { MenuContent, MenuContentProps } from './menuContent';
import { MenuItem } from './menuItem';
import { MenuListItem } from './menuListItem';

export type MenuSubTypes = {
  Button: typeof MenuButton;
  Content: typeof MenuContent;
  Item: typeof MenuItem;
  ListItem: typeof MenuListItem;
};

/**
 * The menu component supports whatever you want as markup for the button and content.
 *
 * You can either use the `Menu.Item` component for default styling in each item or use whatever markup you need.
 *
 * The open menu will take 300px width as default, but can be overridden with your own style.
 */
export const Menu: FC & MenuSubTypes = ({ children }) => (
  <MenuProvider>{children}</MenuProvider>
);

Menu.Button = MenuButton;
Menu.Content = MenuContent;
Menu.Item = MenuItem;
Menu.ListItem = MenuListItem;

export { MenuContentProps, MenuContext, MenuProvider, useMenu };
