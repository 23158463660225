import React, { FC, forwardRef } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import classNames from 'classnames';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { capitalize } from '@mui/material/utils';

import {
  EfcIconMap,
  EfcIconOption,
  IconOption,
  IconOptions,
  MuiIconMap,
  MuiIconOption,
} from './iconOptions';

export type IconProps = Omit<
  SvgIconProps,
  'classes' | 'children' | 'shapeRendering' | 'titleAccess' | 'viewBox'
> & {
  /**
   * Icon name, specifies which icon will be rendered
   */
  name: IconOption;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      pointerEvents: 'none',
    },
  })
);

export const Icon: FC<IconProps> = forwardRef(
  ({ 'aria-label': ariaLabel, className, name, ...rest }, ref) => {
    const classes = useStyles();
    const title = capitalize(name).replace('_', ' ');

    if (Object.keys(MuiIconMap).includes(name)) {
      const Component = MuiIconMap[name as MuiIconOption];

      return (
        <Component
          {...rest}
          aria-label={ariaLabel || title}
          className={classNames('EfcIcon', classes.root, className)}
          ref={ref}
        />
      );
    }

    const Component = EfcIconMap[name as EfcIconOption].icon;

    return (
      <SvgIcon
        {...rest}
        {...{ component: Component }}
        aria-label={ariaLabel || title}
        className={classNames('EfcIcon', classes.root, className)}
        ref={ref}
        viewBox={EfcIconMap[name as EfcIconOption].viewBox}
      />
    );
  }
);

Icon.displayName = 'Icon';

export { IconOptions, IconOption };
