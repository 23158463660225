import React, { FC, forwardRef, useMemo } from 'react';
import { nanoid } from 'nanoid';
import classNames from 'classnames';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import Box from '@mui/material/Box';

import { getMarginClasses } from '../utils';
import { HelperBox } from '../../feedback/helperBox';

import { BaseProps, TextAreaProps } from './types';
import { StyledTextField } from './styles';

type AllTextFieldBaseProps = Omit<TextAreaProps, 'ref'> &
  Pick<MuiTextFieldProps, 'multiline' | 'rows'> &
  BaseProps;

export const TextFieldBase: FC<AllTextFieldBaseProps> = forwardRef(
  (
    {
      autoComplete = false,
      className,
      HelperBoxProps,
      highVizFocus,
      id: customId,
      InputProps,
      label,
      marginBottom,
      marginTop,
      marginY,
      narrow,
      required,
      ...rest
    },
    ref
  ) => {
    const id = useMemo(() => customId || nanoid(), [customId]);

    return (
      <StyledTextField
        {...rest}
        autoComplete={autoComplete ? undefined : 'off'}
        className={classNames(
          getMarginClasses({ marginBottom, marginTop, marginY }),
          {
            'default-width': !rest.fullWidth && !narrow,
            'high-viz': highVizFocus,
            'no-label': !label,
            narrow,
          },
          className
        )}
        color="primary"
        id={id}
        InputLabelProps={{
          classes: {
            asterisk: 'asterisk',
            disabled: 'disabled',
            focused: 'focus',
            root: 'label',
          },
          htmlFor: id,
          shrink: true,
        }}
        InputProps={{
          ...InputProps,
          required,
          classes: {
            disabled: 'disabled',
            focused: 'focus',
            input: 'input',
            multiline: 'p-0',
            root: 'input-root',
          },
        }}
        inputRef={ref}
        FormHelperTextProps={{
          classes: { root: 'helper', error: 'error', disabled: 'disabled' },
        }}
        label={
          (label || HelperBoxProps) && (
            <Box display="flex" alignItems="baseline">
              {label && <label htmlFor={id}>{label}</label>}
              {required && <div>&nbsp;*</div>}
              {HelperBoxProps && (
                <HelperBox className="ml-2" {...HelperBoxProps} />
              )}
            </Box>
          )
        }
        variant="outlined"
      />
    );
  }
);

TextFieldBase.displayName = 'TextFieldBase';
