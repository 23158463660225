import React, {
  FC,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import classNames from 'classnames';

import { Typography } from '../dataDisplay/typography';
import styled from '../../utils/styled';
import { LargeIcon } from '../dataDisplay/largeIcon';
import { IconButton, IconButtonProps } from '../buttons/iconButton';

export type InfoBoxProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'title' | 'children'
> & {
  /**
   * Message content
   */
  children?: ReactNode;
  CloseButtonProps?: Omit<IconButtonProps, 'onClick'>;
  /**
   * An object with `open` state and `onClose` handler to allow for controlled notification. If no value is given, the state is handled internally.
   */
  control?: { onClose: () => void; open: boolean };
  /**
   * If `false`, hides close button
   */
  dismissable?: boolean;
  /**
   * If `true`, takes minimal width instead of filling the available space
   */
  inline?: boolean;
  /**
   * Message title
   */
  title?: string;
};

const ContentWrapper = styled.div`
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  font-weight: ${({ theme }) => theme.typography.body1.fontWeight};
`;

export const InfoBox: FC<InfoBoxProps> = ({
  children,
  control: { onClose, open: initOpen = true } = {},
  className,
  CloseButtonProps: {
    'aria-label': closeButtonAriaLabel = 'close',
    size: closeButtonSize = 'small',
    icon: closeButtonIcon = 'close',
    className: closeButtonClassName,
    ...CloseButtonProps
  } = {},
  dismissable = true,
  inline,
  title,
  ...rest
}) => {
  const [open, setOpen] = useState(initOpen);

  const handleClose = () => {
    onClose && onClose();
    setOpen(false);
  };

  useEffect(() => setOpen(initOpen), [initOpen]);

  const padTop = !!title !== !!children;

  return (
    <Collapse in={open}>
      <Box
        className={classNames(className, { 'd-inline-flex': inline })}
        {...rest}
      >
        <Paper square elevation={1}>
          <Box
            paddingX={4}
            paddingY={3}
            alignItems="flex-start"
            display="flex"
            justifyContent="space-between"
            margin="auto"
          >
            <LargeIcon className="mr-3" name="info" color="primary" />

            <Box className="f-fill" marginTop={padTop ? 0.5 : undefined}>
              {title && (
                <Typography variant="h4" component="p">
                  {title}
                </Typography>
              )}

              {typeof children === 'string' ? (
                <Typography>{children}</Typography>
              ) : (
                <ContentWrapper>{children}</ContentWrapper>
              )}
            </Box>

            {dismissable && (
              <IconButton
                {...CloseButtonProps}
                aria-label={closeButtonAriaLabel}
                className={classNames(
                  'ml-2',
                  { 'mt-1': padTop },
                  closeButtonClassName
                )}
                icon={closeButtonIcon}
                onClick={handleClose}
                size={closeButtonSize}
              />
            )}
          </Box>
        </Paper>
      </Box>
    </Collapse>
  );
};

InfoBox.displayName = 'InfoBox';
