import React, { FC, MouseEvent, useState } from 'react';

import MenuContext, { MenuContextValues } from './context';

const MenuProvider: FC = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onOpen = (e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget);

  const onClose = () => {
    anchorEl?.focus();
    setAnchorEl(null);
  };

  const values: MenuContextValues = {
    anchorEl,
    onOpen,
    onClose,
  };

  return <MenuContext.Provider value={values}>{children}</MenuContext.Provider>;
};

export default MenuProvider;
