import * as styledComponents from 'styled-components';

import { EnovaTheme } from '../theme/types';

const styled = {
  default: styledComponents.default,
  css: styledComponents.css,
  createGlobalStyle: styledComponents.createGlobalStyle,
  keyframes: styledComponents.keyframes,
  ThemeProvider: styledComponents.ThemeProvider,
} as styledComponents.ThemedStyledComponentsModule<EnovaTheme>;

const { css, createGlobalStyle, keyframes, ThemeProvider } = styled;

export { css, createGlobalStyle, keyframes, ThemeProvider };
export default styled.default;
