import React, { FC, forwardRef, Fragment } from 'react';
import MuiMenuItem, {
  MenuItemProps as MuiMenuItemProps,
} from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

import styled, { css } from '../../../utils/styled';
import { focusVisibleInsetBoxShadow } from '../../../hooks/enovaContext/globalStyles';

import useMenu from './useMenu';

export type MenuItemProps = MuiMenuItemProps & {
  keepMounted?: boolean;
};

const MenuItemCSS = css`
  ${focusVisibleInsetBoxShadow}

  &:active {
    background: ${({ theme }) => theme.palette.action.focus};
  }
`;

const StyledMenuItem = styled(MuiMenuItem)`
  ${MenuItemCSS}
` as typeof MuiMenuItem;

export const MenuItem: FC<MenuItemProps> = forwardRef(
  ({ divider, keepMounted, onClick, ...rest }, ref) => {
    const { onClose } = useMenu();

    return (
      <Fragment>
        <StyledMenuItem
          {...rest}
          ref={ref}
          onClick={(e) => {
            onClick && onClick(e);
            !keepMounted && onClose();
          }}
        />

        {divider && <Divider className="m-0" />}
      </Fragment>
    );
  }
);

MenuItem.displayName = 'MenuItem';
