import React, { FC, Fragment, ReactNode } from 'react';
import Drawer from '@mui/material/Drawer';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import useMediaQuery from '@mui/material/useMediaQuery';
import classNames from 'classnames';

import { ToggleButton, ToggleButtonProps } from '../../buttons/toggleButton';
import styled from '../../../utils/styled';
import useEnovaContext from '../../../hooks/enovaContext';
import { DrawerSizingCSS } from '../../navigation/drawer';

import { ToolbarMixin } from './utils';

export type AppBarMenuProps = {
  /**
   * A menu drawer toggle button will be present, and the content will populate the drawer.
   */
  ToggleButtonProps?: Partial<Omit<ToggleButtonProps, 'onClick' | 'open'>>;
  children?: ReactNode;
};

const StyledDrawer = styled(Drawer)`
  z-index: ${({ theme }) => theme.zIndex.appBar - 1} !important;
` as typeof Drawer;

const OuterDrawerContentWrapper = styled.div`
  ${DrawerSizingCSS}
  padding-top: ${({ theme }) => theme.mixins.toolbar.minHeight}px;
  position: relative;

  ${({ theme }) =>
    Object.entries(theme.mixins.toolbar)
      .filter(([_, obj]) => typeof obj === 'object')
      .map(
        ([key, value]) => `${key} {
          padding-top: ${(value as ToolbarMixin).minHeight}px;
      }`
      )}
`;

const InnerDrawerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  width: 100%;
`;

export const AppBarMenu: FC<AppBarMenuProps> = ({
  children,
  ToggleButtonProps: {
    'aria-label': toggleButtonAriaLabel,
    className: toggleButtonClassName,
    label: toggleButtonLabel,
    ...ToggleButtonProps
  } = {},
}) => {
  const { mainMenuOpen, setMainMenuOpen, theme } = useEnovaContext();

  const collapse =
    useMediaQuery(theme.breakpoints.down('md')) || !toggleButtonLabel;

  return (
    <Fragment>
      <ToggleButton
        {...ToggleButtonProps}
        aria-label={
          toggleButtonAriaLabel ?? (mainMenuOpen ? 'close menu' : 'open menu')
        }
        className={classNames({ 'ml-2': collapse })}
        label={collapse ? undefined : toggleButtonLabel}
        onClick={() => setMainMenuOpen((prev) => !prev)}
        open={mainMenuOpen}
      />

      <StyledDrawer
        anchor="left"
        onClose={() => setMainMenuOpen(false)}
        open={mainMenuOpen}
        variant="temporary"
      >
        <ClickAwayListener onClickAway={() => setMainMenuOpen(false)}>
          <OuterDrawerContentWrapper>
            <InnerDrawerContentWrapper>{children}</InnerDrawerContentWrapper>
          </OuterDrawerContentWrapper>
        </ClickAwayListener>
      </StyledDrawer>
    </Fragment>
  );
};

AppBarMenu.displayName = 'AppBarMenu';
