import React, { FC } from 'react';
import MuiList, { ListProps as MuiListProps } from '@mui/material/List';

import { ListItem, ListItemProps } from './listItem';

export type ListProps = Omit<
  MuiListProps,
  'classes' | 'dense' | 'disablePadding' | 'subheader'
>;

type ListSubTypes = {
  Item: typeof ListItem;
};

/**
 * Wrapper component for `ListItems`
 */
export const List: FC<ListProps> & ListSubTypes = (props) => (
  <MuiList disablePadding {...props} />
);

List.Item = ListItem;
List.displayName = 'List';

export { ListItem, ListItemProps };
