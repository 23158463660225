import React, { FC } from 'react';
import classNames from 'classnames';
import Box, { BoxProps } from '@mui/material/Box';

import styled from '../../../utils/styled';
import { Typography } from '../../dataDisplay/typography';
import { Icon } from '../../dataDisplay/icon';

const stepIndicatorSize = 1.25; // rem
const stepIndicatorBorderWidth = 2; // px

const StepWrapper = styled(Box)`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  position: relative;

  &:not(:last-child) {
    padding-bottom: 0.75rem;

    &:before {
      background: ${({ theme }) => theme.palette.greys.grey2};
      bottom: 0;
      content: '';
      position: absolute;
      top: ${stepIndicatorSize / 2}rem;
      right: calc(
        ${stepIndicatorSize / stepIndicatorBorderWidth}rem -
          ${stepIndicatorBorderWidth / 2}px
      );
      width: ${stepIndicatorBorderWidth}px;
      z-index: 0;
    }
  }

  &:not(:first-child) {
    margin-top: -0.25rem;
  }
` as typeof Box;

const StepLabel = styled(Typography)`
  flex: 1;
  overflow-wrap: anywhere;
` as typeof Typography;

const StepIndicator = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.palette.greys.grey2};
  border-radius: 50%;
  display: flex;
  height: ${stepIndicatorSize}rem;
  justify-content: center;
  margin-top: 4px;
  width: ${stepIndicatorSize}rem;
  min-width: ${stepIndicatorSize}rem;
  z-index: 1;

  &.active:not(.done) {
    border: ${stepIndicatorBorderWidth}px solid
      ${({ theme }) => theme.palette.primary.main};
    height: calc(${stepIndicatorSize}rem + ${stepIndicatorBorderWidth}px);
    margin-bottom: ${stepIndicatorBorderWidth * -0.5}px;
    margin-right: ${stepIndicatorBorderWidth * -0.5}px;
    margin-top: ${4 + stepIndicatorBorderWidth * -0.5}px;
    margin-left: ${({ theme }) => theme.spacing()};
    min-width: calc(${stepIndicatorSize}rem + ${stepIndicatorBorderWidth}px);
    width: calc(${stepIndicatorSize}rem + ${stepIndicatorBorderWidth}px);
  }

  &.done {
    background: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) =>
      theme.palette.getContrastText(theme.palette.primary.main)};
  }
`;

const ButtonLink = styled.button`
  all: unset;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export type StepProps = BoxProps & {
  /**
   * If `true`, step is indicated as active
   */
  active?: boolean;
  /**
   * If `true`, step is indicated as done
   */
  done?: boolean;
  /**
   * Step label
   */
  label: string;
  onClick?: () => void;
};

const getLabel = (props: StepProps) => {
  const { active, label, onClick } = props;

  if (active) {
    return <strong>{label}</strong>;
  }

  if (onClick) {
    return <ButtonLink onClick={onClick}>{label}</ButtonLink>;
  }

  return label;
};

export const Step: FC<StepProps> = ({
  label,
  active,
  done,
  component = 'li',
  onClick,
  ...rest
}) => {
  const stepLabel = getLabel({ active, label, onClick });

  return (
    <StepWrapper key={label} component={component} {...rest}>
      <StepLabel>{stepLabel}</StepLabel>

      <StepIndicator className={classNames({ done, active })}>
        {done && <Icon fontSize="small" name="check" color="inherit" />}
      </StepIndicator>
    </StepWrapper>
  );
};

Step.displayName = 'Step';
