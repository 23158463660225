import React, { HTMLAttributes, ReactNode, FC, KeyboardEvent } from 'react';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper, { PopperProps } from '@mui/material/Popper';

import styled from '../../../utils/styled';

import useMenu from './useMenu';

export type MenuContentProps = Omit<HTMLAttributes<HTMLDivElement>, 'title'> &
  Pick<PopperProps, 'disablePortal' | 'placement'> & {
    title?: ReactNode;
    autoFocusItem?: boolean;
  };

const StyledPaper = styled(Paper)`
  border: 1px solid ${({ theme }) => theme.palette.divider};
` as typeof Paper;

const StyledPopper = styled(Popper)`
  z-index: ${({ theme }) => theme.zIndex.appBar + 1};
` as typeof Popper;

const StyledMenuList = styled(MenuList)`
  padding: 0;
` as typeof MenuList;

export const MenuContent: FC<MenuContentProps> = ({
  children,
  disablePortal,
  placement = 'bottom-end',
  title,
  autoFocusItem,
  ...rest
}) => {
  const { anchorEl, onClose } = useMenu();

  function handleListKeyDown(e: KeyboardEvent) {
    if (e.key === 'Tab' || e.key === 'Escape') {
      e.preventDefault();
      onClose();
    }
  }

  return (
    <StyledPopper
      anchorEl={anchorEl}
      disablePortal={disablePortal}
      open={!!anchorEl}
      placement={placement}
    >
      <Grow in={!!anchorEl}>
        <StyledPaper {...rest}>
          <ClickAwayListener onClickAway={onClose}>
            <div>
              {title}
              <StyledMenuList
                autoFocusItem={autoFocusItem ?? !!anchorEl}
                onKeyDown={handleListKeyDown}
              >
                {children}
              </StyledMenuList>
            </div>
          </ClickAwayListener>
        </StyledPaper>
      </Grow>
    </StyledPopper>
  );
};
