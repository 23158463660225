import { createContext, Dispatch, SetStateAction } from 'react';

import { EnovaTheme } from '../../theme/types';

export interface EnovaContextValues {
  mainMenuOpen: boolean;
  setMainMenuOpen: Dispatch<SetStateAction<boolean>>;
  setTheme: Dispatch<SetStateAction<EnovaTheme>>;
  theme: EnovaTheme;
}

const EnovaContext = createContext<EnovaContextValues>({
  mainMenuOpen: false,
  setMainMenuOpen: () => true,
  setTheme: () => true,
  theme: {} as EnovaTheme,
});

export default EnovaContext;
