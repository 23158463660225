import React, { FC, forwardRef, MouseEventHandler } from 'react';
import MuiChip, { ChipProps as MuiChipProps } from '@mui/material/Chip';
import classNames from 'classnames';

export type ChipProps = Omit<
  MuiChipProps,
  | 'action'
  | 'avatar'
  | 'clickable'
  | 'color'
  | 'deleteIcon'
  | 'onDelete'
  | 'size'
  | 'subheading'
  | 'variant'
> & {
  onClick?: MouseEventHandler<HTMLDivElement> &
    MouseEventHandler<HTMLButtonElement>;
  variant?: 'primary' | 'secondary';
};

export const Chip: FC<ChipProps> = forwardRef(
  ({ className, variant = 'primary', ...rest }, ref) => (
    <MuiChip
      {...rest}
      ref={ref}
      className={classNames(className, 'fvbs')}
      color={variant === 'primary' ? 'primary' : 'default'}
      size="small"
      variant={variant === 'primary' ? undefined : 'outlined'}
    />
  )
);

Chip.displayName = 'Chip';
