import React, { FC, ReactNode, useMemo, useState } from 'react';
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails, {
  AccordionDetailsProps,
} from '@mui/material/AccordionDetails';
import classNames from 'classnames';
import { nanoid } from 'nanoid';

import { Icon } from '../../dataDisplay/icon';
import styled from '../../../utils/styled';
import { fastTransition } from '../../../hooks/enovaContext/globalStyles';
import { Typography } from '../../dataDisplay/typography';

const StyledAccordion = withStyles({
  root: {
    background: 'transparent',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme: Theme) => ({
  root: {
    background: 'transparent',
    border: 0,
    borderTop: `thin solid ${theme.palette.divider}`,
    marginBottom: -1,
    paddingTop: theme.spacing(4.4),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(4.4),
    paddingLeft: theme.spacing(3),
    '&:hover': {
      textDecoration: 'underline',
      background: theme.palette.action.hover,
    },
    '&:focus-visible': {
      zIndex: 10,
      borderRadius: 1,
      boxShadow: `0 0 0 0.1rem ${
        theme.palette.mode === 'dark'
          ? theme.palette.common.black
          : theme.palette.common.white
      }, 0 0 0 0.25rem ${
        theme.palette.mode === 'dark'
          ? theme.palette.common.white
          : theme.palette.common.black
      }`,
    },
  },
  content: {
    alignItems: 'center',
    '& p': {
      lineHeight: 1,
    },
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionIcon = styled(Icon)`
  ${fastTransition()}

  &.rotate {
    transform: rotate(-180deg);
  }
`;

const AccordionDetails = withStyles((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(6.4),
    paddingTop: theme.spacing(2.4),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}))(MuiAccordionDetails);

export type AccordionItemProps = {
  /**
   * The accordion item content
   */
  children: ReactNode;
  /**
   * Props applied to the expandable area
   */
  ExpandableAreaProps?: AccordionDetailsProps;
  /**
   * Props applied to the header
   */
  HeaderProps?: Omit<AccordionSummaryProps, 'aria-controls' | 'id'>;
  /**
   * If `true`, the item is initially expanded.
   */
  initExpanded?: boolean;
  /**
   * The accordion item title
   */
  title: ReactNode;
  /**
   * The content of Accordions is mounted by default even if the accordion is not expanded.
   * If you render expensive component trees inside your accordion details or simply render
   * many accordions it might be a good idea to change this default behavior by enabling the
   * unmountOnExit. As with any performance optimization this is not a silver bullet.
   */
  unmountOnExit?: boolean;
};

export const AccordionItem: FC<AccordionItemProps> = ({
  children,
  ExpandableAreaProps,
  HeaderProps,
  initExpanded,
  title,
  unmountOnExit,
}) => {
  const [expanded, setExpanded] = useState(!!initExpanded);
  const id = useMemo(() => nanoid(), []);

  return (
    <StyledAccordion
      expanded={expanded}
      onChange={() => setExpanded((prev) => !prev)}
      square
      TransitionProps={{ unmountOnExit }}
    >
      <AccordionSummary
        {...HeaderProps}
        aria-controls={`panel-content-${id}`}
        id={`panel-header-${id}`}
      >
        {typeof title === 'string' ? (
          <Typography bold>{title}</Typography>
        ) : (
          title
        )}

        <AccordionIcon
          className={classNames('ml-auto', { rotate: expanded })}
          name="chevron_down"
        />
      </AccordionSummary>

      <AccordionDetails {...ExpandableAreaProps}>
        {typeof children === 'string' ? (
          <Typography>{children}</Typography>
        ) : (
          children
        )}
      </AccordionDetails>
    </StyledAccordion>
  );
};

AccordionItem.displayName = 'AccordionItem';
