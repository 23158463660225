import React, { ReactNode } from 'react';
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import MuiTab from '@mui/material/Tab';

import styled from '../../../utils/styled';
import { focusVisibleInsetBoxShadow } from '../../../hooks/enovaContext/globalStyles';

import { TabValue } from './utils';

type TabTitleProps = {
  label: ReactNode;
  value: TabValue;
};

const StyledTab = styled(MuiTab)`
  ${focusVisibleInsetBoxShadow}
` as typeof MuiTab;

export const TabTitle = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: theme.shape.borderRadius,
      fontWeight: theme.typography.fontWeightRegular,
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      maxWidth: 'none',
      minHeight: 0,
      minWidth: 0,
      display: 'flex',
      color: theme.palette.text.primary,
      flexDirection: 'row',
      opacity: 1,
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
      textTransform: 'none',
      zIndex: 1,
      '&:hover': {
        background: theme.palette.action.hover,
      },
      '&:active': {
        background: theme.palette.action.focus,
      },
      '&:first-child': {
        marginLeft: 0,
      },
    },
    selected: {
      fontWeight: theme.typography.fontWeightBold,
    },
    wrapper: {
      flexDirection: 'row',
    },
  })
)((props: TabTitleProps) => <StyledTab wrapped={false} {...props} />);
