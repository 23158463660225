import React, { FC, HTMLAttributes } from 'react';

import { EnovaTheme } from '../../theme/types';
import styled from '../../utils/styled';

export type LoaderProps = Omit<HTMLAttributes<HTMLDivElement>, 'color'> & {
  /**
   * Loader color
   */
  color?: 'primary' | 'inherit';
  /**
   * Loader size
   */
  size?: 'small' | 'large';
};

type StyledLoaderProps = LoaderProps & {
  theme: EnovaTheme;
};

const StyledLoader = styled.div`
  --color: ${({ theme, color }: StyledLoaderProps) =>
    color === 'inherit' ? 'currentColor' : theme.palette.primary.main};

  --expansion: 1em;
  --height: 4em;
  --heightExpanded: 5em;
  --spacing: 0.5em;
  --width: 1em;

  align-items: center;
  display: flex;
  font-size: ${({ size }: StyledLoaderProps) =>
    size === 'large' ? '12px' : '5px'};
  height: calc(var(--heightExpanded) + var(--expansion));
  justify-content: center;
  width: calc(calc(var(--width) * 3) + calc(var(--spacing) * 2));

  span {
    background: var(--color);
    animation: load1 1s infinite ease-in-out;
    width: var(--width);
    height: var(--height);
    color: var(--color);
    position: relative;
    transform: translateZ(0);
    animation-delay: -0.15s;

    &:before,
    &:after {
      background: var(--color);
      animation: load1 1s infinite ease-in-out;
      width: var(--width);
      height: var(--height);
      position: absolute;
      top: 0;
      content: '';
    }

    &:before {
      left: calc(calc(var(--width) + var(--spacing)) * -1);
      animation-delay: -0.3s;
    }

    &:after {
      left: calc(var(--width) + var(--spacing));
    }

    @keyframes load1 {
      0%,
      80%,
      100% {
        box-shadow: 0 0;
        margin-bottom: 0;
        height: var(--height);
      }
      40% {
        box-shadow: 0 calc(var(--expansion) * -1);
        margin-bottom: calc(var(--expansion) * -1);
        height: var(--heightExpanded);
      }
    }
  }
`;

export const Loader: FC<LoaderProps> = (props) => (
  <StyledLoader {...props}>
    <span />
  </StyledLoader>
);

Loader.defaultProps = {
  color: 'primary',
  size: 'small',
};
