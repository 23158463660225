import React, { MouseEvent } from 'react';
import TableCell from '@mui/material/TableCell';
import MuiTableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import classNames from 'classnames';

import { Typography } from '../typography';
import styled from '../../../utils/styled';

import { TTableData, TableHeadProps } from './utils';
import { TableCheckbox } from './tableCheckbox';

const StyledTableHead = styled(MuiTableHead)`
  &.hidden {
    position: absolute;
    left: -999999em;
  }
` as typeof MuiTableHead;

export const TableHead = <T extends TTableData>({
  headCells,
  hideHeader,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  order,
  orderBy,
  rowCount,
  selectable,
}: TableHeadProps<T>) => {
  const createSortHandler = (property: keyof T) => (event: MouseEvent) =>
    onRequestSort(event, property);

  return (
    <StyledTableHead className={classNames({ hidden: hideHeader })}>
      <TableRow>
        {selectable && (
          <TableCell padding="checkbox" className="pr-0">
            <TableCheckbox
              checked={rowCount > 0 && numSelected === rowCount}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all',
              }}
            />
          </TableCell>
        )}

        {headCells.map(({ id, label, align, disableSort, numeric }) =>
          disableSort ? (
            <TableCell
              key={String(id)}
              align={align || numeric ? 'right' : 'left'}
            >
              {label}
            </TableCell>
          ) : (
            <TableCell
              key={String(id)}
              align={align || numeric ? 'right' : 'left'}
              sortDirection={orderBy === id ? order : false}
            >
              <TableSortLabel
                className="fvbsr"
                active={orderBy === id}
                direction={orderBy === id ? order : 'asc'}
                onClick={createSortHandler(id)}
              >
                {label}
                {orderBy === id ? (
                  <Typography variant="srOnly">
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Typography>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        )}
      </TableRow>
    </StyledTableHead>
  );
};
