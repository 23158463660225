import React, { FC, forwardRef } from 'react';
import MuiGrid, { GridProps as MuiGridProps } from '@mui/material/Grid';

export const GridSpacingOptions = [
  undefined,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
] as const;
export type GridSpacing = typeof GridSpacingOptions[number];

export type GridProps = Omit<MuiGridProps, 'classes' | 'spacing'> & {
  /**
   * Defines the space between the type `item` component. It can only be used on a type `container` component.
   */
  spacing?: GridSpacing;
};

export const Grid: FC<GridProps> = forwardRef((props, ref) => (
  <MuiGrid {...props} ref={ref} />
));

Grid.displayName = 'Grid';
