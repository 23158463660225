import React from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';

import styled, { css } from '../../utils/styled';

export const helperTextCSS = css`
  color: inherit;
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  font-weight: ${({ theme }) => theme.typography.body2.fontWeight};
  margin: 0;

  &.disabled,
  &.disabled * {
    opacity: ${({ theme }) => theme.palette.action.disabledOpacity};
  }

  &.error,
  &.error * {
    color: ${({ theme }) => theme.palette.error.main};
  }
`;

export const labelCSS = css`
  color: inherit;
  font-size: ${({ theme }) => theme.typography.h4.fontSize};
  font-weight: ${({ theme }) => theme.typography.h4.fontWeight};
  margin-bottom: 0.5rem;
  overflow: visible;
  pointer-events: all;
  position: relative;
  transform: none;
  white-space: normal;

  &.disabled {
    opacity: ${({ theme }) => theme.palette.action.disabledOpacity};
  }
`;

export type MarginProps = {
  /**
   * Adds consistent margin to the bottom of the component. Intended for displaying multiple consecutive fields in a column.
   */
  marginBottom?: boolean;
  /**
   * Adds consistent margin to the top of the component. Intended for displaying multiple consecutive fields in a column.
   */
  marginTop?: boolean;
  /**
   * Adds consistent margin to the top and bottom of the component. Intended for displaying multiple consecutive fields in a column.
   */
  marginY?: boolean;
};

export const getMarginClasses: (
  input: MarginProps
) => Record<string, boolean | undefined> = ({
  marginBottom,
  marginTop,
  marginY,
}) => ({
  'mb-3': marginBottom,
  'mt-3': marginTop,
  'my-3': marginY,
});

export type Width = 'auto' | 'default' | 'fullWidth';

export const defaultWidth: Width = 'default';

export type WidthProps = {
  /**
   * Adds consistent margin to the bottom of the component. Intended for displaying multiple consecutive fields in a column.
   */
  width?: Width;
};

export const getWidthClasses: (
  input: WidthProps
) => Record<string, boolean | undefined> = ({ width = defaultWidth }) => ({
  'w-100': width === 'fullWidth',
  'w-fit-content': width === 'auto',
  'w-default': width === 'default',
});

export const StyledLabel = styled((props) => (
  <FormLabel
    classes={{
      asterisk: 'asterisk',
      disabled: 'disabled',
      root: 'label',
    }}
    {...props}
  />
))`
  &.label,
  &.label .asterisk {
    ${labelCSS}
  }
`;

export const StyledHelperText = styled((props) => (
  <FormHelperText classes={{ root: 'helper', error: 'error' }} {...props} />
))`
  ${helperTextCSS}
`;
