import React, { FC, forwardRef, ElementType } from 'react';
import MuiTypography, {
  TypographyProps as MuiTypographyProps,
} from '@mui/material/Typography';
import { Variant } from '@mui/material/styles/createTypography';
import { createStyles, makeStyles } from '@mui/styles';
import classNames from 'classnames';

import styled from '../../utils/styled';
import { bgGradient } from '../../theme/utils';

export const TypographyComponents = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'label',
  'p',
  'span',
  'div',
] as const;
export type TypographyComponent = typeof TypographyComponents[number];

const extraVariants = ['ingress', 'spotlight'];

export const TypographyVariants = [
  'body1',
  'body2',
  'h1',
  'h2',
  'h3',
  'h4',
  'srOnly',
  ...extraVariants,
] as const;
export type TypographyVariant = typeof TypographyVariants[number];

export type TypographyProps = Omit<
  MuiTypographyProps,
  'classes' | 'variantMapping' | 'variant'
> & {
  bold?: boolean;
  italic?: boolean;
  /**
   * Text color.
   */
  color?: 'error' | 'inherit' | 'initial' | 'textPrimary' | 'textSecondary';
  /**
   * Overrides the default component for current variant, and render the specified component.
   */
  component?: TypographyComponent;
  /**
   * Text style. Renders as mapped component specified in theme unless another component is passed.
   */
  variant?: TypographyVariant;
};

const StyledTypography = styled(MuiTypography)`
  &.sr-only {
    -webkit-clip-path: inset(50%) !important;
    border: 0 !important;
    clip-path: inset(50%) !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    white-space: nowrap !important;
    width: 1px !important;
  }

  .EfcLink {
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
    line-height: inherit;
  }

  .EfcIcon {
    font-size: inherit;
    vertical-align: middle;
  }

  .EfcLargeIcon {
    font-size: inherit;
    vertical-align: baseline;
  }

  &.bold {
    font-weight: bold;
  }

  &.italic {
    font-style: italic;
  }
` as typeof MuiTypography;

const Ingress = styled(StyledTypography)`
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2em;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    font-weight: 400;
    font-size: 1.75rem;
  }
` as typeof StyledTypography;

const Spotlight = styled(StyledTypography)`
  ${bgGradient}
  display: inline-block;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;

  &::selection {
    -webkit-text-fill-color: initial;
  }
` as typeof StyledTypography;

const useStyles = makeStyles(() =>
  createStyles({
    gutterBottom: {
      marginBottom: '0.5em',
    },
  })
);

/**
 * #### Accessibility
 *
 * A few key factors to follow for an accessible typography:
 *
 * - **Color**. Provide enough contrast between text and its background, check out the minimum recommended [WCAG 2.0 color contrast ratio](https://www.w3.org/TR/UNDERSTANDING-WCAG20/visual-audio-contrast-contrast.html) (4.5:1).
 * - **Font size**. Use [relative units (rem)](https://mui.com/customization/typography/#font-size) to accommodate the user's settings.
 * - **Heading hierarchy**. [Don't skip](https://www.w3.org/WAI/tutorials/page-structure/headings/) heading levels. In order to solve this problem, you need to [separate the semantics from the style](https://mui.com/components/typography/#changing-the-semantic-element).
 */
export const Typography: FC<TypographyProps> = forwardRef(
  ({ className, bold, italic, variant, ...rest }, ref) => {
    const classes = useStyles();

    let Component: ElementType<MuiTypographyProps>;

    switch (variant) {
      case 'ingress':
        Component = Ingress;
        break;
      case 'spotlight':
        Component = Spotlight;
        break;
      default:
        Component = StyledTypography;
    }

    return (
      <Component
        classes={{ gutterBottom: classes.gutterBottom }}
        className={classNames(
          { bold, italic, 'sr-only': variant === 'srOnly' },
          className
        )}
        variant={
          variant && extraVariants.includes(variant)
            ? undefined
            : (variant as Variant)
        }
        ref={ref}
        {...(rest as MuiTypographyProps)}
      />
    );
  }
);

Typography.displayName = 'Typography';
