import {
  createTheme,
  darken as muiDarken,
  lighten as muiLighten,
} from '@mui/material/styles';
import merge from 'deepmerge';

import { css } from '../utils/styled';

import { DarkThemeDefaultOptions, LightThemeDefaultOptions } from './base';
import { EnovaTheme, EnovaThemeOptions } from './types';

export const efcLighten = (color: string) => muiLighten(color, 0.1);

export const efcDarken = (color: string) => muiDarken(color, 0.1);

export const gradient = (color1: string, color2: string, vertical?: boolean) =>
  `linear-gradient(to ${vertical ? 'top' : 'right'}, ${color1}, ${color2})`;

export const getGradient = (theme: EnovaTheme) =>
  gradient(theme.palette.primary.main, theme.palette.secondary.main);

export const getGradientVertical = (theme: EnovaTheme) =>
  gradient(theme.palette.primary.main, theme.palette.secondary.main, true);

export const getGradientSecondary = (theme: EnovaTheme) =>
  gradient(theme.palette.secondary.main, theme.palette.extras.orange);

export const getGradientSecondaryVertical = (theme: EnovaTheme) =>
  gradient(theme.palette.secondary.main, theme.palette.extras.orange, true);

export const bgGradient = css`
  background: ${({ theme }) => getGradient(theme)};
`;

export const bgGradientVertical = css`
  background: ${({ theme }) => getGradientVertical(theme)};
`;

export const bgGradientSecondary = css`
  background: ${({ theme }) => getGradientSecondary(theme)};
`;

export const bgGradientSecondaryVertical = css`
  background: ${({ theme }) => getGradientSecondaryVertical(theme)};
`;

export const createEnovaTheme = (
  themeOptions?: EnovaThemeOptions
): EnovaTheme =>
  createTheme(
    merge(
      themeOptions?.palette?.mode === 'dark'
        ? DarkThemeDefaultOptions
        : LightThemeDefaultOptions,
      themeOptions || {}
    )
  ) as unknown as EnovaTheme;
