import React, { ElementType, FC, forwardRef } from 'react';
import classNames from 'classnames';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';

import styled from '../../utils/styled';
import { Icon, IconOption } from '../dataDisplay/icon';
import { fastTransition } from '../../hooks/enovaContext/globalStyles';
import { Loader } from '../feedback/loader';

export type ButtonProps = Omit<
  MuiButtonProps,
  | 'action'
  | 'centerRipple'
  | 'classes'
  | 'color'
  | 'component'
  | 'disableElevation'
  | 'disableFocusRipple'
  | 'disableRipple'
  | 'disableTouchRipple'
  | 'endIcon'
  | 'focusRipple'
  | 'focusVisibleClassName'
  | 'onFocusVisible'
  | 'startIcon'
  | 'TouchRippleProps'
  | 'variant'
> & {
  /**
   * Accepts allowed icon names and places specified icon to the left with the appropriate color
   */
  leftIcon?: IconOption;
  /**
   * If `true`, the button will be disabled and have a progress spinner
   */
  loading?: boolean;
  /**
   * If `true`, the `Button` will have a double arrow end icon, and the icon and button text will be spaced. Replaces `rightIcon`
   */
  navigation?: boolean;
  /**
   * Accepts allowed icon names and places specified icon to the right with the appropriate color
   */
  rightIcon?: IconOption;
  /**
   * Sets the button color scheme
   */
  variant?: 'primary' | 'secondary';
};

const StyledButton = styled(MuiButton)`
  transition: background 100ms ease-in-out, color 100ms ease-in-out;
  border-radius: 104px;
  border: 2px solid transparent;

  &.btn-navigation {
    display: flex;
    justify-content: space-between;
    padding-right: ${({ theme }) => theme.spacing(4)};

    svg {
      ${fastTransition('margin')}
      margin-right: ${({ theme }) => theme.spacing()};
    }

    &:hover,
    &:active,
    &:focus-visible:not(:disabled) {
      svg {
        margin-right: 0;
        margin-left: ${({ theme }) => theme.spacing()};
      }
    }
  }

  &:disabled {
    background: ${({ theme }) => theme.palette.greys.grey2};
    color: ${({ theme }) => theme.palette.greys.grey3};
  }
` as typeof MuiButton;

const PrimaryButton = styled(StyledButton)`
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};

  &:hover,
  &:focus-visible:not(:disabled) {
    background: ${({ theme }) => theme.palette.extras.lightOcean};
    border-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
  }
` as typeof MuiButton;

const SecondaryButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  color: ${({ theme }) => theme.palette.primary.main};
  border-color: ${({ theme }) => theme.palette.primary.main};
  border-width: 2px;
  border-style: solid;

  &:hover,
  &:focus-visible:not(:disabled) {
    background: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
    background: ${({ theme }) => theme.palette.extras.lightOcean};
    border-color: ${({ theme }) => theme.palette.primary.main};
    border-width: 2px;
    border-style: solid;
  }
` as typeof MuiButton;

export const Button: FC<ButtonProps> = forwardRef(
  (
    {
      children,
      className,
      disabled,
      leftIcon,
      rightIcon,
      loading,
      navigation,
      variant = 'primary',
      size,
      ...rest
    },
    ref
  ) => {
    let Component: ElementType<MuiButtonProps>;

    switch (variant) {
      case 'primary':
        Component = PrimaryButton;
        break;
      case 'secondary':
        Component = SecondaryButton;
        break;
      default:
        Component = StyledButton;
    }

    return (
      <Component
        {...rest}
        size={size ?? 'large'}
        className={classNames(
          `btn-${variant}`,
          { 'btn-navigation': navigation },
          className
        )}
        disabled={disabled || loading}
        disableElevation
        endIcon={
          navigation ? (
            <Icon name="double_arrow" />
          ) : (
            rightIcon && <Icon name={rightIcon} />
          )
        }
        startIcon={leftIcon && <Icon name={leftIcon} />}
        ref={ref}
      >
        {children}
        {loading && <Loader className="p-absolute" />}
      </Component>
    );
  }
);

Button.displayName = 'Button';
