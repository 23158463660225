import React, { ElementType, FC } from 'react';
import classNames from 'classnames';

import styled from '../../../utils/styled';
import { Button, ButtonProps } from '../../buttons/button';
import { focusVisibleInsetBoxShadow } from '../../../hooks/enovaContext/globalStyles';
import { Typography } from '../../dataDisplay/typography';

import { ToolbarMixin } from './utils';

export type AppBarButtonProps = ButtonProps & {
  component?: ElementType<ButtonProps>;
};

const StyledButtonWrapper = styled.div`
  & > button {
    ${focusVisibleInsetBoxShadow};
    align-items: center;
    background: none;
    border-radius: 0;
    border: none;
    box-shadow: none;
    color: ${({ theme }) => theme.palette.background.paper};
    display: block;
    height: ${({ theme }) => theme.mixins.toolbar.minHeight}px;
    padding: ${({ theme }) => theme.spacing(0, 3)};

    ${({ theme }) =>
      Object.entries(theme.mixins.toolbar)
        .filter(([_, obj]) => typeof obj === 'object')
        .map(
          ([key, value]) => `${key} {
          height: ${(value as ToolbarMixin).minHeight}px;
      }`
        )}
    &:hover {
      background: ${({ theme }) => theme.palette.extras.mountainGreen};
      color: ${({ theme }) => theme.palette.extras.white};
    }
  }
`;

export const AppBarButton: FC<AppBarButtonProps> = ({
  children,
  className,
  component: Component = Button,
  ...rest
}) => (
  <StyledButtonWrapper>
    <Component {...rest} className={classNames('app-bar--button', className)}>
      <Typography className="app-bar--button-label" component="p" variant="h4">
        <strong className="d-flex ai-center">{children}</strong>
      </Typography>
    </Component>
  </StyledButtonWrapper>
);

AppBarButton.displayName = 'AppBarButton';
