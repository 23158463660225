import React, { FC, forwardRef } from 'react';
import MuiIconButton, {
  IconButtonProps as MuiIconButtonProps,
} from '@mui/material/IconButton';
import classNames from 'classnames';

import styled, { css } from '../../utils/styled';
import { Icon, IconOption } from '../dataDisplay/icon';

export type IconButtonProps = Omit<
  MuiIconButtonProps,
  | 'action'
  | 'aria-label'
  | 'centerRipple'
  | 'children'
  | 'classes'
  | 'component'
  | 'disableElevation'
  | 'disableFocusRipple'
  | 'disableRipple'
  | 'disableTouchRipple'
  | 'endIcon'
  | 'focusRipple'
  | 'focusVisibleClassName'
  | 'onFocusVisible'
  | 'size'
  | 'startIcon'
  | 'TouchRippleProps'
  | 'variant'
> & {
  /**
   * Button text reachable for screen readers, but hidden from the screen
   */
  'aria-label': string;
  /**
   * Accepts allowed icon names
   */
  icon: IconOption;
  size?: 'large' | 'medium' | 'small';
  color?: 'default' | 'inherit';
};

export const IconButtonCSS = css`
  &.icon-btn--medium {
    padding: ${({ theme }) => theme.spacing()};
  }
`;

const StyledIconButton = styled(MuiIconButton)`
  ${IconButtonCSS}
` as typeof MuiIconButton;

export const IconButton: FC<IconButtonProps> = forwardRef(
  ({ className, icon, size = 'medium', ...rest }, ref) => (
    <StyledIconButton
      {...rest}
      className={classNames(className, `icon-btn--${size}`)}
      ref={ref}
      size={size !== 'large' ? 'small' : undefined}
    >
      <Icon fontSize={size === 'small' ? 'inherit' : undefined} name={icon} />
    </StyledIconButton>
  )
);

IconButton.displayName = 'IconButton';
