import React, {
  FC,
  forwardRef,
  // HTMLAttributes
} from 'react';
import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
// import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

import { BaseProps, SelectOption, SelectValue } from '../textFields/types';
import { TextFieldBase } from '../textFields/textFieldBase';
import { Icon } from '../../dataDisplay/icon';
import { Loader } from '../../feedback/loader';

import { StyledPopper, ListboxComponent } from './utils';

export type AutocompleteProps = Omit<
  MuiAutocompleteProps<SelectOption, false, false, false>,
  | 'autoComplete	'
  | 'autoFocus'
  | 'autoHighlight	'
  | 'autoSelect'
  | 'blurOnSelect'
  | 'ChipProps'
  | 'classes'
  | 'clearIcon'
  | 'clearOnBlur'
  | 'clearOnEscape'
  | 'clearText'
  | 'componentName'
  | 'componentsProps'
  | 'disableClearable'
  | 'disableCloseOnSelect'
  | 'disabledItemsFocusable'
  | 'disableListWrap'
  | 'disablePortal'
  | 'filterOptions'
  | 'filterSelectedOptions'
  | 'forcePopupIcon'
  | 'freeSolo'
  | 'getLimitTagsText'
  | 'getOptionDisabled'
  | 'getOptionLabel'
  | 'getTagProps'
  | 'groupBy'
  | 'handleHomeEndKeys'
  | 'includeInputInList'
  | 'InputProps'
  | 'inputValue'
  | 'isOptionEqualToValue'
  | 'limitTags'
  | 'ListboxComponent'
  | 'ListboxProps'
  | 'multiple'
  | 'onHighlightChange'
  | 'openOnFocus'
  | 'PaperComponent'
  | 'PopperComponent'
  | 'popupIcon'
  | 'ref'
  | 'renderGroup'
  | 'renderInput'
  | 'renderOption'
  | 'renderTags'
  | 'select'
  | 'selectOnFocus'
  | 'SelectProps'
  | 'value'
> &
  Omit<BaseProps, 'value' | 'onChange' | 'type'> & { value?: SelectValue };

export const Autocomplete: FC<AutocompleteProps> = forwardRef(
  (
    {
      autoFocus,
      error,
      helperText,
      HelperBoxProps,
      label,
      options = [],
      fullWidth,
      loadingText,
      marginBottom,
      marginTop,
      marginY,
      loading,
      required,
      value,
      placeholder,
      ...rest
    },
    ref
  ) => {
    const renderInput = (params: AutocompleteRenderInputParams) => (
      <TextFieldBase
        {...params}
        autoFocus={autoFocus}
        error={error}
        fullWidth={fullWidth}
        HelperBoxProps={HelperBoxProps}
        helperText={helperText}
        label={label}
        marginBottom={marginBottom}
        marginTop={marginTop}
        marginY={marginY}
        ref={ref}
        placeholder={placeholder}
        required={required}
      />
    );

    return (
      <MuiAutocomplete
        {...rest}
        fullWidth={fullWidth}
        autoComplete
        autoHighlight
        classes={{ inputRoot: 'pt-0 pb-0 pl-0' }}
        clearIcon={<Icon name="close" color="inherit" fontSize="small" />}
        disableListWrap
        getOptionDisabled={(option) => !!(option as SelectOption).disabled}
        getOptionLabel={(option) => String((option as SelectOption).label)}
        ListboxComponent={ListboxComponent}
        loading={loading}
        loadingText={
          <Box display="flex" justifyContent="center" alignItems="center">
            <Loader color="inherit" />
            {loadingText}
          </Box>
        }
        options={options}
        PopperComponent={StyledPopper}
        popupIcon={<Icon name="chevron_down" color={undefined} />}
        renderInput={renderInput}
        renderOption={(props, option) => [props, option]}
        value={options.find((item) => item.value === value) || null}
      />
    );
  }
);

Autocomplete.displayName = 'Autocomplete';
