import React, { FC, useMemo } from 'react';
import Divider from '@mui/material/Divider';
import { nanoid } from 'nanoid';
import classNames from 'classnames';

import { EnovaTheme } from '../../../theme/types';
import styled from '../../../utils/styled';
import { Typography } from '../typography';

import { EnergyGrade, EnergyGrades } from './utils';

export type EnergyRatingProps = {
  /**
   * Current grade value
   */
  currentGrade?: EnergyGrade;
  /**
   * Description of actual value
   */
  currentGradeDesc?: string;
  /**
   * General descrption
   */
  desc?: string;
  /**
   * Value of potential
   */
  potentialGrade?: EnergyGrade;
  /**
   * Description of potential value
   */
  potentialGradeDesc?: string;
  /**
   * Alternative description for screen readers
   */
  screenReaderCaption: string;
};

type WrapperProps = { columns: number };

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }: WrapperProps) =>
    [
      'minmax(100px, 260px)',
      ...Array.from(Array(columns - 1 || 0)).map(() => 'min-content'),
    ].join(' ')};
  column-gap: ${({ theme }) => theme.spacing(2)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-template-columns: ${({ columns }: WrapperProps) =>
      [...Array.from(Array(columns)).map(() => 'min-content')].join(' ')};
    column-gap: ${({ theme }) => theme.spacing(2)};
  }
`;

type EnergyGradeProps = {
  theme: EnovaTheme;
  level: EnergyGrade;
};

const eneryGradeBarHeight = 30; // px

const EnergyGradeBar = styled.div`
  align-items: center;
  background: ${({ level, theme }: EnergyGradeProps) =>
    theme.palette.energyGrade[level]};
  color: ${({ level, theme }) =>
    theme.palette.getContrastText(theme.palette.energyGrade[level])};
  display: flex;
  grid-column: 1;
  height: ${eneryGradeBarHeight}px;
  margin: ${({ level, theme }) =>
    `${theme.spacing(0.5)} ${
      EnergyGrades.length - EnergyGrades.indexOf(level) + 1
    }ch ${theme.spacing(0.5)} 0`};
  padding: ${({ theme }) => theme.spacing(0.25, 0, 0.25, 2)};
  position: relative;
  text-transform: uppercase;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-right: ${eneryGradeBarHeight / 2}px;
    width: ${({ level }) =>
      `calc(150px + ${EnergyGrades.indexOf(level) * 1.75}ch)`};
  }

  &:after {
    border-color: ${({ level, theme }: EnergyGradeProps) =>
      `transparent transparent transparent ${theme.palette.energyGrade[level]}`};
    border-style: solid;
    border-width: ${eneryGradeBarHeight / 2}px;
    content: '';
    height: 0;
    position: absolute;
    right: ${eneryGradeBarHeight * -1}px;
    top: 0;
    width: 0;
  }
`;

const EnergyGradeIndicatorMargin = -0.5; // Spacer parameter

const EnergyGradeIndicator = styled.div`
  background: ${({ theme }: EnergyGradeProps) => theme.palette.text.primary};
  color: ${({ theme }) =>
    theme.palette.getContrastText(theme.palette.text.primary)};
  grid-column: 2;
  grid-row: ${({ level }) => EnergyGrades.indexOf(level) + 1};
  padding-left: ${({ theme }) => theme.spacing(2)};
  position: relative;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  width: 40px;
  margin: ${({ theme }) =>
    `${theme.spacing(EnergyGradeIndicatorMargin)} 0 ${theme.spacing(
      EnergyGradeIndicatorMargin
    )} calc(${eneryGradeBarHeight / 2}px +
      ${theme.spacing(0.5 + EnergyGradeIndicatorMargin * -1)})`};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 50px;
  }

  &:before {
    border-color: transparent;
    border-right-color: ${({ theme }: EnergyGradeProps) =>
      theme.palette.text.primary};
    border-style: solid;
    border-width: ${({ theme }) =>
      `calc(${eneryGradeBarHeight / 2}px +
      ${theme.spacing(0.5 + EnergyGradeIndicatorMargin * -1)})`};
    content: '';
    height: 0;
    position: absolute;
    left: ${({ theme }) =>
      `calc(${-1 * eneryGradeBarHeight}px - ${theme.spacing(
        1 + EnergyGradeIndicatorMargin * -2
      )})`};
    top: 0;
    width: 0;
  }

  span {
    margin-top: ${({ theme }) => theme.spacing(-0.5)};
  }

  &.potential {
    background: ${({ theme }: EnergyGradeProps) =>
      theme.palette.text.secondary};
    grid-column: 4;

    &:before {
      border-right-color: ${({ theme }: EnergyGradeProps) =>
        theme.palette.text.secondary};
    }
  }
`;

const GridDivider = styled(Divider)`
  grid-column: 3;
  grid-row: 1 / ${EnergyGrades.length + 1};

  &.edge-bottom {
    margin-bottom: ${({ theme }) =>
      theme.spacing(EnergyGradeIndicatorMargin * -1)};
  }

  &.edge-top {
    margin-top: ${({ theme }) =>
      theme.spacing(EnergyGradeIndicatorMargin * -1)};
  }
` as typeof Divider;

type DescriptionProps = {
  column: number;
  theme: EnovaTheme;
};

const Description = styled.div`
  grid-column: ${({ column }: DescriptionProps) => column};
  grid-row: ${EnergyGrades.length + 1};
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-left: ${({ theme }) => theme.spacing(2)};
`;

export const EnergyRating: FC<EnergyRatingProps> = ({
  currentGrade,
  currentGradeDesc,
  desc,
  potentialGrade,
  potentialGradeDesc,
  screenReaderCaption,
  ...rest
}) => {
  const srCaptionId = useMemo(() => nanoid(), []);

  return (
    <section aria-describedby={srCaptionId}>
      <Typography id={srCaptionId} variant="srOnly">
        {screenReaderCaption}
      </Typography>

      <Wrapper
        aria-hidden
        columns={
          1 +
          (currentGrade ? 1 : 0) +
          (potentialGrade || potentialGradeDesc ? 2 : 0)
        }
        {...rest}
      >
        {EnergyGrades.map((level) => (
          <EnergyGradeBar key={level} level={level}>
            <Typography variant="h4" component="span">
              {level}
            </Typography>
          </EnergyGradeBar>
        ))}

        {desc && (
          <Description column={1} className="ml-0">
            <Typography>{desc}</Typography>
          </Description>
        )}

        {currentGrade && (
          <EnergyGradeIndicator level={currentGrade}>
            <Typography color="inherit" variant="h3" component="span">
              {currentGrade}
            </Typography>
          </EnergyGradeIndicator>
        )}

        {currentGradeDesc && (
          <Description column={2}>
            <Typography>{currentGradeDesc}</Typography>
          </Description>
        )}

        {(potentialGrade || potentialGradeDesc) && (
          <GridDivider
            className={classNames({
              'edge-top':
                currentGrade === EnergyGrade.A ||
                potentialGrade === EnergyGrade.A,
              'edge-bottom':
                currentGrade === EnergyGrade.G ||
                potentialGrade === EnergyGrade.G,
            })}
            orientation="vertical"
            flexItem
          />
        )}

        {potentialGrade && (
          <EnergyGradeIndicator className="potential" level={potentialGrade}>
            <Typography color="inherit" variant="h3" component="span">
              {potentialGrade}
            </Typography>
          </EnergyGradeIndicator>
        )}

        {potentialGradeDesc && (
          <Description column={4}>{potentialGradeDesc}</Description>
        )}
      </Wrapper>
    </section>
  );
};

EnergyRating.displayName = 'EnergyRating';
