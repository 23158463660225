import React, { FC, useState } from 'react';
import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import classNames from 'classnames';

import { Icon } from '../icon';
import styled from '../../../utils/styled';

const StyledCheckbox = styled(MuiCheckbox)`
  &:hover,
  &.focus {
    border-radius: 8px;
    outline-offset: -4px;
    outline: 2px solid rgba(0, 0, 0, 0.87);
  }
`;

export const TableCheckbox: FC<CheckboxProps> = ({ checked, ...rest }) => {
  const [focus, setFocus] = useState(false);

  return (
    <StyledCheckbox
      {...rest}
      checked={checked}
      checkedIcon={<Icon name="check_checked" />}
      className={classNames({ focus })}
      icon={<Icon name="check_unchecked" />}
      indeterminateIcon={<Icon name="check_indeterminate" />}
      onBlur={() => setFocus(false)}
      onFocusVisible={() => !focus && setFocus(true)}
    />
  );
};
