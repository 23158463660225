import React, { FC } from 'react';
import MuiBox, { BoxProps as MuiBoxProps } from '@mui/material/Box';

const Box: FC<MuiBoxProps> = ({ children, ...rest }) => (
  <MuiBox {...rest}>{children}</MuiBox>
);

Box.displayName = 'Box';

export type BoxProps = MuiBoxProps;

export default Box;
