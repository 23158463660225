import React, { FC, forwardRef, useMemo } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { capitalize } from '@mui/material/utils';
import Box from '@mui/material/Box';
import { nanoid } from 'nanoid';

import { EnovaTheme } from '../../../theme/types';

import {
  ShapeMap,
  ShapeOptions,
  ShapeOption,
  InvertedShapeMap,
} from './shapeOptions';

export type ShapeProps = Omit<
  SvgIconProps,
  | 'children'
  | 'classes'
  | 'color'
  | 'fontSize'
  | 'htmlColor'
  | 'shapeRendering'
  | 'size'
  | 'titleAccess'
  | 'viewBox'
> & {
  name: ShapeOption;
  inverted?: boolean;
};

const useStyles = (color?: string) =>
  makeStyles((theme: EnovaTheme) =>
    createStyles({
      root: {
        pointerEvents: 'none',
        fontSize: '4rem',
        width: '100%',
        height: '100%',
        '& > path:first-child': {
          fill: theme.palette.action.selected,
          stroke: 'currentColor',
        },
        '& > path:not(:last-child)': {
          fill: theme.palette.action.selected,
        },
        '& > path:last-child:not(:first-child)': {
          fill: 'currentColor',
        },
        '&.inverted': {
          '& > path:first-child': {
            fill: 'currentColor',
            stroke: 'transparent',
          },
          '& > path:not(:last-child)': {
            fill: 'currentColor',
          },
          '& > path:last-child:not(:first-child)': {
            fill: 'transparent',
          },
          '&.multi_zoned_wall > path:last-child': {
            stroke: theme.palette.getContrastText(
              color || theme.palette.text.primary
            ),
          },
          '&.multi_zoned_floor > path:last-child': {
            stroke: theme.palette.getContrastText(
              color || theme.palette.text.primary
            ),
          },
        },
      },
    })
  );

export const Shape: FC<ShapeProps> = forwardRef(
  ({ 'aria-label': ariaLabel, className, inverted, name, ...rest }, ref) => {
    const id = useMemo(() => nanoid(), []);
    const el = document.getElementById(id);
    const color = el ? window.getComputedStyle(el)?.color : undefined;

    const classes = useStyles(color)();
    const title = capitalize(name).replace('_', ' ');

    let Component = ShapeMap[name];
    let hasInvertedShape = false;

    if (inverted) {
      hasInvertedShape = !!InvertedShapeMap[name];
      if (hasInvertedShape) Component = InvertedShapeMap[name]!;
    }

    return (
      <Box
        alignItems="center"
        display="flex"
        height="3rem"
        id={id}
        justifyContent="center"
        width="4rem"
      >
        <Component
          {...rest}
          aria-label={ariaLabel || title}
          className={classNames(
            name,
            'EfcShape',
            { [classes.root]: inverted && !hasInvertedShape },
            { inverted: inverted && !hasInvertedShape },
            className
          )}
          ref={ref}
        />
      </Box>
    );
  }
);

Shape.displayName = 'Shape';

export { ShapeOptions, ShapeOption };
