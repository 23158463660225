import React, { FC, ReactNode, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import { nanoid } from 'nanoid';
import InputLabel from '@mui/material/InputLabel';
import classNames from 'classnames';
import { TextFieldProps } from '@mui/material/TextField';

import { IconButton } from '../buttons/iconButton';
import styled from '../../utils/styled';
import { HelperBox, HelperBoxProps } from '../feedback/helperBox';

import { getMarginClasses, labelCSS, MarginProps } from './utils';
import { StyledHelperText } from './selection/styles';

export type NumberPickerProps = Pick<
  TextFieldProps,
  'disabled' | 'error' | 'fullWidth' | 'helperText' | 'id' | 'label'
> &
  MarginProps & {
    /**
     * If `HelperBoxProps` is provided, a `HelperBox` with the provided props will be placed beside the label.
     */
    HelperBoxProps?: HelperBoxProps;
    label: ReactNode;
    max?: number;
    min?: number;
    onChange: (value: number) => void;
    step?: number;
    value: number;
  };

const StyledWrapper = styled(Box)`
  width: ${({ theme }) => theme.size.inputWidth}px;

  &.fullWidth {
    width: 100%;
  }
` as typeof Box;

const StyledLabel = styled(InputLabel)`
  ${labelCSS}
` as typeof InputLabel;

const StyledInput = styled.input`
  background: none;
  border: none;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${({ theme }) => theme.typography.h4.fontSize};
  font-weight: ${({ theme }) => theme.typography.h4.fontWeight};
  min-width: 4ch;
  text-align: center;
  width: ${({ value, step }) =>
    `${
      String(Math.floor(value as number)).length +
      1 +
      ((step as number) % 2 !== 0 ? String((step as number) % 2).length - 1 : 0)
    }ch`};

  &:disabled {
    color: ${({ theme }) => theme.palette.text.disabled};
  }
`;

export const NumberPicker: FC<NumberPickerProps> = ({
  disabled,
  error,
  fullWidth,
  HelperBoxProps,
  helperText,
  id: customId,
  label,
  marginBottom,
  marginTop,
  marginY,
  max,
  min,
  onChange,
  step = 1,
  value,
}) => {
  const id = useMemo(() => customId || nanoid(), [customId]);
  const helperTextId = useMemo(nanoid, [customId]);

  useEffect(() => {
    if (min != null && max != null && min > max)
      console.error('Minimum value cannot be greater than maximum value.');

    if (min != null && value < min)
      console.error('Provided value is less than minimum allowed value.');

    if (max != null && value > max)
      console.error('Provided value is greater than maximum allowed value.');
  }, [min, max, value]);

  return (
    <StyledWrapper
      className={classNames(
        { fullWidth },
        getMarginClasses({ marginBottom, marginTop, marginY })
      )}
      display="flex"
      flexDirection="column"
    >
      <Box display="flex" alignItems="baseline">
        <StyledLabel disabled={disabled} htmlFor={id}>
          {label}
        </StyledLabel>

        {HelperBoxProps && <HelperBox className="ml-2" {...HelperBoxProps} />}
      </Box>

      <Box display="flex" justifyContent="flex-start" maxWidth={1}>
        <IconButton
          aria-controls={id}
          aria-label={`decrement value by ${step}`}
          className="mr-1"
          disabled={disabled || (min != null && value <= min)}
          icon="minus"
          onClick={() => {
            const newVal = value - step;
            onChange(min != null && newVal < min ? min : newVal);
          }}
          size="small"
        />

        <StyledInput
          aria-describedby={helperText ? helperTextId : undefined}
          disabled={disabled}
          id={id}
          inputMode="numeric"
          onFocus={(e) => e.currentTarget.blur()}
          readOnly
          step={step}
          tabIndex={-1}
          value={value}
        />

        <IconButton
          aria-controls={id}
          aria-label={`increment value by ${step}`}
          className="ml-1"
          disabled={disabled || (max != null && value >= max)}
          icon="plus"
          onClick={() => {
            const newVal = value + step;
            onChange(max != null && newVal > max ? max : newVal);
          }}
          size="small"
        />
      </Box>

      {helperText && (
        <StyledHelperText
          className={classNames({ disabled, error })}
          id={helperTextId}
        >
          {helperText}
        </StyledHelperText>
      )}
    </StyledWrapper>
  );
};
