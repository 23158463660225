import React, {
  cloneElement,
  FC,
  Fragment,
  ReactElement,
  ReactNode,
} from 'react';
import classNames from 'classnames';
import Box from '@mui/material/Box';

import styled from '../../../utils/styled';
import { Typography } from '../../dataDisplay/typography';
import { defaultWidth, getWidthClasses } from '../utils';

import { FormControlLabelProps } from './formControlLabel';
import { StyledButtonFormControlLabel } from './styles';

export type ButtonFormControlLabelProps = FormControlLabelProps & {
  checkbox?: boolean;
  checked?: boolean;
  icon?: ReactNode;
  invertedIcon?: ReactNode;
};

const StyledTypography = styled(Typography)`
  line-height: normal;
` as typeof Typography;

const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: center;
  max-width: 100%;

  & > * {
    max-width: 100%;
    max-height: 100%;
  }
`;

/**
 * Common wrapper component form `Checkboxes` and `Radios`
 */
export const ButtonFormControlLabel: FC<ButtonFormControlLabelProps> = ({
  checkbox,
  checked,
  className,
  error,
  focus,
  icon,
  invertedIcon,
  label,
  width = defaultWidth,
  ...rest
}) => {
  const classes = classNames('p-relative', { checked, checkbox });

  const labelContent =
    typeof label === 'string' ? (
      <StyledTypography className={classes} variant="body2">
        {label}
      </StyledTypography>
    ) : (label as ReactElement)?.type === Fragment ? (
      ((label as ReactElement)?.props?.children as ReactElement[])?.map(
        (item, index) =>
          index === 0
            ? cloneElement(item as ReactElement, {
                className: classNames(
                  (item as ReactElement).props.className,
                  classes
                ),
              })
            : item
      )
    ) : (
      <span className={classes}>{label}</span>
    );

  return (
    <StyledButtonFormControlLabel
      {...rest}
      className={classNames(
        getWidthClasses({ width }),
        {
          'bg-primary': checked,
          checked: checked,
          error,
          focus,
        },
        className
      )}
      classes={{
        root: 'selection-control',
        disabled: 'disabled',
        label: 'label',
      }}
      label={
        icon ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={2}
          >
            <IconWrapper>
              {(checked && invertedIcon) || icon || null}
            </IconWrapper>
            {labelContent}
          </Box>
        ) : (
          labelContent
        )
      }
    />
  );
};

ButtonFormControlLabel.displayName = 'ButtonFormControlLabel';
