import React, { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';

import styled from '../../utils/styled';

import { ToolbarMixin } from './appBar/utils';

export const StyledMain = styled.main`
  padding-top: ${({ theme }) => theme.mixins.toolbar.minHeight}px;

  ${({ theme }) =>
    Object.entries(theme.mixins.toolbar)
      .filter(([_, obj]) => typeof obj === 'object')
      .map(
        ([key, value]) => `${key} {
          padding-top: ${(value as ToolbarMixin).minHeight}px;
      }`
      )}
`;

export type MainProps = HTMLAttributes<HTMLDivElement> & {
  ContainerProps?: HTMLAttributes<HTMLDivElement>;
};

export const Main: FC<MainProps> = ({
  ContainerProps: { className, ...ContainerProps } = {},
  ...rest
}) => (
  <StyledMain {...ContainerProps} className={classNames(className, 'f-fill')}>
    <div {...rest} />
  </StyledMain>
);

Main.displayName = 'Main';
