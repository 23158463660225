import React, { FC, forwardRef, useMemo, useState } from 'react';
import { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';
import { nanoid } from 'nanoid';
import Box from '@mui/material/Box';
import classNames from 'classnames';

import { getMarginClasses } from '../utils';

import {
  ButtonFormControlLabel,
  ButtonFormControlLabelProps,
} from './buttonFormControlLabel';
import { CheckboxProps } from './types';
import { StyledCheckbox, StyledHelperText } from './styles';

export type CheckButtonProps = CheckboxProps &
  Pick<ButtonFormControlLabelProps, 'icon' | 'invertedIcon'>;

/**
 * Demos:
 *
 * - [Checkboxes](https://mui.com/components/checkboxes/)
 *
 * API:
 *
 * - [Checkbox API](https://mui.com/api/checkbox/)
 * - inherits [IconButton API](https://mui.com/api/icon-button/)
 */
export const CheckButton: FC<CheckButtonProps> = forwardRef(
  (
    {
      checked,
      className,
      error,
      helperText,
      icon,
      id: customId,
      invertedIcon,
      label,
      marginBottom,
      marginTop,
      marginY,
      onBlur,
      onFocus,
      width,
      ...rest
    },
    ref
  ) => {
    const [focus, setFocus] = useState(false);
    const id = useMemo(() => customId || nanoid(), [customId]);

    const checkbox = (
      <StyledCheckbox
        {...(rest as MuiCheckboxProps)}
        checked={checked}
        checkedIcon={<span />}
        className="p-absolute"
        color="default"
        icon={<span />}
        id={id}
        inputRef={ref}
        onBlur={(e) => {
          setFocus(false);
          onBlur && onBlur(e);
        }}
        onFocusVisible={() => !focus && setFocus(true)}
      />
    );

    return (
      <Box
        className={classNames(
          getMarginClasses({ marginBottom, marginTop, marginY }),
          className
        )}
        display="flex"
        flexDirection="column"
      >
        <ButtonFormControlLabel
          checkbox
          checked={checked}
          control={checkbox}
          error={error}
          focus={focus}
          icon={icon}
          invertedIcon={invertedIcon}
          label={label}
          width={width}
        />

        {helperText && <StyledHelperText>{helperText}</StyledHelperText>}
      </Box>
    );
  }
);

CheckButton.displayName = 'CheckButton';
