import React, { FC, HTMLAttributes } from 'react';
import classNames from 'classnames';

import { FormMessage } from '../feedback/formMessage';

import { Autocomplete } from './autocomplete';
import { Checkbox } from './selection/checkbox';
import { CheckButton } from './selection/checkButton';
import { CheckGroup } from './selection/checkGroup';
import { NumberField } from './textFields/numberField';
import { NumberPicker } from './numberPicker';
import { Radio } from './selection/radio';
import { RadioButton } from './selection/radioButton';
import { RadioGroup } from './selection/radioGroup';
import { Select } from './textFields/select';
import { TextArea } from './textFields/textArea';
import { TextField } from './textFields/textField';
import { DatePicker } from './textFields/datePicker';

export type FormProps = HTMLAttributes<HTMLFormElement>;

export type FormSubTypes = {
  Autocomplete: typeof Autocomplete;
  Checkbox: typeof Checkbox;
  CheckButton: typeof CheckButton;
  CheckGroup: typeof CheckGroup;
  DatePicker: typeof DatePicker;
  Message: typeof FormMessage;
  NumberField: typeof NumberField;
  NumberPicker: typeof NumberPicker;
  Radio: typeof Radio;
  RadioButton: typeof RadioButton;
  RadioGroup: typeof RadioGroup;
  Select: typeof Select;
  TextArea: typeof TextArea;
  TextField: typeof TextField;
};

export const Form: FC<FormProps> & FormSubTypes = ({
  className,
  onSubmit,
  ...rest
}) => (
  <form
    className={classNames('EfcForm--root', className)}
    {...rest}
    onSubmit={(e) => {
      e.preventDefault();
      onSubmit && onSubmit(e);
    }}
  />
);

Form.Autocomplete = Autocomplete;
Form.Checkbox = Checkbox;
Form.CheckButton = CheckButton;
Form.CheckGroup = CheckGroup;
Form.DatePicker = DatePicker;
Form.Message = FormMessage;
Form.NumberField = NumberField;
Form.NumberPicker = NumberPicker;
Form.Radio = Radio;
Form.RadioButton = RadioButton;
Form.RadioGroup = RadioGroup;
Form.Select = Select;
Form.TextArea = TextArea;
Form.TextField = TextField;
Form.displayName = 'Form';
