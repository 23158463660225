import React, { FC, forwardRef, useMemo } from 'react';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/system/Box';
import classNames from 'classnames';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

import { TextField } from './textField';
import { NumberFieldProps } from './types';
import styled from 'styled-components';
import { Typography } from '../../dataDisplay/typography';
import { HelperBox } from '../../feedback/helperBox';
import { nanoid } from 'nanoid';
import { getMarginClasses, StyledHelperText, StyledLabel } from '../utils';

const NumberFieldWrapper = styled(FormControl)`
  max-width: 100%;

  &.fullwidth {
    width: 100%;
  }
`;

const Suffix = styled(Typography)`
  &.disabled {
    opacity: ${({ theme }) => theme.palette.action.disabledOpacity};
  }
`;

export const NumberField: FC<NumberFieldProps> = forwardRef(
  (
    {
      className,
      decimalPrecision,
      disabled,
      error,
      HelperBoxProps,
      helperText,
      id: customId,
      integer,
      label,
      marginBottom,
      marginTop,
      marginY,
      narrow,
      onValueChange,
      prefix,
      required,
      suffix,
      useThousandSeparator,
      value,
      allowLeadingZeros,
      ...rest
    },
    ref
  ) => {
    const id = useMemo(() => customId || nanoid(), []);
    const helperTextId = useMemo(nanoid, [customId]);

    return (
      <NumberFieldWrapper
        className={classNames(
          getMarginClasses({ marginBottom, marginTop, marginY }),
          { fullwidth: rest.fullWidth }
        )}
        disabled={disabled}
        error={error}
        required={required}
      >
        {(label || HelperBoxProps) && (
          <Box display="flex" alignItems="baseline">
            {label && <StyledLabel htmlFor={id}>{label}</StyledLabel>}

            {HelperBoxProps && (
              <HelperBox className="ml-2" {...HelperBoxProps} />
            )}
          </Box>
        )}

        <Box display="flex">
          <NumberFormat
            allowedDecimalSeparators={['.', ',']}
            aria-describedby={helperText ? helperTextId : undefined}
            className={classNames(className, 'number-field')}
            customInput={TextField}
            decimalScale={
              !integer
                ? decimalPrecision === false
                  ? undefined
                  : decimalPrecision
                : 0
            }
            error={error}
            disabled={disabled}
            getInputRef={ref}
            id={id}
            inputProps={{ inputMode: integer ? 'numeric' : 'decimal' }}
            narrow={rest.fullWidth ? false : narrow}
            onValueChange={(values, e) =>
              e.source === 'event' && onValueChange && onValueChange(values, e)
            }
            prefix={prefix ? `${prefix} ` : undefined}
            thousandSeparator={useThousandSeparator ? ' ' : undefined}
            value={value != null ? value : ''}
            allowLeadingZeros={allowLeadingZeros}
            {...(rest as NumberFormatProps)}
          />

          {suffix && (
            <Suffix
              aria-hidden
              className={classNames('d-flex', 'ai-center', 'ml-2', {
                disabled,
              })}
            >
              {suffix}
            </Suffix>
          )}
        </Box>

        {helperText && (
          <StyledHelperText className={classNames({ error })} id={helperTextId}>
            {helperText}
          </StyledHelperText>
        )}
      </NumberFieldWrapper>
    );
  }
);

NumberField.displayName = 'NumberField';

NumberField.defaultProps = {
  allowNegative: false,
  decimalPrecision: 2,
  integer: false,
  narrow: true,
  useThousandSeparator: true,
};
