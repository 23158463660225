import React, { FC, forwardRef } from 'react';
import MuiPaper, { PaperProps as MuiPaperProps } from '@mui/material/Paper';

const PaperElevationMap = {
  none: 0,
  small: 2,
  large: 8,
};

export type PaperProps = Omit<
  MuiPaperProps,
  'classes' | 'elevation' | 'variant'
> & {
  /**
   * Shadow
   */
  shadow?: keyof typeof PaperElevationMap;
  /**
   * Disregards value of `shadow` prop (removes shadow) if `true`
   */
  outlined?: boolean;
};

/**
 * Implements [Paper](https://mui.com/component/paper/) from **Material-UI**.
 */
export const Paper: FC<PaperProps> = forwardRef(
  ({ shadow = 'small', outlined, ...rest }, ref) => (
    <MuiPaper
      {...rest}
      elevation={outlined ? undefined : PaperElevationMap[shadow]}
      variant={outlined ? 'outlined' : undefined}
      ref={ref}
    />
  )
);

Paper.displayName = 'Paper';
