export enum EnergyGrade {
  A = 'a',
  B = 'b',
  C = 'c',
  D = 'd',
  E = 'e',
  F = 'f',
  G = 'g',
}

export const EnergyGrades = Object.values(EnergyGrade);

export enum HeatingGrade {
  Green = 'green',
  Lightgreen = 'lightgreen',
  Yellow = 'yellow',
  Orange = 'orange',
  Red = 'red',
}

export const HeatingGrades = Object.values(HeatingGrade);
