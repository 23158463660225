import React, { FC, forwardRef } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import classNames from 'classnames';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { capitalize } from '@mui/material/utils';

import {
  LargeIconMap,
  LargeIconOptions,
  LargeIconOption,
} from './largeIconOptions';

export type LargeIconProps = Omit<
  SvgIconProps,
  'classes' | 'children' | 'shapeRendering' | 'size' | 'titleAccess' | 'viewBox'
> & {
  name: LargeIconOption;
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      pointerEvents: 'none',
      fontSize: '2rem',
    },
  })
);

export const LargeIcon: FC<LargeIconProps> = forwardRef(
  ({ 'aria-label': ariaLabel, className, name, ...rest }, ref) => {
    const classes = useStyles();
    const title = capitalize(name).replace('_', ' ');

    const Component = LargeIconMap[name].icon;

    return (
      <SvgIcon
        {...rest}
        {...{ component: Component }}
        aria-label={ariaLabel || title}
        className={classNames('EfcLargeIcon', classes.root, className)}
        ref={ref}
        viewBox={LargeIconMap[name].viewBox}
      />
    );
  }
);

LargeIcon.displayName = 'LargeIcon';

export { LargeIconOptions, LargeIconOption };
