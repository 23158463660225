import React, { FC, ReactNode } from 'react';

import { TabValue, a11yPropsTabPanel } from './utils';

type TabPanelProps = {
  children?: ReactNode;
  identifier: TabValue;
  value: TabValue;
};

export const TabPanel: FC<TabPanelProps> = ({
  children,
  identifier,
  value,
  ...rest
}) => (
  <div
    {...rest}
    {...a11yPropsTabPanel(identifier)}
    hidden={value !== identifier}
    role="tabpanel"
  >
    {value === identifier && children}
  </div>
);

TabPanel.displayName = 'TabPanel';
