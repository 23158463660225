import { createContext, MouseEvent } from 'react';

export type MenuContextValues = {
  anchorEl: null | HTMLElement;
  onOpen: (event: MouseEvent<HTMLElement>) => void;
  onClose: () => void;
};

const MenuContext = createContext<MenuContextValues>({
  anchorEl: null,
  onOpen: () => true,
  onClose: () => true,
});

export default MenuContext;
