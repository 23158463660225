import ArrowBack from '@mui/icons-material/ArrowBackRounded';
import ArrowDownward from '@mui/icons-material/ArrowDownwardRounded';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowForward from '@mui/icons-material/ArrowForwardRounded';
import ArrowUpward from '@mui/icons-material/ArrowUpwardRounded';
import ChevronDown from '@mui/icons-material/KeyboardArrowDownRounded';
import ChevronLeft from '@mui/icons-material/KeyboardArrowLeftRounded';
import ChevronRight from '@mui/icons-material/KeyboardArrowRightRounded';
import ChevronUp from '@mui/icons-material/KeyboardArrowUpRounded';
import Close from '@mui/icons-material/CloseRounded';
import Delete from '@mui/icons-material/DeleteRounded';
import Filter from '@mui/icons-material/FilterListRounded';
import HorizontalDots from '@mui/icons-material/MoreHoriz';
import Minus from '@mui/icons-material/RemoveRounded';
import Plus from '@mui/icons-material/AddRounded';
import Reset from '@mui/icons-material/AutorenewRounded';
import VerticalDots from '@mui/icons-material/MoreVert';
import ThumbUp from '@mui/icons-material/ThumbUp';

import { ReactComponent as Check } from '../../../assets/icons/check.svg';
import { ReactComponent as CheckChecked } from '../../../assets/icons/check-checked.svg';
import { ReactComponent as CheckUnchecked } from '../../../assets/icons/check-unchecked.svg';
import { ReactComponent as CheckIndeterminate } from '../../../assets/icons/check-indeterminate.svg';
import { ReactComponent as DoubleArrow } from '../../../assets/icons/icon-2xarrow.svg';
import { ReactComponent as RadioChecked } from '../../../assets/icons/radio-checked.svg';
import { ReactComponent as RadioUnchecked } from '../../../assets/icons/radio-unchecked.svg';
import { ReactComponent as Search } from '../../../assets/icons/icon-search.svg';
import { ReactComponent as Share } from '../../../assets/icons/icon-share.svg';
import { ReactComponent as Document } from '../../../assets/icons/icon-document.svg';
import { ReactComponent as Download } from '../../../assets/icons/icon-download.svg';

const viewBox16 = '0 0 16 16';
const viewBox20 = '-2 -2 20 20';

export const EfcIconMap = {
  check_checked: { icon: CheckChecked, viewBox: viewBox20 },
  check_indeterminate: { icon: CheckIndeterminate, viewBox: viewBox20 },
  check_unchecked: { icon: CheckUnchecked, viewBox: viewBox20 },
  check: { icon: Check, viewBox: '1 1 14 14' },
  document: { icon: Document, viewBox: '0 0 29 29' },
  double_arrow: { icon: DoubleArrow, viewBox: viewBox16 },
  download: { icon: Download, viewBox: '0 0 32 32' },
  radio_checked: { icon: RadioChecked, viewBox: viewBox20 },
  radio_unchecked: { icon: RadioUnchecked, viewBox: viewBox20 },
  search: { icon: Search, viewBox: viewBox20 },
  share: { icon: Share, viewBox: '0 0 19 14' },
} as const;

export const MuiIconMap = {
  thumb_up: ThumbUp,
  arrow_down: ArrowDownward,
  arrow_dropdown: ArrowDropDown,
  arrow_left: ArrowBack,
  arrow_right: ArrowForward,
  arrow_up: ArrowUpward,
  chevron_down: ChevronDown,
  chevron_left: ChevronLeft,
  chevron_right: ChevronRight,
  chevron_up: ChevronUp,
  close: Close,
  delete: Delete,
  filter: Filter,
  horizontal_dots: HorizontalDots,
  minus: Minus,
  plus: Plus,
  reset: Reset,
  vertical_dots: VerticalDots,
} as const;

export type EfcIconOption = keyof typeof EfcIconMap;
export type MuiIconOption = keyof typeof MuiIconMap;

export type IconOption = MuiIconOption | EfcIconOption;

export const EfcIconOptions = Object.keys(EfcIconMap) as IconOption[];
export const MuiIconOptions = Object.keys(MuiIconMap) as IconOption[];

export const IconOptions = [...MuiIconOptions, ...EfcIconOptions].sort((a, b) =>
  a < b ? -1 : a > b ? 1 : 0
);
