import { ReactComponent as Atrium } from '../../../assets/shapes/atrium.svg';
import { ReactComponent as LShape } from '../../../assets/shapes/l-shape.svg';
import { ReactComponent as LShapeMirrored } from '../../../assets/shapes/l-shape-mirrored.svg';
import { ReactComponent as Rectangle } from '../../../assets/shapes/rectangular.svg';
import { ReactComponent as TShape } from '../../../assets/shapes/t-shape.svg';
import { ReactComponent as UShape } from '../../../assets/shapes/u-shape.svg';
import { ReactComponent as XShape } from '../../../assets/shapes/x-shape.svg';
import { ReactComponent as YShape } from '../../../assets/shapes/y-shape.svg';
import { ReactComponent as ZShape } from '../../../assets/shapes/z-shape.svg';
import { ReactComponent as ZShapeMirrored } from '../../../assets/shapes/z-shape-mirrored.svg';
// FLOOR
import { ReactComponent as Floor } from '../../../assets/shapes/gulv/gulv-ensartet.svg';
import { ReactComponent as FloorInverted } from '../../../assets/shapes/gulv/gulv-ensartet-inverted.svg';
import { ReactComponent as MultiZonedFloor } from '../../../assets/shapes/gulv/gulv-sone.svg';
import { ReactComponent as MultiZonedFloorInverted } from '../../../assets/shapes/gulv/gulv-sone-inverted.svg';
// WALLS
import { ReactComponent as Wall } from '../../../assets/shapes/vegg/vegg-ensartet.svg';
import { ReactComponent as WallInverted } from '../../../assets/shapes/vegg/vegg-ensartet-inverted.svg';
import { ReactComponent as MultiZonedWall } from '../../../assets/shapes/vegg/vegg-soner.svg';
import { ReactComponent as MultiZonedWallInverted } from '../../../assets/shapes/vegg/vegg-soner-inverted.svg';

export const ShapeMap = {
  atrium: Atrium,
  floor_multizoned: MultiZonedFloor,
  floor: Floor,
  l_shape_mirrored: LShapeMirrored,
  l_shape: LShape,
  rectangle: Rectangle,
  t_shape: TShape,
  u_shape: UShape,
  wall_multizoned: MultiZonedWall,
  wall: Wall,
  x_shape: XShape,
  y_shape: YShape,
  z_shape_mirrored: ZShapeMirrored,
  z_shape: ZShape,
} as const;

export type ShapeOption = keyof typeof ShapeMap;

export const ShapeOptions = Object.keys(ShapeMap) as ShapeOption[];

export const InvertedShapeMap: Partial<typeof ShapeMap> = {
  floor_multizoned: MultiZonedFloorInverted,
  floor: FloorInverted,
  wall_multizoned: MultiZonedWallInverted,
  wall: WallInverted,
} as const;
