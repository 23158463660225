import { amber } from '@mui/material/colors';
import { alpha, createTheme, lighten, darken } from '@mui/material/styles';
import merge from 'deepmerge';

import {
  EnergyGrade,
  HeatingGrade,
} from '../components/dataDisplay/energyRatings/utils';

import {
  HeatingGradePalette,
  EnergyGradePalette,
  ExtraColorsPalette,
  EnovaThemeOptions,
  EnovaColors,
} from './types';

const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1140,
  xl: 1920,
};

// Colors from Enova design system 2022
// https://www.figma.com/file/9i4MDKdNG6xqnwrCpOBAUx/Enova-Design-System-Updated?node-id=7%3A154&t=X1SUC55IPBpbVYrS-0
const EnovaThemeColors: EnovaColors = {
  // Greens
  mountainGreen: '#324947',
  fjordGreen: '#5ECA9B',
  lightGreen: '#B2E6CF',
  // Yellows
  darkSun: '#F9D006',
  sunYellow: '#EEF979',
  lightSun: '#FCFEE7',
  // Reds / Orange
  red: '#C85E5B',
  powerOrange: '#ED8550',
  lightRed: '#F4D8D7',
  // Blues
  oceanBlue: '#5F8899',
  skyBlue: '#9AFCFF',
  lightOcean: '#EFF3F5',
  // Greys
  black: '#2D2A2B',
  rockGrey: '#8F8F8F',
  winterGrey: '#E5E1DC',
  white: '#FFFFFF',
};

export const BrandColor = EnovaThemeColors.mountainGreen;
export const BrandColorSecondary = '#ef3340';

export const EnergyGradeColors: EnergyGradePalette = {
  [EnergyGrade.A]: '#00A652',
  [EnergyGrade.B]: '#4FB849',
  [EnergyGrade.C]: '#C0D731',
  [EnergyGrade.D]: '#FEF200',
  [EnergyGrade.E]: '#FCB914',
  [EnergyGrade.F]: '#F37020',
  [EnergyGrade.G]: '#ED2324',
};

export const HeatingGradeColors: HeatingGradePalette = {
  [HeatingGrade.Green]: '#3F8E50',
  [HeatingGrade.Lightgreen]: '#8EBE56',
  [HeatingGrade.Yellow]: '#E5CA49',
  [HeatingGrade.Orange]: '#E67E3B',
  [HeatingGrade.Red]: '#B83633',
};

export const Extras: ExtraColorsPalette = {
  blue: '#00a3e0',
  green: '#00b74f',
  lightPurple: '#9b96c7',
  orange: '#ffa300',
  purple: '#382D8F',
  yellow: '#ffd100',
  ...EnovaThemeColors,
};

export const Greys = {
  grey1: EnovaThemeColors.white,
  grey2: EnovaThemeColors.winterGrey,
  grey3: EnovaThemeColors.rockGrey,
  grey4: EnovaThemeColors.black,
};

export const BrandError = '#AC1003';
export const BrandSuccess = '#00B74F';

const defaultMuiTheme = (mode: 'dark' | 'light') =>
  createTheme({ palette: { mode } });

// Any non-color related theme customizations specified here will be applied to both light and dark themes
const getBaseThemeOptions = (mode: 'dark' | 'light'): EnovaThemeOptions => {
  const defaultTheme = defaultMuiTheme(mode);

  return {
    breakpoints: { values: breakpoints },
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        variants: [
          {
            props: { size: 'small' },
            style: {
              fontSize: '0.925rem',
              lineHeight: 1.25,
              padding: '4px 8px',
            },
          },
          {
            props: { size: 'medium' },
            style: {
              fontSize: '1rem',
              lineHeight: 1.25,
              padding: '8px 16px',
            },
          },
          {
            props: { size: 'large' },
            style: {
              fontSize: '1.125rem',
              lineHeight: 1.5,
              padding: '8px 16px',
            },
          },
        ],
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&:hover': {
              background: defaultTheme.palette.action.hover,
            },
            '&:focus-visible': {
              boxShadow: `inset 0 0 0 0.15rem ${
                mode === 'dark'
                  ? defaultTheme.palette.common.white
                  : defaultTheme.palette.common.black
              }`,
              background: defaultTheme.palette.action.focus,
            },
            '&:active': {
              background: defaultTheme.palette.action.focus,
            },
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              background: defaultTheme.palette.action.selected,
              '&:hover': {
                background: defaultTheme.palette.action.hover,
              },
              '&:focus-visible': {
                background: defaultTheme.palette.action.focus,
              },
              '&:active': {
                background: defaultTheme.palette.action.focus,
              },
            },
          },
        },
      },
    },
    palette: {
      contrastThreshold: 4.5,
      extras: Extras,
      greys: Greys,
    },
    shape: { borderRadius: 5 },
    size: { inputHeight: 50, inputWidth: 300 },
    spacing: 5,
    typography: {
      fontFamily: ['"National"', 'sans-serif'].join(','),
      h1: {
        fontSize: '2.25rem',
        fontWeight: 600,
        lineHeight: 1.1,
        [`@media (min-width: ${breakpoints.lg}px)`]: {
          fontSize: '3rem',
        },
      },
      h2: {
        fontSize: '1.75rem',
        fontWeight: 600,
        lineHeight: 1.1,
        [`@media (min-width: ${breakpoints.lg}px)`]: {
          fontSize: '2.25rem',
        },
      },
      h3: {
        fontSize: '1.5rem',
        fontWeight: 600,
        lineHeight: 1.2,
        [`@media (min-width: ${breakpoints.lg}px)`]: {
          fontSize: '1.75rem',
        },
      },
      h4: {
        fontSize: '1.125rem',
        fontWeight: 600,
        lineHeight: 1.35,
        [`@media (min-width: ${breakpoints.lg}px)`]: {
          fontSize: '1.25rem',
        },
      },
      body1: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.5,
        [`@media (min-width: ${breakpoints.lg}px)`]: {
          fontSize: '1.125rem',
        },
      },
      body2: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.25,
      },
      button: {
        fontSize: '1.125rem',
        fontWeight: 300,
        lineHeight: 1.5,
      },
    },
  };
};

export default getBaseThemeOptions;

const LightThemeDefaultOptions: EnovaThemeOptions = merge(
  getBaseThemeOptions('light'),
  {
    palette: {
      action: { disabledBackground: alpha('#000', 0.08) },
      background: { default: '#F6F6F6' },
      divider: Greys.grey2,
      energyGrade: EnergyGradeColors,
      heatingGrade: HeatingGradeColors,
      error: { main: BrandError, background: alpha(BrandError, 0.09) },
      primary: { main: BrandColor },
      secondary: { main: BrandColorSecondary },
      success: { main: BrandSuccess, background: alpha(BrandSuccess, 0.1) },
      warning: {
        main: amber[500],
        background: alpha(amber[500], 0.1),
        dark: darken(amber[700], 0.2),
      },
    },
  }
);

const DarkThemeDefaultOptions: EnovaThemeOptions = merge(
  getBaseThemeOptions('dark'),
  {
    palette: {
      action: { disabledBackground: alpha('#fff', 0.08) },
      background: { default: Greys.grey4 },
      divider: Greys.grey2,
      energyGrade: EnergyGradeColors,
      heatingGrade: HeatingGradeColors,
      error: {
        background: alpha(lighten(BrandError, 0.4), 0.09),
        main: lighten(BrandError, 0.4),
      },
      primary: { main: BrandColor },
      secondary: { main: BrandColorSecondary },
      success: {
        background: alpha(lighten(BrandSuccess, 0.4), 0.1),
        main: lighten(BrandSuccess, 0.4),
      },
      warning: {
        main: lighten(amber[500], 0.4),
        background: alpha(lighten(amber[500], 0.4), 0.1),
        dark: darken(lighten(amber[700], 0.4), 0.2),
      },
    },
  }
);

export { LightThemeDefaultOptions, DarkThemeDefaultOptions };
