import React, { FC, forwardRef } from 'react';
import MuiDatePicker, {
  DatePickerProps as MuiDatePickerProps,
} from '@mui/lab/DatePicker';
import { format, isBefore, isAfter } from 'date-fns';

import { TextField } from './textField';
import { TextFieldBase } from './textFieldBase';
import { TextFieldProps } from './types';

export type DatePickerProps = Omit<TextFieldProps, 'autoComplete'> &
  Omit<
    MuiDatePickerProps,
    | 'acceptRegex'
    | 'allowSameDateSelection'
    | 'autoComplete'
    | 'cancelText'
    | 'clearable'
    | 'clearText'
    | 'components'
    | 'componentsProps'
    | 'defaultCalendarMonth'
    | 'defaultCalendarMonth'
    | 'desktopModeMediaQuery'
    | 'DialogProps'
    | 'disableCloseOnSelect'
    | 'disableHighlightToday'
    | 'disableMaskedInput'
    | 'disableOpenPicker'
    | 'element'
    | 'getOpenDialogAriaText'
    | 'getViewSwitchingButtonText'
    | 'ignoreInvalidInputs'
    | 'InputAdornmentProps'
    | 'InputProps'
    | 'inputRef'
    | 'leftArrowButtonText'
    | 'loading'
    | 'okText'
    | 'onAccept'
    | 'onClose'
    | 'onMonthChange'
    | 'onOpen'
    | 'onViewChange'
    | 'onYearChange'
    | 'open'
    | 'OpenPickerButtonProps'
    | 'openTo'
    | 'orientation'
    | 'PopperProps'
    | 'readOnly'
    | 'reduceAnimations'
    | 'renderDay'
    | 'renderInput'
    | 'renderLoading'
    | 'rifmFormatter'
    | 'rightArrowButtonText'
    | 'shouldDisableDate'
    | 'shouldDisableYear'
    | 'showDaysOutsideCurrentMonth'
    | 'showTodayButton'
    | 'showToolbar'
    | 'todayText'
    | 'ToolbarComponent'
    | 'toolbarFormat'
    | 'toolbarPlaceholder'
    | 'toolbarTitle'
    | 'TransitionComponent'
    | 'type'
    | 'views'
  >;

const formatDate = (dateString?: string | number | Date) => {
  if (!dateString) return undefined;
  const date = new Date(dateString);
  return format(date, 'yyyy-MM-dd');
};

export const DatePicker: FC<DatePickerProps> = forwardRef(
  (
    {
      error,
      HelperBoxProps,
      helperText,
      highVizFocus,
      inputFormat = 'dd.MM.yyyy',
      marginBottom,
      marginTop,
      marginY,
      mask = '__.__.____',
      value,
      required,
      ...rest
    },
    ref
  ) => {
    let textFieldProps: Partial<TextFieldProps> = {
      error,
      HelperBoxProps,
      helperText,
      highVizFocus,
      label: rest.label,
      marginBottom,
      marginTop,
      marginY,
    };

    if (
      [
        'iPad Simulator',
        'iPad',
        'iPhone Simulator',
        'iPhone',
        'iPod Simulator',
        'iPod',
      ].includes(navigator.platform)
    ) {
      const getMaxDate = () => {
        const dates = [
          ...(rest.disableFuture ? [new Date().getTime()] : []),
          ...(rest.maxDate ? [new Date(rest.maxDate as string).getTime()] : []),
        ];

        return dates.length > 0
          ? formatDate(Math.min.apply(null, dates))
          : undefined;
      };

      const getMinDate = () => {
        const dates = [
          ...(rest.disablePast ? [new Date().getTime()] : []),
          ...(rest.minDate ? [new Date(rest.minDate as string).getTime()] : []),
        ];

        return dates.length > 0
          ? formatDate(Math.max.apply(null, dates))
          : undefined;
      };

      const inputProps = {
        max: getMaxDate(),
        min: getMinDate(),
      };

      const val = formatDate(value as string | Date) || '';

      const invalidDate = !!(
        (val &&
          inputProps.min &&
          isBefore(new Date(val), new Date(inputProps.min as string | Date))) ||
        (val &&
          inputProps.max &&
          isAfter(new Date(val), new Date(inputProps.max as string | Date)))
      );

      const changeHandler: React.FormEventHandler<HTMLInputElement> &
        React.ChangeEventHandler<HTMLInputElement> = (
        e:
          | React.ChangeEvent<HTMLInputElement>
          | React.FormEvent<HTMLInputElement>
      ) => {
        const newValue = e.currentTarget.value as string;
        rest.onChange(newValue ? new Date(newValue as string | Date) : null);
      };

      textFieldProps = {
        ...textFieldProps,
        error: error ?? invalidDate,
        inputProps,
        onChange: changeHandler,
        value: val,
      };

      return <TextField {...textFieldProps} ref={ref} type="date" />;
    }

    return (
      <MuiDatePicker
        {...rest}
        inputFormat={inputFormat}
        mask={mask}
        ref={ref}
        renderInput={(params) => (
          <TextFieldBase
            required={required}
            {...textFieldProps}
            {...(params as TextFieldProps)}
            {...(error ? { error: true } : {})}
          />
        )}
        value={value || null}
      />
    );
  }
);

DatePicker.displayName = 'DatePicker';
