import React, { FC } from 'react';
import MuiDialogTitle, {
  DialogTitleProps as MuiDialogTitleProps,
} from '@mui/material/DialogTitle';

export type DialogTitleProps = Omit<
  MuiDialogTitleProps,
  'classes' | 'disableTypography'
>;

export const DialogTitleId = 'dialog-title';

/**
 * Implements [DialogTitle](https://mui.com/api/dialog-title/) from **Material-UI**.
 */
export const DialogTitle: FC<DialogTitleProps> = ({
  id = DialogTitleId,
  ...rest
}) => <MuiDialogTitle {...rest} id={id} />;
