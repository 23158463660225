import React, { ReactNode, FC } from 'react';

export type TabValue = string | number;

export type TabProps = {
  /**
   * Content of the tab panel
   */
  children: ReactNode;
  /**
   * If `true`, the default padding of tab panels will be omitted
   */
  dense?: boolean;
  /**
   * Custom tab identifier. If missing, tab index will be used instead.
   */
  identifier?: TabValue;
  /**
   * Tab title
   */
  tabTitle: ReactNode;
};

export const Tab: FC<TabProps> = (props) => <div {...props} />;

Tab.displayName = 'Tab';

const tabPanelId = (identifier: TabValue) => `nav-tabpanel-${identifier}`;

const tabId = (identifier: TabValue) => `nav-tab-${identifier}`;

export const a11yPropsTab = (identifier: TabValue) => ({
  'aria-controls': tabPanelId(identifier),
  id: tabId(identifier),
});

export const a11yPropsTabPanel = (identifier: TabValue) => ({
  'aria-labelledby': tabId(identifier),
  id: tabPanelId(identifier),
});
