import React from 'react';
import { alpha } from '@mui/material/styles';
import MuiToolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

import { Typography } from '../typography';

import { TableToolbarProps, TTableData } from './utils';

export function TableToolbar<T extends TTableData>({
  actions,
  labelSelectedItems,
  numSelected,
  selectedItemsActions,
  tableTitle,
  titleId,
}: TableToolbarProps<T>) {
  return (
    <MuiToolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography color="inherit" variant="subtitle1" component="div">
          {labelSelectedItems
            ? labelSelectedItems(numSelected)
            : `${numSelected} selected`}
        </Typography>
      ) : tableTitle ? (
        <Typography variant="h6" id={titleId} component="div">
          {tableTitle}
        </Typography>
      ) : null}

      <Box ml="auto">{numSelected > 0 ? selectedItemsActions : actions}</Box>
    </MuiToolbar>
  );
}
