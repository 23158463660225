import React, { FC, ReactElement, ReactFragment } from 'react';
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Breakpoint, styled } from '@mui/material/styles';
import classNames from 'classnames';

import { FormProps } from '../../input/form';
import useEnovaContext from '../../../hooks/enovaContext';

import { DialogContent, DialogContentProps } from './dialogContent';
import { DialogActions, DialogActionsProps } from './dialogActions';
import { DialogTitle, DialogTitleId, DialogTitleProps } from './dialogTitle';

export type DialogSize = undefined | 'xs' | 'sm' | 'md' | 'lg';

type DialogChild =
  | ReactFragment
  | ReactElement<
      FormProps | DialogTitleProps | DialogContentProps | DialogActionsProps
    >;

export type DialogBreakpoint = Exclude<Breakpoint, 'xs'>;

export type DialogProps = Omit<
  MuiDialogProps,
  | 'BackdropComponent'
  | 'BackdropProps'
  | 'children'
  | 'classes'
  | 'closeAfterTransition'
  | 'components'
  | 'componentsProps'
  | 'container'
  | 'disableAutoFocus'
  | 'disableBackdropClick'
  | 'disableEnforceFocus'
  | 'disableEscapeKeyDown'
  | 'disablePortal'
  | 'disableRestoreFocus'
  | 'disableScrollLock'
  | 'fullScreen'
  | 'fullWidth'
  | 'hideBackdrop'
  | 'keepMounted'
  | 'manager'
  | 'maxWidth'
  | 'onBackdropClick'
  | 'onEnter'
  | 'onEntered'
  | 'onEntering'
  | 'onEscapeKeyDown'
  | 'onExit'
  | 'onExited'
  | 'onExiting'
  | 'onRendered'
  | 'PaperComponent'
  | 'PaperProps'
  | 'scroll'
  | 'TransitionComponent'
  | 'transitionDuration'
  | 'TransitionProps'
> & {
  /**
   * Normally `Dialog` sub-components (directly or wrapped in a `<Form />` or `ReactFragment`)
   */
  children: DialogChild | DialogChild[];
  /**
   * If `true`, the `Dialog` is fullscreen. If a breakpoint is given, it will be fullscreen up until the given breakpoint.
   */
  fullScreen?: boolean | DialogBreakpoint;
  /**
   * Replaces **Material-UI** props `maxWidth` and `fullWidth`.
   */
  size?: DialogSize;
};

type DialogSubTypes = {
  /**
   * The action area is intended for one or more `Button` components. The area is padded, the content floats to the right, and the elements are spaced automatically.
   */
  Actions: typeof DialogActions;
  /**
   * A simple wrapper that provides consistent spacing around the main content of all dialogs. If the `children` prop is of type `string`, it will be wrapped with a `p` tag with no additional spacing, otherwise it will be rendered as-is. Simple textual content consisting of a single paragraph should be passed directly.
   */
  Content: typeof DialogContent;
  /**
   * All dialogs should have a title placed within a `Dialog.Title` tag.
   */
  Title: typeof DialogTitle;
};

const StyledDialog = styled(MuiDialog)`
  &.fs .paper {
    display: inline-flex;
    flex-direction: column;

    .dialog-content {
      flex: 1;
    }
  }
` as typeof MuiDialog;

/**
 * Implements [Dialog](https://mui.com/components/dialogs/) from **Material-UI**.
 */
export const Dialog: FC<DialogProps> & DialogSubTypes = ({
  'aria-labelledby': ariaLabelledby = DialogTitleId,
  children,
  className,
  fullScreen,
  size = 'sm',
  ...rest
}) => {
  const { theme } = useEnovaContext();

  const fs = useMediaQuery(
    theme.breakpoints.down(
      typeof fullScreen === 'string' ? fullScreen : fullScreen ? 'xl' : 0
    )
  );

  return (
    <StyledDialog
      {...rest}
      aria-labelledby={ariaLabelledby}
      classes={{ paper: 'paper' }}
      className={classNames(className, { fs })}
      fullScreen={fs}
      fullWidth
      maxWidth={size}
      scroll="body"
    >
      {children}
    </StyledDialog>
  );
};

Dialog.Actions = DialogActions;
Dialog.Content = DialogContent;
Dialog.Title = DialogTitle;

export { DialogActions, DialogContent, DialogTitle };
