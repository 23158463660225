import React, { FC, Fragment, ReactNode, useState } from 'react';
import MuiBadge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import classNames from 'classnames';
import Popover from '@mui/material/Popover';

import { Typography } from '../dataDisplay/typography';
import styled from '../../utils/styled';
import { IconButton, IconButtonProps } from '../buttons/iconButton';
import { BadgeProps } from '../dataDisplay/badge';
import { focusSingleThickBoxShadow } from '../../hooks/enovaContext/globalStyles';

export type HelperBoxProps = Omit<BadgeProps, 'classes' | 'role'> & {
  'aria-label': string;
  /**
   * Message content
   */
  children: ReactNode;
  CloseButtonProps?: Omit<IconButtonProps, 'onClick'>;
};

const ContentWrapper = styled.div`
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  font-weight: ${({ theme }) => theme.typography.body1.fontWeight};
`;

const StyledBadge = styled(MuiBadge)`
  padding: 0;
  border: 1px solid ${({ theme }) => theme.palette.extras.black};
  border-radius: 50%;
  background: none;

  .badge {
    color: ${({ theme }) =>
      theme.palette.getContrastText(theme.palette.background.paper)};
    background: ${({ theme }) => theme.palette.background.paper};
    padding-bottom: 3px;
    position: initial;
    top: initial;
    cursor: pointer;
    right: initial;
    transform: initial;
    transform-origin: initial;
  }

  &:hover,
  &:active,
  &:focus-visible {
    .badge {
      background: ${({ theme }) => theme.palette.extras.lightOcean};
      color: ${({ theme }) =>
        theme.palette.getContrastText(theme.palette.extras.lightOcean)};
    }
  }

  &:focus-visible {
    outline: none;

    .badge {
      ${focusSingleThickBoxShadow}
    }
  }
` as typeof MuiBadge;

export const HelperBox: FC<HelperBoxProps> = ({
  'aria-label': ariaLabel = 'information',
  children,
  CloseButtonProps: {
    'aria-label': closeButtonAriaLabel = 'close',
    size: closeButtonSize = 'small',
    icon: closeButtonIcon = 'close',
    className: closeButtonClassName,
    ...CloseButtonProps
  } = {},
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onClose = () => {
    anchorEl?.focus();
    setAnchorEl(null);
  };

  const toggle = (target: HTMLElement) =>
    setAnchorEl((prev) => (!!prev ? null : target));

  return (
    <Fragment>
      <StyledBadge
        {...rest}
        {...{ component: 'button', type: 'button', 'aria-label': ariaLabel }}
        badgeContent="?"
        classes={{ badge: 'badge' }}
        onClick={(e) => {
          e.preventDefault();
          toggle(e.currentTarget);
        }}
        title={rest.title ?? ariaLabel}
      />

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={onClose}
        open={!!anchorEl}
      >
        <Box
          alignItems="flex-start"
          display="flex"
          justifyContent="space-between"
          marginTop={1}
          maxWidth={600}
          minWidth={260}
          paddingX={4}
          paddingY={3}
        >
          <Box className="f-fill" marginTop={-0.5}>
            {typeof children === 'string' ? (
              <Typography className="text-preline">{children}</Typography>
            ) : (
              <ContentWrapper>{children}</ContentWrapper>
            )}
          </Box>

          <IconButton
            {...CloseButtonProps}
            aria-label={closeButtonAriaLabel}
            className={classNames('ml-2', closeButtonClassName)}
            icon={closeButtonIcon}
            onClick={onClose}
            size={closeButtonSize}
          />
        </Box>
      </Popover>
    </Fragment>
  );
};

HelperBox.displayName = 'HelperBox';
