import React, { FC } from 'react';
import classNames from 'classnames';
import MuiDialogContent, {
  DialogContentProps as MuiDialogContentProps,
} from '@mui/material/DialogContent';

import { Typography } from '../../dataDisplay/typography';

export type DialogContentProps = Omit<
  MuiDialogContentProps,
  'classes' | 'dividers'
>;

/**
 * Implements [DialogContent](https://mui.com/api/dialog-content/) from **Material-UI**.
 */
export const DialogContent: FC<DialogContentProps> = ({
  children,
  className,
  ...rest
}) => (
  <MuiDialogContent
    className={classNames(
      'dialog-content',
      'pb-4',
      'pt-0',
      'o-visible',
      className
    )}
    {...rest}
  >
    {typeof children === 'string' ? (
      <Typography>{children}</Typography>
    ) : (
      children
    )}
  </MuiDialogContent>
);
