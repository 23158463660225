import React, { FC } from 'react';
import MuiBadge, { BadgeProps as MuiBadgeProps } from '@mui/material/Badge';
import classNames from 'classnames';

import styled from '../../utils/styled';

export type BadgeProps = Omit<
  MuiBadgeProps,
  | 'anchorOrigin'
  | 'overlap'
  | 'color'
  | 'invisible'
  | 'max'
  | 'showZero'
  | 'variant'
> & {
  inline?: boolean;
};

const StyledBadge = styled(MuiBadge)`
  .badge {
    color: ${({ theme }) =>
      theme.palette.getContrastText(theme.palette.extras.blue)};
    background: ${({ theme }) => theme.palette.extras.blue};
    padding-bottom: 3px;
  }

  &.inline .badge {
    position: initial;
    top: initial;
    right: initial;
    transform: initial;
    transform-origin: initial;
  }
` as typeof MuiBadge;

export const Badge: FC<BadgeProps> = ({ className, inline, ...rest }) => (
  <StyledBadge
    {...rest}
    classes={{ badge: 'badge', root: 'badge--root' }}
    className={classNames({ inline }, className)}
  />
);

Badge.displayName = 'Badge';
