import React, { FC, useMemo } from 'react';
import { nanoid } from 'nanoid';

import { EnovaTheme } from '../../../../theme/types';
import styled from '../../../../utils/styled';
import { Typography } from '../../typography';
import {
  EnergyGrade,
  EnergyGrades,
  HeatingGrade,
  HeatingGrades,
} from '../utils';

import {
  EnergyRatingIndicator,
  EnergyRatingIndicatorBaseProps,
  roofSize,
} from './energyRatingIndicator';
import { getReverseHGIndex } from './utils';

export type EnergyRating2DProps = {
  /**
   * Label of the energy grade axis
   */
  energyAxisLabel: string;
  /**
   * Energy grade value
   */
  energyGrade: EnergyGrade;
  /**
   * Label of the heating grade axis
   */
  heatingAxisLabel: string;
  /**
   * Heating grade value
   */
  heatingGrade: HeatingGrade;
  /**
   * Alternative description for screen readers
   */
  screenReaderCaption: string;
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns:
    min-content min-content minmax(30px, 100%) minmax(30px, 100%)
    minmax(30px, 100%) minmax(30px, 100%) minmax(30px, 100%);

  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-template-columns: min-content min-content 60px 60px 60px 60px 60px;
  }
`;

type EnergyLevel2DProps = {
  theme: EnovaTheme;
  grade: EnergyGrade;
};

const eneryGradeBarHeight = 30; // px
const energyGradeArrowWidth = 8; // px

const EnergyLevelBar = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.palette.greys.grey3};
  color: ${({ theme }) =>
    theme.palette.getContrastText(theme.palette.greys.grey3)};
  display: flex;
  grid-column: 2;
  height: ${eneryGradeBarHeight}px;
  margin: ${({ theme }: EnergyLevel2DProps) =>
    theme.spacing(0.5, '2ch', 0.5, 0)};
  padding: ${({ theme }) => theme.spacing(0.25, 0, 0.25, 2)};
  position: relative;
  text-transform: uppercase;
  width: ${({ grade }) => 3 + EnergyGrades.indexOf(grade) * 0.35}ch;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: ${({ grade }) => 3 + EnergyGrades.indexOf(grade) * 0.6}ch;
    margin-right: ${eneryGradeBarHeight / 2}px;
  }

  &:after {
    content: '';
    height: 0;
    position: absolute;
    right: ${energyGradeArrowWidth * -1}px;
    top: 0;
    width: 0;
    border-top: ${eneryGradeBarHeight / 2}px solid transparent;
    border-bottom: ${eneryGradeBarHeight / 2}px solid transparent;
    border-left: ${({ theme }) =>
      `${energyGradeArrowWidth}px solid ${theme.palette.greys.grey3}`};
  }
`;

type HeatingGradeIndicatorProps = {
  heatinggrade: HeatingGrade;
  theme: EnovaTheme;
};

const HeatingGradeIndicator = styled.div`
  background: ${({ theme, heatinggrade }: HeatingGradeIndicatorProps) =>
    theme.palette.heatingGrade[heatinggrade]};
  grid-column: ${({ heatinggrade }) => getReverseHGIndex(heatinggrade) + 3};
  grid-row: ${EnergyGrades.length + 2};
  height: 25px;
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

const houseWidthXs = 30;

const StyledEnergyRatingIndicator = styled(EnergyRatingIndicator)`
  grid-row: ${({ energyGrade }: EnergyRatingIndicatorBaseProps) =>
    EnergyGrades.indexOf(energyGrade) + 1};
  grid-column: ${({ heatingGrade }) => getReverseHGIndex(heatingGrade) + 3};
  margin: ${({ theme }) => theme.spacing(1, 'auto', 0)};

  ${({ theme }) => theme.breakpoints.only('xs')} {
    width: ${houseWidthXs}px;
    height: ${houseWidthXs}px;

    &::after {
      border-left-width: ${houseWidthXs / 2}px;
      border-right-width: ${houseWidthXs / 2}px;
      top: ${roofSize * -1}px;
    }
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin: ${({ theme }) => theme.spacing(0.5, 'auto', -0.5)};
  }
`;

const AxisLabel = styled((props) => (
  <Typography {...props} variant="h4" component="span" />
))`
  align-self: center;
  justify-self: center;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: ${({ theme }) => theme.typography.h3.fontSize};
  }
` as typeof Typography;

const EnergyAxisLabel = styled(AxisLabel)`
  grid-row: 1 / span ${EnergyGrades.length};
  max-height: fit-content;
  padding-left: ${({ theme }) => theme.spacing(1)};
  transform: rotate(180deg);
  writing-mode: vertical-rl;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-left: ${({ theme }) => theme.spacing(4)};
  }
`;

const HeatingAxisLabel = styled(AxisLabel)`
  grid-column: 3 / span ${HeatingGrades.length};
  grid-row: ${EnergyGrades.length + 3};
  max-width: fit-content;
  padding-top: ${({ theme }) => theme.spacing(2)};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-left: ${({ theme }) => theme.spacing(3)};
  }
`;

export const EnergyRating2D: FC<EnergyRating2DProps> = ({
  energyGrade,
  heatingGrade,
  energyAxisLabel,
  heatingAxisLabel,
  screenReaderCaption,
  ...rest
}) => {
  const srCaptionId = useMemo(() => nanoid(), []);

  return (
    <section aria-describedby={srCaptionId}>
      <Typography id={srCaptionId} variant="srOnly">
        {screenReaderCaption}
      </Typography>

      <Wrapper aria-hidden {...rest}>
        <EnergyAxisLabel>{energyAxisLabel}</EnergyAxisLabel>

        {EnergyGrades.map((level) => (
          <EnergyLevelBar key={level} grade={level}>
            <Typography variant="h4" component="span">
              {level}
            </Typography>
          </EnergyLevelBar>
        ))}

        {energyGrade && heatingGrade && (
          <StyledEnergyRatingIndicator
            heatingGrade={heatingGrade}
            energyGrade={energyGrade}
          />
        )}

        {HeatingGrades.map((hg) => (
          <HeatingGradeIndicator key={hg} heatinggrade={hg} />
        ))}

        <HeatingAxisLabel>{heatingAxisLabel}</HeatingAxisLabel>
      </Wrapper>
    </section>
  );
};

EnergyRating2D.displayName = 'EnergyRating2D';
