import React, { FC, forwardRef } from 'react';

import { TextFieldBase } from './textFieldBase';
import { TextFieldProps } from './types';

export const TextField: FC<TextFieldProps> = forwardRef((props, ref) => (
  <TextFieldBase {...props} ref={ref} />
));

TextField.displayName = 'TextField';
