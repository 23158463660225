import React, { ElementType, FC, forwardRef, MouseEventHandler } from 'react';
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import classNames from 'classnames';

import { TypographyVariants } from '../dataDisplay/typography';
import styled from '../../utils/styled';
import { focusVisibleBoxShadowRounded } from '../../hooks/enovaContext/globalStyles';

export const LinkVariants = [...TypographyVariants, 'inherit'] as const;
export type LinkVariant = typeof LinkVariants[number];

export type LinkProps = Omit<
  MuiLinkProps,
  | 'classes'
  | 'color'
  | 'gutterBottom'
  | 'noWrap'
  | 'paragraph'
  | 'onClick'
  | 'TypographyClasses'
  | 'underline'
  | 'variantMapping'
> & {
  /**
   * Renders the link as a HTML button tag instead (but only if the link does not have an `href`).
   */
  button?: boolean;
  /**
   * Text color.
   */
  color?: 'error' | 'inherit' | 'initial' | 'textPrimary' | 'textSecondary';
  component?: ElementType;
  /**
   * Opens link address in a new tab if `true`.
   */
  targetBlank?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  /**
   * Link will receive the styling of the variant.
   */
  variant?: LinkVariant;
  transparentBackground?: boolean;
};

const StyledLink = styled(({ transparentBackground, ...rest }: LinkProps) => (
  <MuiLink {...rest} />
))`
  ${focusVisibleBoxShadowRounded};
  text-underline-position: under;
  vertical-align: baseline;
  text-decoration-color: initial;
  background-color: ${(props) =>
    props.transparentBackground
      ? 'transparent'
      : props.theme.palette.extras.lightGreen};

  .EfcIcon {
    font-size: inherit;
    vertical-align: middle;
  }

  .EfcLargeIcon {
    font-size: 150%;
    vertical-align: middle;
    margin: ${({ theme }) => theme.spacing(0, 2)};
  }
` as typeof MuiLink;

/**
 * #### Security
 *
 * When you use `target="_blank"` with Links, it is [recommended](https://developers.google.com/web/tools/lighthouse/audits/noopener) to always set `rel="noopener"` or `rel="noreferrer"` when linking to third party content.
 *
 * - `rel="noopener"` prevents the new page from being able to access the `window.opener` property and ensures it runs in a separate process. Without this, the target page can potentially redirect your page to a malicious URL.
 * - `rel="noreferrer"` has the same effect, but also prevents the _Referer_ header from being sent to the new page. ⚠️ Removing the referrer header will affect analytics.
 *
 * #### Accessibility
 *
 * (WAI-ARIA: https://www.w3.org/TR/wai-aria-practices/#link)
 *
 * - When providing the content for the link, avoid generic descriptions like "click here" or "go to". Instead, use [specific descriptions](https://developers.google.com/web/tools/lighthouse/audits/descriptive-link-text).
 * - For the best user experience, links should stand out from the text on the page.
 * - If a link doesn't have a meaningful href, [it should be rendered using a `<button>` element](https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md).
 */
export const Link: FC<LinkProps> = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      button,
      className,
      color = 'primary',
      component,
      targetBlank,
      variant = 'body1',
      ...rest
    },
    ref
  ) => (
    <StyledLink
      {...rest}
      {...{ component: component || (!rest.href && button) ? 'button' : 'a' }}
      className={classNames('EfcLink', className)}
      color={color}
      ref={ref}
      target={targetBlank ? '_blank' : undefined}
      underline="always"
      variant={variant}
    />
  )
);

Link.displayName = 'Link';
