import React, { FC, HTMLAttributes } from 'react';

import { EnovaTheme } from '../../../../theme/types';
import styled from '../../../../utils/styled';
import { Typography } from '../../typography';
import { EnergyGrade, EnergyGrades, HeatingGrade } from '../utils';

import { getReverseHGIndex } from './utils';

export type EnergyRatingIndicatorBaseProps = {
  heatingGrade: HeatingGrade;
  energyGrade: EnergyGrade;
};

export type EnergyRatingIndicatorProps = HTMLAttributes<HTMLDivElement> &
  Partial<EnergyRatingIndicatorBaseProps>;

type StyledEnergyRatingIndicatorProps = EnergyRatingIndicatorProps & {
  theme: EnovaTheme;
};

export const roofSize = 12;
const houseWidth = 40;
const houseHeight = 35;

const Wrapper = styled.div`
  --bgColor: ${({ theme, heatingGrade }: StyledEnergyRatingIndicatorProps) =>
    heatingGrade
      ? theme.palette.heatingGrade[heatingGrade]
      : theme.palette.greys.grey2};

  align-items: center;
  background: var(--bgColor);
  color: ${({ theme }) =>
    theme.palette.getContrastText(theme.palette.greys.grey2)};
  display: flex;
  height: ${houseHeight}px;
  justify-content: center;
  margin-top: ${roofSize}px;
  position: relative;
  width: ${houseWidth}px;
  overflow-x: clip;

  ${({ theme, heatingGrade }): string | undefined => {
    if (heatingGrade)
      return `
  color: ${theme.palette.getContrastText(
    theme.palette.heatingGrade[heatingGrade]
  )};
  grid-column: ${getReverseHGIndex(heatingGrade) + 3};
  `;
    return undefined;
  }}

  ${({ energyGrade }): string | undefined => {
    if (energyGrade)
      return `
  grid-row: ${EnergyGrades.indexOf(energyGrade) + 1};
  `;
    return undefined;
  }}


  &::after {
    content: '';
    height: 0;
    position: absolute;
    top: ${(roofSize - 1) * -1}px;
    bottom: 100%;
    width: 0;
    border-bottom: ${roofSize}px solid var(--bgColor);
    border-left: ${houseWidth / 2 + 2}px solid transparent;
    border-right: ${houseWidth / 2 + 2}px solid transparent;
  }
`;

export const EnergyRatingIndicator: FC<EnergyRatingIndicatorProps> = ({
  energyGrade,
  heatingGrade,
  ...rest
}) => (
  <Wrapper heatingGrade={heatingGrade} energyGrade={energyGrade} {...rest}>
    {energyGrade && (
      <Typography color="inherit" variant="h3" component="span">
        {energyGrade?.toUpperCase()}
      </Typography>
    )}
  </Wrapper>
);

EnergyRatingIndicator.displayName = 'EnergyRatingIndicator';
