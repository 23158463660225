import React, { FC, ReactNode } from 'react';
import Modal, { ModalProps } from '@mui/material/Modal';
import Box from '@mui/material/Box';

import { Loader } from './loader';

export type FullScreenLoaderProps = Pick<ModalProps, 'onClose' | 'open'> & {
  children?: ReactNode;
};

export const FullScreenLoader: FC<FullScreenLoaderProps> = ({
  children,
  ...rest
}) => (
  <Modal
    {...rest}
    sx={{
      color: (theme) => theme.palette.common.white,
      zIndex: (theme) => theme.zIndex.drawer + 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      gap={4}
      style={{ outline: 'none' }}
      tabIndex={-1}
    >
      <Loader color="inherit" size="large" />
      {children && <Box>{children}</Box>}
    </Box>
  </Modal>
);

FullScreenLoader.displayName = 'FullScreenLoader';
