import React, { ChangeEvent, HTMLAttributes } from 'react';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import MuiTabs, { TabsProps as MuiTabsProps } from '@mui/material/Tabs';
import classNames from 'classnames';

import { EnovaTheme } from '../../../theme/types';

import { TabValue } from './utils';

type TabsBaseProps = Omit<
  HTMLAttributes<HTMLButtonElement>,
  'children' | 'onChange'
> &
  Omit<MuiTabsProps, 'value' | 'onChange'> & {
    value: TabValue;
    onChange: (event: ChangeEvent<unknown>, newValue: TabValue) => void;
    hideDivider?: boolean;
  };

export const TabsBase = withStyles((theme: EnovaTheme) =>
  createStyles({
    root: {
      position: 'relative',
      marginBottom: 0,
      '&.divider::after': {
        content: '""',
        position: 'absolute',
        height: 1,
        background: theme.palette.divider,
        left: theme.spacing(2.4),
        right: theme.spacing(2.4),
        bottom: 0,
      },
      '& .scroll-button': {
        background: theme.palette.background.default,
        border: theme.shape.borderRadius,
        bottom: 0,
        opacity: 1,
        position: 'absolute',
        top: 0,
        zIndex: 100,
        '&::before': {
          background: theme.palette.background.default,
          borderRadius: theme.shape.borderRadius,
          bottom: 0,
          content: '""',
          display: 'block',
          left: 0,
          position: 'absolute',
          right: 0,
          top: 0,
        },
        '& svg': {
          zIndex: 100,
        },
        '&:first-child': {
          left: 0,
        },
        '&:last-child': {
          right: 0,
        },
        '&:hover:not(:active)': {
          opacity: 1,
          '&::before': {
            background: theme.palette.action.hover,
          },
        },
        '&:active': {
          opacity: 1,
          '&::before': {
            background: theme.palette.action.focus,
          },
        },
      },
      '& .scroll-button--hidden': {
        opacity: 0,
        overflow: 'hidden',
        width: 0,
      },
    },
    indicator: {
      backgroundColor: 'transparent',
      bottom: 8,
      display: 'flex',
      height: 4,
      justifyContent: 'center',
      '& > span': {
        background: theme.palette.extras.fjordGreen,
        borderRadius: 2,
        height: '100%',
        transform: 'rotate(180deg)',
        width: 'calc(100% - 24px)',
      },
    },
  })
)(({ className, hideDivider, ...rest }: TabsBaseProps) => (
  <MuiTabs
    {...rest}
    textColor="inherit"
    className={classNames({ divider: !hideDivider }, className)}
    TabIndicatorProps={{ children: <span /> }}
  />
));
