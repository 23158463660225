import React, { FC } from 'react';
import { FormControlLabelProps as MuiFormControlLabelProps } from '@mui/material/FormControlLabel';
import classNames from 'classnames';

import styled from '../../../utils/styled';
import { Typography } from '../../dataDisplay/typography';
import { defaultWidth, getWidthClasses, WidthProps } from '../utils';

import { StyledFormControlLabel } from './styles';

export type FormControlLabelProps = MuiFormControlLabelProps &
  WidthProps & {
    focus: boolean;
    error?: boolean;
  };

const StyledTypography = styled(Typography)`
  line-height: normal;
` as typeof Typography;

/**
 * Common wrapper component form `Checkboxes` and `Radios`
 */
export const FormControlLabel: FC<FormControlLabelProps> = ({
  className,
  error,
  focus,
  label,
  width = defaultWidth,
  ...rest
}) => (
  <StyledFormControlLabel
    {...rest}
    className={classNames(
      getWidthClasses({ width }),
      { error, focus },
      className
    )}
    classes={{
      root: 'selection-control',
      disabled: 'disabled',
      label: 'label',
    }}
    label={
      typeof label === 'string' ? (
        <StyledTypography variant="body2">{label}</StyledTypography>
      ) : (
        label
      )
    }
  />
);

FormControlLabel.displayName = 'FormControlLabel';
