import React, { FC, HTMLAttributes, ReactNode, useMemo, useState } from 'react';
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails, {
  AccordionDetailsProps,
} from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { nanoid } from 'nanoid';

import { Icon } from '../dataDisplay/icon';
import styled from '../../utils/styled';
import { fastTransition } from '../../hooks/enovaContext/globalStyles';

import { FactBoxBaseProps, FactBoxTitle } from './factBox';

const StyledAccordion = withStyles((theme: Theme) => ({
  root: {
    background: 'transparent',
    boxShadow: 'none',
    borderRadius: theme.shape.borderRadius,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&.expanded': {
      margin: 'auto',
    },
  },
}))(MuiAccordion);

const AccordionSummary = withStyles((theme: Theme) => ({
  root: {
    background: 'transparent',
    border: 0,
    borderRadius: theme.shape.borderRadius,
    marginBottom: -1,
    padding: theme.spacing(4),
    '&:hover': {
      background: theme.palette.primary.dark,
    },
    '&:focus-visible': {
      zIndex: 10,
      background: theme.palette.primary.dark,
      borderBottomLeftRadius: `${theme.shape.borderRadius}px !important`,
      borderBottomRightRadius: `${theme.shape.borderRadius}px !important`,
      boxShadow: `0 0 0 0.1rem ${
        theme.palette.mode === 'dark'
          ? theme.palette.common.black
          : theme.palette.common.white
      }, 0 0 0 0.25rem ${
        theme.palette.mode === 'dark'
          ? theme.palette.common.white
          : theme.palette.common.black
      }`,
    },
    '&$expanded': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      margin: 0,
    },
  },
  content: {
    alignItems: 'center',
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionIcon = styled(Icon)`
  ${fastTransition()}

  &.rotate {
    transform: rotate(-180deg);
  }
`;

const AccordionDetails = withStyles((theme: Theme) => ({
  root: {
    background: theme.palette.background.paper,
    border: `thin solid ${theme.palette.divider}`,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    borderTop: 'none',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: theme.spacing(5, 4),
    position: 'relative',
    '&.error': { borderColor: theme.palette.error.main },
    '&.dense': { padding: 0 },
  },
}))(MuiAccordionDetails);

export type CollapsibleFactBoxProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'onChange' | 'title'
> &
  FactBoxBaseProps & {
    /**
     * The accordion item content
     */
    children: ReactNode;
    /**
     *  If `true`, the content box will not have padding
     */
    dense?: boolean;
    /**
     * Props applied to the expandable area
     */
    ExpandableAreaProps?: AccordionDetailsProps;
    /**
     * Props applied to the header
     */
    HeaderProps?: Omit<AccordionSummaryProps, 'aria-controls' | 'id'>;
    /**
     * If `true`, the item is initially expanded.
     */
    initExpanded?: boolean;
  };

type CollapsibleFactBoxSubTypes = {
  Title: typeof FactBoxTitle;
};

export const CollapsibleFactBox: FC<CollapsibleFactBoxProps> &
  CollapsibleFactBoxSubTypes = ({
  children,
  dense,
  error,
  ExpandableAreaProps,
  HeaderProps,
  initExpanded,
  title,
  TitleProps,
  ...rest
}) => {
  const [expanded, setExpanded] = useState(!!initExpanded);
  const id = useMemo(() => nanoid(), []);

  return (
    <StyledAccordion
      className={classNames({ expanded })}
      expanded={expanded}
      onChange={() => setExpanded((prev) => !prev)}
      square
      {...rest}
    >
      <AccordionSummary
        {...HeaderProps}
        aria-controls={`panel-content-${id}`}
        className={classNames('bg-primary', HeaderProps?.className)}
        id={`panel-header-${id}`}
      >
        {typeof title === 'string' ? (
          <FactBoxTitle {...TitleProps}>{title}</FactBoxTitle>
        ) : (
          title
        )}

        <AccordionIcon
          className={classNames('ml-auto', { rotate: expanded })}
          name="chevron_down"
        />
      </AccordionSummary>

      <AccordionDetails
        {...ExpandableAreaProps}
        className={classNames(ExpandableAreaProps?.className, { dense, error })}
      >
        {typeof children === 'string' ? (
          <Typography>{children}</Typography>
        ) : (
          children
        )}
      </AccordionDetails>
    </StyledAccordion>
  );
};

CollapsibleFactBox.Title = FactBoxTitle;
CollapsibleFactBox.displayName = 'CollapsibleFactBox';
