import React, { ElementType, FC, MouseEvent } from 'react';
import classNames from 'classnames';

import styled from '../../../utils/styled';
import { fastTransition } from '../../../hooks/enovaContext/globalStyles';
import { Button, ButtonProps } from '../../buttons/button';
import { Icon } from '../../dataDisplay/icon';

import useMenu from './useMenu';

export type MenuButtonProps = ButtonProps & {
  dropdownIcon?: boolean;
  component?: ElementType<ButtonProps>;
};

const StyledIcon = styled(Icon)`
  ${fastTransition('transform')}
  transform: rotate(0);
  margin: ${({ theme }) => theme.spacing(0, -1, 0, 1)};

  &.rotate {
    transform: rotate(-180deg);
  }
` as typeof Icon;

export const MenuButton: FC<MenuButtonProps> = ({
  onClick,
  children,
  component: Component = Button,
  dropdownIcon,
  ...rest
}) => {
  const { anchorEl, onOpen } = useMenu();

  return (
    <Component
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        onClick && onClick(e);
        onOpen(e);
      }}
      {...rest}
    >
      {children}

      {dropdownIcon && (
        <StyledIcon
          className={classNames('menu-button--dropdown-icon', {
            rotate: !!anchorEl,
          })}
          name="arrow_dropdown"
        />
      )}
    </Component>
  );
};
