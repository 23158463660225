import React, { FC, ReactElement, ReactFragment } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

import styled from '../../../utils/styled';

import { Step, StepProps } from './step';

export type StepperProps = Omit<BoxProps, 'children'> & {
  /**
   * Accepts a list of `Step` components
   */
  children: ReactElement<StepProps>[] | ReactFragment;
};

const StepperWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
` as typeof Box;

type StepperSubTypes = {
  Step: typeof Step;
};

/**
 * Wrapper component for `Steps`.
 */
export const Stepper: FC<StepperProps> & StepperSubTypes = ({
  children,
  component = 'ol',
  padding = 0,
  margin = 0,
  ...rest
}) => (
  <StepperWrapper
    {...rest}
    component={component}
    margin={margin}
    padding={padding}
  >
    {children}
  </StepperWrapper>
);

Stepper.Step = Step;
Stepper.displayName = 'Stepper';

export { Step, StepProps };
