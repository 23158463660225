import React, { FC } from 'react';
import classNames from 'classnames';

import { StyledCheckGroup } from './styles';
import { CheckGroupProps } from './types';
import { SelectionGroup } from './selectionGroup';
import { getSelectionGroupDisplayClasses } from './utils';

/**
 * Component for easy rendering of `Checkbox` groups with some common attributes.
 * Renders correctly according to current a11y requirements.
 *
 * Accepts one or more `Checkboxes` as its children.
 *
 * Uses the following components from **Material-UI**:
 *
 * - [FormControl](https://mui.com/api/form-control/)
 * - [FormLabel](https://mui.com/api/form-label/)
 * - [FormGroup](https://mui.com/api/form-group/)
 * - [FormHelperText](https://mui.com/api/form-helper-text/)
 */
export const CheckGroup: FC<CheckGroupProps> = ({ children, row, ...rest }) => (
  <SelectionGroup {...rest}>
    <StyledCheckGroup
      className={classNames(
        getSelectionGroupDisplayClasses(rest.hiddenLabel, row)
      )}
    >
      {children}
    </StyledCheckGroup>
  </SelectionGroup>
);

CheckGroup.displayName = 'CheckGroup';
