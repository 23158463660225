import React, { FC, forwardRef } from 'react';

import { ListItem, ListItemProps } from '../../dataDisplay/list/listItem';

import useMenu from './useMenu';

export type MenuListItemProps = ListItemProps & {
  keepMounted?: boolean;
};

export const MenuListItem: FC<MenuListItemProps> = forwardRef<
  HTMLDivElement | HTMLLIElement,
  MenuListItemProps
>(({ onClick, keepMounted, ...rest }, ref) => {
  const { onClose } = useMenu();

  return (
    <ListItem
      {...rest}
      ref={ref}
      onClick={(e) => {
        onClick && onClick(e);
        !keepMounted && onClose();
      }}
    />
  );
});

MenuListItem.displayName = 'MenuListItem';
