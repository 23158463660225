import { ReactComponent as Calendar } from '../../../assets/largeIcons/icon-calendar.svg';
import { ReactComponent as Chat } from '../../../assets/largeIcons/icon-chat.svg';
import { ReactComponent as EnergyHouse } from '../../../assets/largeIcons/icon-energy-house.svg';
import { ReactComponent as Envelope } from '../../../assets/largeIcons/icon-envelope.svg';
import { ReactComponent as House } from '../../../assets/largeIcons/icon-house.svg';
import { ReactComponent as Info } from '../../../assets/largeIcons/icon-info.svg';
import { ReactComponent as Private } from '../../../assets/largeIcons/icon-private.svg';
import { ReactComponent as Support } from '../../../assets/largeIcons/icon-support.svg';

const viewBox32 = '0 0 32 32';

export const LargeIconMap = {
  calendar: { icon: Calendar, viewBox: viewBox32 },
  chat: { icon: Chat, viewBox: viewBox32 },
  envelope: { icon: Envelope, viewBox: viewBox32 },
  energy_house: { icon: EnergyHouse, viewBox: viewBox32 },
  house: { icon: House, viewBox: '0 0 50 50' },
  info: { icon: Info, viewBox: '0 0 44 44' },
  private: { icon: Private, viewBox: viewBox32 },
  support: { icon: Support, viewBox: viewBox32 },
} as const;

export type LargeIconOption = keyof typeof LargeIconMap;

export const LargeIconOptions = Object.keys(LargeIconMap) as LargeIconOption[];
