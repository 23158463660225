import React, { FC, ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import classNames from 'classnames';

import styled from '../../utils/styled';
import { Typography, TypographyProps } from '../dataDisplay/typography';

export type FactBoxBaseProps = {
  /**
   *  If `true`, the content box will not have padding
   */
  dense?: boolean;
  /**
   * If `true`, the main content box will have a red border
   */
  error?: boolean;
  /**
   * FactBox title
   */
  title: ReactNode;
  /**
   * Props that will be applied to the FactBoxTitle component
   */
  TitleProps?: Omit<TypographyProps, 'bold' | 'color'>;
};

export type FactBoxProps = Omit<BoxProps, 'title'> & FactBoxBaseProps;

export const FactBoxTitle: FC<TypographyProps> = ({
  bold = true,
  color = 'inherit',
  variant = 'h3',
  ...rest
}) => <Typography variant={variant} bold={bold} color={color} {...rest} />;

export const TitleWrapper = styled(Box)`
  border-top-left-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border-top-right-radius: ${({ theme }) => theme.shape.borderRadius}px;

  * {
    color: inherit;
  }
` as typeof Box;

export const ContentWrapper = styled(Box)`
  background: ${({ theme }) => theme.palette.background.paper};
  border-bottom-left-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border-bottom-right-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: thin solid ${({ theme }) => theme.palette.divider};
  border-top: none;
  margin: 0;
  overflow: hidden;

  &.error {
    border-color: ${({ theme }) => theme.palette.error.main};
  }
` as typeof Box;

type FactBoxSubTypes = {
  Title: typeof FactBoxTitle;
};

export const FactBox: FC<FactBoxProps> & FactBoxSubTypes = ({
  children,
  dense,
  error,
  title,
  TitleProps,
  ...rest
}) => (
  <Box {...rest}>
    <TitleWrapper className="bg-primary" padding={4}>
      {typeof title === 'string' ? (
        <FactBoxTitle {...TitleProps}>{title}</FactBoxTitle>
      ) : (
        title
      )}
    </TitleWrapper>

    <ContentWrapper
      {...(dense ? {} : { paddingX: 4, paddingY: 5 })}
      className={classNames({ error })}
    >
      {typeof children === 'string' ? (
        <Typography>{children}</Typography>
      ) : (
        children
      )}
    </ContentWrapper>
  </Box>
);

FactBox.Title = FactBoxTitle;
FactBox.displayName = 'FactBox';
