import React, { ElementType, FC, useMemo } from 'react';
import FormControl from '@mui/material/FormControl';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import Box from '@mui/material/Box';

import { Typography } from '../../dataDisplay/typography';
import { getMarginClasses, StyledLabel } from '../utils';
import { HelperBox } from '../../feedback/helperBox';

import { CheckGroupProps, RadioGroupProps } from './types';
import { StyledDescWrapper, StyledHelperText } from './styles';

/**
 * Component for easy rendering of `Radio` groups with some common attributes.
 * Renders correctly according to current a11y requirements.
 *
 * Accepts one or more `Radio`.
 *
 * Uses the following components from **Material-UI**:
 *
 * - [FormControl](https://mui.com/api/form-control/)
 * - [FormLabel](https://mui.com/api/form-label/)
 * - [RadioGroup](https://mui.com/api/radio-group/)
 * - [FormHelperText](https://mui.com/api/form-helper-text/)
 */
export const SelectionGroup: FC<Partial<RadioGroupProps | CheckGroupProps>> = ({
  'aria-describedby': ariaDescribedby,
  children,
  className,
  description,
  HelperBoxProps,
  helperText,
  hiddenLabel,
  label,
  marginBottom,
  marginTop,
  marginY,
  ...rest
}) => {
  const descriptionId = useMemo(
    () => (description ? nanoid() : undefined),
    [description]
  );

  return (
    <FormControl
      {...rest}
      aria-describedby={ariaDescribedby || descriptionId}
      className={classNames(
        'mw-100',
        'w-100',
        className,
        getMarginClasses({ marginBottom, marginTop, marginY })
      )}
      component={'fieldset' as ElementType}
    >
      {(label || HelperBoxProps) && (
        <Box display="flex" alignItems="baseline">
          {label && (
            <StyledLabel
              className={classNames({ 'sr-only': hiddenLabel })}
              component="legend"
            >
              {label}
            </StyledLabel>
          )}

          {HelperBoxProps && <HelperBox className="ml-2" {...HelperBoxProps} />}
        </Box>
      )}

      {description && (
        <StyledDescWrapper
          className={classNames('selection-group--desc', {
            disabled: rest.disabled,
          })}
        >
          {typeof description === 'string' ? (
            <Typography id={descriptionId} variant="body2">
              {description}
            </Typography>
          ) : (
            <span id={descriptionId}>{description}</span>
          )}
        </StyledDescWrapper>
      )}

      {children}

      {helperText && (
        <StyledHelperText
          className="mt-1"
          classes={{ disabled: 'disabled', error: 'error' }}
        >
          {helperText}
        </StyledHelperText>
      )}
    </FormControl>
  );
};

SelectionGroup.displayName = 'SelectionGroup';
