import React, { FC, HTMLAttributes } from 'react';

import styled from '../../utils/styled';

import { AppBar, AppBarProps } from './appBar';
import { Footer, FooterProps } from './footer';
import { Main, MainProps } from './main';

type LayoutSubTypes = {
  AppBar: typeof AppBar;
  Footer: typeof Footer;
  Main: typeof Main;
};

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export type LayoutProps = HTMLAttributes<HTMLDivElement>;

export const Layout: FC<LayoutProps> & LayoutSubTypes = (props) => (
  <StyledLayout {...props} />
);

Layout.AppBar = AppBar;
Layout.Footer = Footer;
Layout.Main = Main;
Layout.displayName = 'Layout';

export { AppBar, AppBarProps, Footer, FooterProps, Main, MainProps };
