import React, {
  ElementType,
  FC,
  forwardRef,
  Fragment,
  HTMLAttributes,
  HTMLProps,
  ReactNode,
  RefAttributes,
  RefObject,
} from 'react';
import MuiListItem, {
  ListItemProps as MuiListItemProps,
} from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import styled from '../../../utils/styled';
import { focusVisibleInsetBoxShadow } from '../../../hooks/enovaContext/globalStyles';
import { Icon, IconOption } from '../icon';
import { IconButtonProps } from '../../buttons/iconButton';

export type ListItemProps = HTMLAttributes<HTMLDivElement | HTMLLIElement> &
  RefAttributes<HTMLDivElement | HTMLLIElement> &
  Pick<MuiListItemProps, 'disabled' | 'divider' | 'selected'> &
  Pick<HTMLProps<HTMLAnchorElement>, 'href' | 'rel' | 'target'> & {
    /**
     * If `true`, the list item will be regarded as a button.
     */
    button?: boolean;
    /**
     * List item main content.
     */
    children?: ReactNode;
    component?: ElementType;
    /**
     * If `true`, the list item will be disabled.
     */
    disabled?: boolean;
    /**
     * If `true`, a 1px light border is added to the bottom of the list item.
     */
    divider?: boolean;
    /**
     * Use to apply selected styling.
     */
    selected?: boolean;
    /**
     * The subheading of the list item.
     */
    subheading?: ReactNode;
    leftIcon?: IconOption | ElementType<IconButtonProps>;
    rightIcon?: IconOption | ElementType<IconButtonProps>;
  };

const StyledListItemText = styled(ListItemText)`
  margin: 0;
  padding-bottom: ${({ theme }) => theme.spacing()};
  padding-top: ${({ theme }) => theme.spacing()};

  .primary {
    font-weight: 600;
  }

  .secondary {
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

const ButtonListItem = styled(MuiListItem)`
  ${focusVisibleInsetBoxShadow}

  &:active {
    background: ${({ theme }) => theme.palette.action.focus};
  }
` as typeof MuiListItem;

export const ListItem: FC<ListItemProps> = forwardRef<
  HTMLDivElement | HTMLLIElement,
  ListItemProps
>(
  (
    { button, children, divider, leftIcon, rightIcon, subheading, ...rest },
    ref
  ) => {
    const Content = () => (
      <Fragment>
        {leftIcon &&
          (typeof leftIcon === 'string' ? (
            <Icon name={leftIcon as IconOption} />
          ) : (
            leftIcon
          ))}

        <StyledListItemText
          classes={{ primary: 'primary', secondary: 'secondary' }}
          primary={children}
          secondary={subheading}
        />

        {rightIcon &&
          (typeof rightIcon === 'string' ? (
            <Icon name={rightIcon as IconOption} />
          ) : (
            rightIcon
          ))}
      </Fragment>
    );

    return (
      <Fragment>
        {button ? (
          <ButtonListItem
            {...rest}
            button
            classes={{ selected: 'selected' }}
            ref={ref as RefObject<HTMLDivElement>}
          >
            <Content />
          </ButtonListItem>
        ) : (
          <MuiListItem
            {...rest}
            classes={{ selected: 'selected' }}
            ref={ref as RefObject<HTMLLIElement>}
          >
            <Content />
          </MuiListItem>
        )}

        {divider && <Divider className="m-0" />}
      </Fragment>
    );
  }
);

ListItem.displayName = 'ListItem';
